import React, { useState } from 'react'
import { MdEdit } from 'react-icons/md'
import { FaPlus } from "react-icons/fa";
import { Field, Form, Formik } from 'formik';
import Input from '../../Input';
import { UploadImage } from '../../uplaodImage';
import {  createTripValidator, } from '../../../utils/validators';
import { toast } from 'react-toastify';
import Overlay from '../../Overlay';
import { BsDot } from 'react-icons/bs';
import {  RiDeleteBin6Line, RiEditFill } from 'react-icons/ri';
import DropDown from '../../dropDown';
export default function CreateLevelTrip({ rewardValue, childData, setOpenModal }) {
  const [isLoading, setIsLoading] = useState(false);

  // todo list
  // for how to use
  const [todos, setTodos] = useState([]);
  const [newTodo, setNewTodo] = useState("");
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingText, setEditingText] = useState("");

  const addTodo = (formikBag) => {
    if (newTodo.trim()) {
      const updatedTodos = [...todos, newTodo];
      setTodos(updatedTodos);
      setNewTodo("");
      formikBag.setFieldValue("description", updatedTodos);
    }
  };

  // Delete a todo
  const deleteTodo = (index, formikBag) => {
    const updatedTodos = todos.filter((_, idx) => idx !== index);
    setTodos(updatedTodos);
    formikBag.setFieldValue("description", updatedTodos);
  };

  // Save the edited todo
  const saveTodo = (formikBag) => {
    const updatedTodos = todos.map((todo, idx) =>
      idx === editingIndex ? editingText : todo
    );
    setTodos(updatedTodos);
    setEditingIndex(null);
    setEditingText("");
    formikBag.setFieldValue("description", updatedTodos);
  };

  // for terms and condition

  const [terms, setTerms] = useState([]);
  const [newTerms, setNewTerms] = useState("");
  const [editingTermsIndex, setEditingTermsIndex] = useState(null);
  const [editingTermsText, setEditingTermsText] = useState("");

  const addTerms = (formikBag) => {
    if (newTerms.trim()) {
      const updatedTerms = [...terms, newTerms];
      setTerms(updatedTerms);
      setNewTerms("");
      formikBag.setFieldValue("terms", updatedTerms);
    }
  };

  // Delete a terms and condition
  const deleteTerms = (index, formikBag) => {
    const updatedTerms = terms.filter((_, idx) => idx !== index);
    setTerms(updatedTerms);
    formikBag.setFieldValue("terms", updatedTerms);
  };

  // Save the edited term and condition
  const saveTerms = (formikBag) => {
    const updatedTerms = terms.map((term, idx) =>
      idx === editingTermsIndex ? editingTermsText : term
    );
    setTerms(updatedTerms);
    setEditingTermsIndex(null);
    setEditingTermsText("");
    formikBag.setFieldValue("terms", updatedTerms);
  };


  const [value, setValue] = useState({
    rewardIcon: "",
    brandIcon: "",
    brandImg: "",
    rewardAmount: "",
    tripType: "",
    quantity: "",
    validFrom: "",
    validTill: "",
    wonMessage: "",
    price: "",
    pickupLocation: "",
    destination: "",
    rewardName: "",
    rewardExpireDate: "",
    description: "",
    terms: ""
  })


  const [rewardIcon, setRewardIcon] = useState("");
  const [brandIcon, setBrandIcon] = useState("");
  const [brandImg, setBrandImg] = useState("");
const uploadfile = async (e, formikBag) => {
    const file = e.target.files[0];
    if (file) {
      const img = new Image();
      const objectUrl = URL.createObjectURL(file);

      img.onload = async function () {
        if (this.width > 128 || this.height > 128) {
          console.error("Image dimensions should not exceed 128x128.");
          toast.error("Image dimensions should not exceed 128x128.")
          URL.revokeObjectURL(objectUrl);
          return;
        }

        let message="Reward Icon uploaded successfully "
        const url = await UploadImage(file,message);
        if (url) {
          formikBag.setFieldValue("rewardIcon", url);
          setRewardIcon(url);
        } else {
          console.error("Failed to upload or get image URL.");
        }

        URL.revokeObjectURL(objectUrl);
      };

      img.src = objectUrl;
    } else {
      console.error("No file selected.");
    }
  };

  const uploadfile2 = async (e, formikBag) => {
    const file = e.target.files[0];
    if (file) {
      const img = new Image();
      const objectUrl = URL.createObjectURL(file);

      img.onload = async function () {
        let message="Brand Icon uploaded successfully"
        const url = await UploadImage(file,message);
        if (url) {
          formikBag.setFieldValue("brandIcon", url);
          setBrandIcon(url);
        } else {
          console.error("Failed to upload or get image URL.");
        }

        URL.revokeObjectURL(objectUrl);
      };

      img.src = objectUrl;
    } else {
      console.error("No file selected.");
    }
  };
  const uploadfile3 = async (e, formikBag) => {
    const file = e.target.files[0];
    if (file) {
      const img = new Image();
      const objectUrl = URL.createObjectURL(file);

      img.onload = async function () {

        let message="Brand Banner Image uploaded successfully"
        const url = await UploadImage(file,message);
        if (url) {
          formikBag.setFieldValue("brandImg", url);
          setBrandImg(url);
        } else {
          console.error("Failed to upload or get image URL.");
        }

        URL.revokeObjectURL(objectUrl);
      };

      img.src = objectUrl;
    } else {
      console.error("No file selected.");
    }
  };
  const handleCreateVoucher = async (value) => {
    let refData = {
      rewardIcon: value.rewardIcon,
      brandIcon: value.brandIcon,
      brandImg: value.brandImg,
      rewardAmount: value.rewardAmount,
      tripType: value.tripType,
      quantity: Number(value.quantity),
      validFrom: value.validFrom,
      validTill: value.validTill,
      rewardExpireDate: value.rewardExpireDate,
      destination: value.destination,
      pickupLocation: value.pickupLocation,
      wonMessage: `You won ${Number(value.rewardAmount)} ${value.rewardName}`,
      price: value.price,
      rewardName: value.rewardName,
      description: value.description,
      terms: value.terms,
    }
    rewardValue(refData);
    setOpenModal(false)
  }
  const option = [
    {
      id: "1",
      name: "1 Adult"
    },
    {
      id: "2",
      name: "1 Adult + 1 Child"
    },
    {
      id: "3",
      name: "2 Adult"
    },
    {
      id: "4",
      name: "2 Adult + 1 Child"
    },
  ];
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const wordLimit = 200;

  const handleDescriptionChange = (e) => {
    const inputText = e.target.value;
    const words = inputText.trim().split(/\s+/);

    if (words.length > wordLimit) {
      alert("You cannot enter more than 200 words.");
      return;
    }
    setNewTodo(inputText);
  };
  const handleTermsChange = (e) => {
    const inputText = e.target.value;
    const words = inputText.trim().split(/\s+/);

    if (words.length > wordLimit) {
      alert("You cannot enter more than 200 words.");
      return;
    }
    setNewTerms(inputText);
  };
  return (
    <div className="hewe-con">
      <div className="referralcont">

        <Formik
          enableReinitialize
          initialValues={value}
          validate={(values) => createTripValidator(values)}
          validateOnChange
          onSubmit={(values) => handleCreateVoucher(values)}
        >
          {(formikBag) => {
            return (
              <Form className='vocherform'>
                <div className="row align-items-baseline">
                  <div className="col-xl-12 d-flex  mb-4">
                    <div className=''>
                      <label className='text-center'>Reward Icon</label>
                      <div className="imgcont">
                        <input
                          type="file"
                          id="avatar-upload"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={(e) => uploadfile(e, formikBag)}
                        />
                        {
                          rewardIcon != "" ?
                            <img src={rewardIcon} alt="img" className='brandimg' />
                            : <div className='flex-align-center flex-column'>
                              <FaPlus className='plusIcon' onClick={() => document.getElementById("avatar-upload").click()} />
                              <label className='text-center rewardsize mt-2'>128 x 128</label>
                            </div>
                        }
                        {
                          rewardIcon != "" ? <>
                            <RiEditFill className='edit' onClick={() => document.getElementById("avatar-upload").click()} />
                          </>
                            : null
                        }
                      </div>
                      <p className='error'>{
                        formikBag.touched.rewardIcon &&
                          formikBag.errors.rewardIcon
                          ? formikBag.errors.rewardIcon
                          : null
                      }</p>
                    </div>

                    <div className='ps-4'>
                      <label className='text-center'>brand Icon</label>
                      <div className="imgcont">
                        <input
                          type="file"
                          id="avatar-upload2"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={(e) => uploadfile2(e, formikBag)}
                        />
                        {
                          brandIcon != "" ?
                            <img src={brandIcon} alt="img" className='brandimg' />
                            : <div className='flex-align-center flex-column'>
                              <FaPlus className='plusIcon' onClick={() => document.getElementById("avatar-upload2").click()} />
                            </div>
                        }
                        {
                          brandIcon != "" ? <>
                            <RiEditFill className='edit' onClick={() => document.getElementById("avatar-upload2").click()} />
                          </>
                            : null
                        }
                      </div>
                      <p className='error'>{
                        formikBag.touched.brandIcon &&
                          formikBag.errors.brandIcon
                          ? formikBag.errors.brandIcon
                          : null
                      }</p>
                    </div>

                    <div className='ps-4'>
                      <label className='text-center'>Banner Image</label>
                      <div className="imgcont imgcont2">
                        <input
                          type="file"
                          id="avatar-upload3"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={(e) => uploadfile3(e, formikBag)}
                        />
                        {
                          brandImg != "" ?
                            <img src={brandImg} alt="img" className='bannerimg' />
                            : <div className='flex-align-center flex-column'>
                              <FaPlus className='plusIcon' onClick={() => document.getElementById("avatar-upload3").click()} />
                            </div>
                        }
                        {
                          brandImg != "" ? <>
                            <RiEditFill className='edit' onClick={() => document.getElementById("avatar-upload3").click()} />
                          </>
                            : null
                        }
                      </div>
                      <p className='error'>{
                        formikBag.touched.brandImg &&
                          formikBag.errors.brandImg
                          ? formikBag.errors.brandImg
                          : null
                      }</p>
                    </div>

                  </div>
                  <div className='col-xl-10 mt-3'>
                    <div className="row">
                      <div className='col-xxl-4 col-xl-6  col-sm-6 mb-4'>
                        <label htmlFor="">Member Details</label>
                        <Field name="tripType">
                          {({ field, form }) => (
                            <>
                              <DropDown
                                field={field}
                                form={form}
                                option={option}
                                placeholder="Select Reward Type"
                              />
                              <p className='error'>{
                                formikBag.touched.tripType &&
                                  formikBag.errors.tripType
                                  ? formikBag.errors.tripType
                                  : null
                              }</p>
                            </>

                          )}
                        </Field>
                      </div>
                      <div className="col-xxl-4 col-xl-6  col-sm-6 mb-4">
                        <label htmlFor="" >Quantity</label>
                        <Field className="inptCont">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="number"
                              min="0"
                              placeholder='Enter Quantity'
                              value={formikBag.values.quantity}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "quantity",
                                  e.target.value
                                );
                              }}
                              error={
                                formikBag.touched.quantity &&
                                  formikBag.errors.quantity
                                  ? formikBag.errors.quantity
                                  : null
                              }
                              className="form-control"
                            />
                          )}
                        </Field>

                      </div>


                      <div className="col-xxl-4 col-xl-6  col-sm-6 mb-4">
                        <label htmlFor="" placeholder='Reward Amount'>Per User Qty</label>
                        <Field className="inptCont">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="Number"
                              min="0"
                              value={formikBag.values.rewardAmount}
                              placeholder="Enter Quantity"
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "rewardAmount",
                                  e.target.value
                                );
                              }}
                              error={
                                formikBag.touched.rewardAmount &&
                                  formikBag.errors.rewardAmount
                                  ? formikBag.errors.rewardAmount
                                  : null
                              }
                              className="form-control"
                            />
                          )}
                        </Field>
                      </div>
                      <div className="col-xxl-4 col-xl-6  col-sm-6 mb-4">
                        <label htmlFor="" >Reward Name</label>
                        <Field className="inptCont">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="text"
                              minlength="2"
                              maxlength="30"
                              placeholder="Enter Reward Name"
                              value={formikBag.values.rewardName}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "rewardName",
                                  e.target.value
                                );
                              }}
                              error={
                                formikBag.touched.rewardName &&
                                  formikBag.errors.rewardName
                                  ? formikBag.errors.rewardName
                                  : null
                              }
                              className="form-control"
                            />
                          )}
                        </Field>
                      </div>
                      <div className="col-xxl-4 col-xl-6  col-sm-6 mb-4">
                        <label htmlFor="">Trip Start Point</label>
                        <Field className="inptCont">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="text"
                              placeholder="Enter Pickup Location"
                              value={formikBag.values.pickupLocation}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "pickupLocation",
                                  e.target.value
                                );
                              }}
                              error={
                                formikBag.touched.pickupLocation &&
                                  formikBag.errors.pickupLocation
                                  ? formikBag.errors.pickupLocation
                                  : null
                              }
                              className="form-control"
                            />
                          )}
                        </Field>
                      </div>
                      <div className="col-xxl-4 col-xl-6  col-sm-6 mb-4">
                        <label htmlFor="">Trip Destination Point</label>
                        <Field className="inptCont">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="text"
                              placeholder="Enter Destination"
                              value={formikBag.values.destination}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "destination",
                                  e.target.value
                                );
                              }}
                              error={
                                formikBag.touched.destination &&
                                  formikBag.errors.destination
                                  ? formikBag.errors.destination
                                  : null
                              }
                              className="form-control"
                            />
                          )}
                        </Field>
                      </div>

                      <div className="col-xxl-4 col-xl-6  col-sm-6 mb-4">
                        <label htmlFor="" placeholder='Select Date' >Valid From</label>
                        <Field className="inptCont">
                          {({ field }) => {
                            let maxDate = "";

                            if (formikBag?.values?.rewardExpireDate) {
                              const validFromDate = new Date(formikBag.values.rewardExpireDate);
                              validFromDate.setDate(validFromDate.getDate() - 1);
                              maxDate = validFromDate.toISOString().split("T")[0];
                            }

                            return (
                              <Input
                                {...field}
                                type="date"
                                min={getTodayDate()}
                                max={maxDate}
                                value={formikBag.values.validFrom}
                                onChange={(e) => {
                                  formikBag.setFieldValue("validFrom", e.target.value);
                                }}
                                error={
                                  formikBag.touched.validFrom && formikBag.errors.validFrom
                                    ? formikBag.errors.validFrom
                                    : null
                                }
                                className="form-control"
                              />
                            );
                          }}
                        </Field>

                      </div>
                      <div className="col-xxl-4 col-xl-6  col-sm-6 mb-4">
                        <label htmlFor="" placeholder='Select Date' >Valid till</label>
                        <Field className="inptCont">
                          {({ field }) => {
                            let minDate = "";

                            if (formikBag?.values?.validFrom) {
                              const validFromDate = new Date(formikBag.values.validFrom);
                              validFromDate.setDate(validFromDate.getDate() + 1);
                              minDate = validFromDate.toISOString().split("T")[0];
                            }

                            let maxDate = "";

                            if (formikBag?.values?.rewardExpireDate) {
                              const validFromDate = new Date(formikBag.values.rewardExpireDate);
                              validFromDate.setDate(validFromDate.getDate());
                              maxDate = validFromDate.toISOString().split("T")[0];
                            }

                            return (
                              <Input
                                {...field}
                                type="date"
                                min={minDate}
                                max={maxDate}
                                value={formikBag.values.validTill}
                                onChange={(e) => {
                                  formikBag.setFieldValue("validTill", e.target.value);
                                }}
                                error={
                                  formikBag.touched.validTill && formikBag.errors.validTill
                                    ? formikBag.errors.validTill
                                    : null
                                }
                                className="form-control"
                              />
                            );
                          }}
                        </Field>
                      </div>
                      <div className="col-xxl-4 col-xl-6  col-sm-6 mb-4">
                        <label  >Reward Expiry Date</label>
                        <Field className="inptCont">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="date"
                              htmlFor="" placeholder='Select Date'
                              min={formikBag.values.validTill}
                              value={formikBag.values.rewardExpireDate}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "rewardExpireDate",
                                  e.target.value
                                );
                              }}
                              error={
                                formikBag.touched.rewardExpireDate &&
                                  formikBag.errors.rewardExpireDate
                                  ? formikBag.errors.rewardExpireDate
                                  : null
                              }
                              className="form-control"
                            />
                          )}
                        </Field>
                      </div>
                      <div className="col-xxl-4 col-xl-6  col-sm-6 mb-4">
                        <label htmlFor="" placeholder='Enter Quantity'>Price (in $)</label>
                        <Field className="inptCont">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="number"
                              min="0"
                              step="any"
                              value={formikBag.values.price}
                              placeholder="Enter Price"
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "price",
                                  e.target.value
                                );
                              }}
                              error={
                                formikBag.touched.price &&
                                  formikBag.errors.price
                                  ? formikBag.errors.price
                                  : null
                              }
                              className="form-control"
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <label htmlFor="">How to use</label>
                    <div className="inptCont d-flex justify-content-between align-items-end">
                      <textarea

                        type="text"
                        placeholder="Type instructions here (e.g., 'Apply code at checkout to redeem.')."
                        value={newTodo}
                        onChange={handleDescriptionChange}
                        error={
                          formikBag.touched.description &&
                            formikBag.errors.description
                            ? formikBag.errors.description
                            : null
                        }
                      />
                      <div>
                        <p className="white-space-nowrap">{newTodo?.trim()?.split(/\s+/)?.filter(word => word)?.length}/200 words</p>
                        <div className='d-flex justify-content-end'>
                          <div
                            onClick={newTodo.length === 0 ? null : () => addTodo(formikBag)}
                            className={`savebtn ${newTodo.length === 0 ? 'btndisabled' : 'pointer'}`}
                            style={{ pointerEvents: newTodo.length === 0 ? 'none' : 'auto' }}
                          >
                            Save
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className='error'>{formikBag.touched.description && formikBag.errors.description ? formikBag.errors.description : null}</p>
                    <ul className='my-5 list-style-none'>
                      {todos.map((todo, index) => (
                        <li key={index} className="inptCont">
                          {/* Show the editing input if we are editing this item */}
                          {editingIndex === index ? (
                            <div className='d-flex justify-content-between'>
                              <input
                                type="text"
                                value={editingText}
                                onChange={(e) => setEditingText(e.target.value)}
                              />
                              <div onClick={() => saveTodo(formikBag)} className='savebtn'>Save</div>
                            </div>
                          ) : (
                            <div className='d-flex justify-content-between word-break-all font-italic'>
                              <span><BsDot /> {todo}</span>
                              <div className='d-flex align-items-start '>
                                <div className='font-#F8F8F8 border-0 pointer me-2 fs-20' onClick={() => {
                                  setEditingIndex(index);
                                  setEditingText(todo);
                                }}>
                                  <MdEdit />
                                </div>
                                <div className='bg-white border-0 pointer fs-20' onClick={() => deleteTodo(index, formikBag)}> <RiDeleteBin6Line /> </div>
                              </div>
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-12">
                    <label htmlFor="">Terms and Condition</label>
                    <div className="inptCont d-flex justify-content-between align-items-end">
                      <textarea

                        type="text"
                        placeholder="Add a new Terms and condition"
                        value={newTerms}
                        onChange={handleTermsChange}
                        error={
                          formikBag.touched.terms &&
                            formikBag.errors.terms
                            ? formikBag.errors.terms
                            : null
                        }
                      />
                      <div>
                        <p className="white-space-nowrap">{newTerms?.trim()?.split(/\s+/)?.filter(word => word)?.length}/200 words</p>                      <div className='d-flex justify-content-end'>
                          <div
                            onClick={newTerms.length === 0 ? null : () => addTerms(formikBag)}
                            className={`savebtn ${newTerms.length === 0 ? 'btndisabled' : 'pointer'}`}
                            style={{ pointerEvents: newTerms.length === 0 ? 'none' : 'auto' }}
                          >
                            Save
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className='error'>{formikBag.touched.terms && formikBag.errors.terms ? formikBag.errors.terms : null}</p>
                    <ul className='my-5 list-style-none'>
                      {terms.map((term, index) => (
                        <li key={index} className="inptCont">
                          {/* Show the editing input if we are editing this item */}
                          {editingTermsIndex === index ? (
                            <div className='d-flex justify-content-between'>
                              <input
                                type="text"
                                value={editingTermsText}
                                onChange={(e) => setEditingTermsText(e.target.value)}
                              />
                              <div onClick={() => saveTerms(formikBag)} className='savebtn'>Save</div>
                            </div>
                          ) : (
                            <div className='d-flex justify-content-between word-break-all font-italic'>
                              <span><BsDot /> {term}</span>
                              <div className='d-flex align-items-start '>
                                <div className='font-#F8F8F8 border-0 pointer me-2 fs-20' onClick={() => {
                                  setEditingTermsIndex(index);
                                  setEditingTermsText(term);
                                }}>
                                  <MdEdit />
                                </div>
                                <div className='bg-white border-0 pointer fs-20' onClick={() => deleteTerms(index, formikBag)}> <RiDeleteBin6Line /> </div>
                              </div>
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className='d-flex justify-content-center'>
                  <button type="submit" className='savebtn px-5 py-3' >Save</button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
      {isLoading && <Overlay />}
    </div>

  )
}
