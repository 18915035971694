import React, { useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Overlay from '../../components/Overlay';
import Input from '../../components/Input';
import { IoChevronBack } from 'react-icons/io5';
import { DashHeading } from '../AccountManagement/AccountManagementElements';
import { Formik } from 'formik';
import 'react-phone-input-2/lib/style.css';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import PhoneInput from "react-phone-input-2";
import { toast } from 'react-toastify';
import axios from "../../axios";
import "./style.scss"
import { subAdminValidationSchema } from '../../utils/validators';
const useStyles = makeStyles((theme) => ({
    paperTableHeight: {
        width: "100%",
        minHeight: "50vh",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        border: "1px solid #D9E3D9",
        overflowX: "scroll",
    },
    tableMainContainer: {
        overflowX: "scroll",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
    },
    tableHeadingRow: {
        // backgroundColor: "#21AFE6",
    },
    tablseHeadingCell: {
        textTransform: "capitalize",
        padding: "0.8rem 20px !important",
        textAlign: "center",
        fontSize: "14px",
        color: "#264503",
        fontWeight: 600,
        borderBottom: "1px solid #D9E3D9",
        whiteSpace: "nowrap",
    },
    textMiddle: {
        padding: "0.6rem 20px !important",
        verticalAlign: "middle !important",
        textAlign: "center",
        fontSize: "14px",
        color: "black",
    },
    tablePaginationStyle: {
        borderTop: "1px solid #0000001a",
        borderRadius: "0rem 0rem 0.4rem 0.4rem",
        overflowY: "hidden",
    },
    sticky: {
        position: "sticky",
        left: "0"
    }
}));

export const AddSubAdmin = () => {
    const history = useHistory()
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false)
    const [countryCode, setCountryCode] = useState("+91")
    const [userData, setUserData] = useState({
        email: "",
        phoneNumber: "",
        countryCode: countryCode,
        subAdminName: "",
        subAdminTitle: "",
        modules: [
            {
                moduleName: "Dashboard",
                access: false,
                permissions: { add: false, edit: false, delete: false, download: false, blockUnblock: false },
                disable: { add: true, edit: true, delete: true, download: true, blockUnblock: true },
            },
            {
                moduleName: "Account Management",
                access: false,
                permissions: { add: false, edit: false, delete: false, download: false, blockUnblock: false },
                disable: { add: true, edit: true, delete: true, download: false, blockUnblock: false },
            },
            {
                moduleName: "Reward Management",
                access: false,
                permissions: { add: false, edit: false, delete: false, download: false, blockUnblock: false },
                disable: { add: false, edit: false, delete: false, download: true, blockUnblock: true },
            },
            // {
            //     moduleName: "Level Management",
            //     access: false,
            //     permissions: { add: false, edit: false, delete: false, download: false, blockUnblock: false },
            //     disable: { add: false, edit: false, delete: true, download: true, blockUnblock: true },
            // },
            // {
            //     moduleName: "Daily Achievement",
            //     access: false,
            //     permissions: { add: false, edit: false, delete: false, download: false, blockUnblock: false },
            //     disable: { add: false, edit: false, delete: true, download: true, blockUnblock: true },
            // },
            {
                moduleName: "Claim Management",
                access: false,
                permissions: { add: false, edit: false, delete: false, download: false, blockUnblock: false },
                disable: { add: true, edit: false, delete: true, download: true, blockUnblock: true },
            },
            {
                moduleName: "Sub Admin Management",
                access: false,
                permissions: { add: false, edit: false, delete: false, download: false, blockUnblock: false },
                disable: { add: false, edit: false, delete: false, download: true, blockUnblock: false },
            },
            {
                moduleName: "CMS Management",
                access: false,
                permissions: { add: false, edit: false, delete: false, download: false, blockUnblock: false },
                disable: { add: false, edit: false, delete: false, download: true, blockUnblock: true },
            },
            {
                moduleName: "Notification Management",
                access: false,
                permissions: { add: false, edit: false, delete: false, download: false, blockUnblock: false },
                disable: { add: false, edit: true, delete: true, download: true, blockUnblock: true },
            },
            {
                moduleName: "Ticket Management",
                access: false,
                permissions: { add: false, edit: false, delete: false, download: false, blockUnblock: false },
                disable: { add: false, edit: false, delete: false, download: true, blockUnblock: true },
            },
        ]
    })

    const handlePhoneChange = (value, data) => {
        setCountryCode(`+${data.dialCode}`)
    };
    const handleUserData = async (value) => {
        try {
            setIsLoading(true)
            const data = await axios.post(`/private/createSubAdmin`, value)
            toast.success(data?.data?.message);
            history.goBack();
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            toast.error(`${error.response.data?.errors[0].msg}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }


    return (
        <section className="hewe-cont addSubAdmin" >
            <div className="flex-align-center justify-content-between mb-4 w-100">
                <div className="flex-align-center ">
                    <IoChevronBack
                        size={32}
                        className="back-color"
                        onClick={() =>
                            history.goBack()}
                    />
                    <DashHeading>Add Sub Admin</DashHeading>
                </div>
            </div>
            <div>
                <Formik
                    enableReinitialize
                    initialValues={userData}
                    validate={subAdminValidationSchema}
                    validateOnChange
                    onSubmit={(values) => handleUserData(values)}
                >
                    {(formikBag) => (
                        <form onSubmit={formikBag.handleSubmit}>
                            <div className="cont">
                                <p className="mb-4">Sub Admin Details</p>
                                <div className="row">
                                    <div className="col-sm-6 mb-3">
                                        <label htmlFor="subAdminName">Sub Admin Name</label>
                                        <Input
                                            type="text"
                                            autocomplete="off"
                                            id="subAdminName"
                                            name="subAdminName"
                                            minlength="2"
                                            maxlength="20"
                                            value={formikBag.values.subAdminName}
                                            onChange={(e) => {
                                                let value = e.target.value.replace(/\s+/g, ' '); // Replace multiple spaces with a single space
                                                const regex = /^[A-Za-z0-9!@#$%^&*()_+={}\[\]:;"'<>,.?/\- ]*$/; // Allow spaces

                                                if (value.startsWith(' ')) {
                                                    value = value.trimStart(); // Remove leading spaces
                                                }

                                                if (regex.test(value) || value === "") {
                                                    formikBag.setFieldValue("subAdminName", value);
                                                }
                                            }}
                                            className="form-control"
                                            placeholder="Enter Sub Admin Name"
                                        />
                                        {formikBag.touched.subAdminName && formikBag.errors.subAdminName && (
                                            <div className="error">{formikBag.errors.subAdminName}</div>
                                        )}
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                        <label htmlFor="subAdminTitle">Sub Admin Title</label>
                                        <Input
                                            type="text"
                                            autocomplete="off"
                                            id="subAdminTitle"
                                            name="subAdminTitle"
                                            minlength="2"
                                            maxlength="20"
                                            value={formikBag.values.subAdminTitle}
                                            onChange={(e) => {
                                                let value = e.target.value.replace(/\s+/g, ' '); // Replace multiple spaces with a single space
                                                const regex = /^[A-Za-z0-9!@#$%^&*()_+={}\[\]:;"'<>,.?/\- ]*$/; // Allow spaces

                                                if (value.startsWith(' ')) {
                                                    value = value.trimStart(); // Remove leading spaces
                                                }

                                                if (regex.test(value) || value === "") {
                                                    formikBag.setFieldValue("subAdminTitle", value);
                                                }
                                            }}
                                            // onChange={formikBag.handleChange}
                                            className="form-control"
                                            placeholder="Enter Sub Admin Title"
                                        />
                                        {formikBag.touched.subAdminTitle && formikBag.errors.subAdminTitle && (
                                            <div className="error">{formikBag.errors.subAdminTitle}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <p className="mb-4">Contact Information</p>
                                    <div className="col-sm-6 mb-4">
                                        <label htmlFor="email">Email</label>
                                        <Input
                                            type="text"
                                            id="email"
                                            autocomplete="off"
                                            name="email"
                                            value={formikBag.values.email}
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/\s+/g, ''); // Remove spaces

                                                const regex = /^[A-Za-z0-9!@#$%^&*()_+={}\[\]:;"'<>,.?/\- ]*$/; // Allow spaces

                                                if (value.startsWith(' ')) {
                                                    value = value.trimStart(); // Remove leading spaces
                                                }

                                                if (regex.test(value) || value === "") {
                                                    formikBag.setFieldValue("email", value);
                                                }
                                            }}
                                            // onChange={formikBag.handleChange}
                                            className="form-control"
                                            placeholder="Enter Email"
                                        />
                                        {formikBag.touched.email && formikBag.errors.email && (
                                            <div className="error">{formikBag.errors.email}</div>
                                        )}
                                    </div>
                                    <div className="col-sm-6">
                                        <label>Phone Number</label>
                                        <div className="d-flex form-controlphone">
                                            <PhoneInput
                                                country={'in'}
                                                enableSearch={true}
                                                className="phone-input"
                                                value={formikBag.values.phoneNumber}
                                                onChange={(phone, country) => {
                                                    formikBag.setFieldValue('countryCode', country.dialCode);
                                                    formikBag.setFieldValue('phoneNumber', phone);
                                                }}
                                            />
                                            {/* <Input
                                                type="text"
                                                autocomplete="off"
                                                value={formikBag.values.phoneNumber}
                                                onChange={formikBag.handleChange}
                                                name="phoneNumber"
                                                placeholder="Enter phone number"
                                                className="form-control border-0"
                                                onKeyPress={(e) => {
                                                    // Prevent non-numeric characters
                                                    if (!/[0-9]/.test(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                inputMode="numeric"
                                                pattern="[0-9]*"

                                            /> */}
                                        </div>
                                        {formikBag.touched.phoneNumber && formikBag.errors.phoneNumber && (
                                            <div className="error">{formikBag.errors.phoneNumber}</div>
                                        )}
                                    </div>
                                </div>
                                <p className="note">
                                    <strong>Note:</strong> Sub Admin will receive an email with the new login credentials for panel access.
                                </p>
                            </div>
                            <div className="permission mt-4">
                                <h3>Manage Permission</h3>
                                <Paper>
                                    <TableContainer className={classes.tableMainContainer}>
                                        <Table>
                                            <TableHead>
                                                <TableRow className={classes.tableHeadingRow}>

                                                    <TableCell className={classes.tablseHeadingCell} style={{ textAlign: "left" }}>Module Name</TableCell>
                                                    <TableCell className={classes.tablseHeadingCell}>View</TableCell>
                                                    <TableCell className={classes.tablseHeadingCell}>Add</TableCell>
                                                    <TableCell className={classes.tablseHeadingCell}>Edit</TableCell>
                                                    <TableCell className={classes.tablseHeadingCell}>Block/Unblock</TableCell>
                                                    <TableCell className={classes.tablseHeadingCell}>Delete</TableCell>
                                                    <TableCell className={classes.tablseHeadingCell}>Download</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {formikBag?.values?.modules?.map((module, index) => (
                                                    <TableRow key={module.moduleName}>

                                                        <TableCell className={classes.textMiddle} style={{ textAlign: "left" }}>{module.moduleName}</TableCell>
                                                        <TableCell className={classes.textMiddle}>
                                                            <input
                                                                type="checkbox"
                                                                checked={
                                                                    formikBag.values.modules[index].access
                                                                }
                                                                onChange={(e) => {
                                                                    const isChecked = e.target.checked;
                                                                    if (isChecked === false) {
                                                                        formikBag.setFieldValue(`modules[${index}].permissions.add`, false);
                                                                        formikBag.setFieldValue(`modules[${index}].permissions.edit`, false);
                                                                        formikBag.setFieldValue(`modules[${index}].permissions.blockUnblock`, false);
                                                                        formikBag.setFieldValue(`modules[${index}].permissions.delete`, false);
                                                                        formikBag.setFieldValue(`modules[${index}].permissions.download`, false);
                                                                    }
                                                                    formikBag.setFieldValue(`modules[${index}].access`, isChecked);
                                                                }}
                                                            />


                                                        </TableCell>
                                                        <TableCell className={classes.textMiddle}>
                                                            <input
                                                                type="checkbox"
                                                                disabled={formikBag.values.modules[index].disable.add}
                                                                checked={
                                                                    !formikBag.values.modules[index].disable.add &&
                                                                    formikBag.values.modules[index].permissions.add
                                                                }
                                                                onChange={(e) => {
                                                                    if (!formikBag.values.modules[index].disable.add) {
                                                                        {
                                                                            e.target.checked == true &&
                                                                                formikBag.setFieldValue(`modules[${index}].access`, true);
                                                                        }
                                                                        formikBag.setFieldValue(
                                                                            `modules[${index}].permissions.add`,
                                                                            e.target.checked
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.textMiddle}>
                                                            <input
                                                                type="checkbox"
                                                                disabled={formikBag.values.modules[index].disable.edit}
                                                                checked={
                                                                    !formikBag.values.modules[index].disable.edit &&
                                                                    formikBag.values.modules[index].permissions.edit
                                                                }
                                                                onChange={(e) => {
                                                                    if (!formikBag.values.modules[index].disable.edit) {
                                                                        {
                                                                            e.target.checked == true &&
                                                                                formikBag.setFieldValue(`modules[${index}].access`, true);
                                                                        }
                                                                        formikBag.setFieldValue(
                                                                            `modules[${index}].permissions.edit`,
                                                                            e.target.checked
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </TableCell>

                                                        <TableCell className={classes.textMiddle}>
                                                            <input
                                                                type="checkbox"
                                                                disabled={formikBag.values.modules[index].disable.blockUnblock}
                                                                checked={
                                                                    !formikBag.values.modules[index].disable.blockUnblock &&
                                                                    formikBag.values.modules[index].permissions.blockUnblock
                                                                }
                                                                onChange={(e) => {
                                                                    if (!formikBag.values.modules[index].disable.blockUnblock) {
                                                                        {
                                                                            e.target.checked == true &&
                                                                                formikBag.setFieldValue(`modules[${index}].access`, true);
                                                                        }
                                                                        formikBag.setFieldValue(
                                                                            `modules[${index}].permissions.blockUnblock`,
                                                                            e.target.checked
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.textMiddle}>
                                                            <input
                                                                type="checkbox"
                                                                disabled={formikBag.values.modules[index].disable.delete}
                                                                checked={
                                                                    !formikBag.values.modules[index].disable.delete &&
                                                                    formikBag.values.modules[index].permissions.delete
                                                                }
                                                                onChange={(e) => {
                                                                    if (!formikBag.values.modules[index].disable.delete) {
                                                                        {
                                                                            e.target.checked == true &&
                                                                                formikBag.setFieldValue(`modules[${index}].access`, true);
                                                                        }

                                                                        formikBag.setFieldValue(
                                                                            `modules[${index}].permissions.delete`,
                                                                            e.target.checked
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.textMiddle}>
                                                            <input
                                                                type="checkbox"
                                                                disabled={formikBag.values.modules[index].disable.download}
                                                                checked={
                                                                    !formikBag.values.modules[index].disable.download &&
                                                                    formikBag.values.modules[index].permissions.download
                                                                }
                                                                onChange={(e) => {
                                                                    if (!formikBag.values.modules[index].disable.download) {

                                                                        {
                                                                            e.target.checked == true &&
                                                                                formikBag.setFieldValue(`modules[${index}].access`, true);
                                                                        }

                                                                        formikBag.setFieldValue(
                                                                            `modules[${index}].permissions.download`,
                                                                            e.target.checked
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </TableCell>
                                                    </TableRow>

                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </div>
                            <button type="submit" className="savebtn mt-5">
                                Submit
                            </button>
                        </form>
                    )}
                </Formik>
            </div>
            {isLoading && <Overlay />}

        </section>
    )
}
