import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./index.css";

import axios from "./axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as actionTypes from "./store/actions";

import Navbar from "./components/Navbar";
import LoginSection from "./components/LoginSection";
import ChangedPassword from "./pages/Authentican/ForgotPassword/ChangedPassowrd";
import ProfileManagement from "./pages/Authentican/Profile/Profile";
import ManageDevice from "./pages/ManageDevice/ManageDevice";
import AccountManagement from "./pages/AccountManagement/AccountManagement";

import Sidebar from "./components/SidebarHaydii/Sidebar";

import ViewProfile from "./components/ViewProfile/ViewProfile";
import ViewReferral from "./components/viewReferral/viewReferral";
import ReferralAndEarn from "./pages/RewardManagement/ReferralAndEarn";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import OtherReward from "./pages/RewardManagement/OtherReward";
import Voucher from "./components/reward/voucher/Vouchers";
import CreateVoucher from "./components/reward/voucher/create-vouchers";
import EditVoucher from "./components/reward/voucher/edit-voucher";
import InAppReward from "./components/reward/inAppReward/appReward";
import CreateInAppReward from "./components/reward/inAppReward/create-appReward";
import EditInAppReward from "./components/reward/inAppReward/edit-appReward";
import AuditToken from "./components/reward/auditToken/AuditToken";
import CreateAuditToken from "./components/reward/auditToken/create-AuditToken";
import EditAuditToken from "./components/reward/auditToken/edit-AuditToken";
import Premium from "./components/reward/premium/Premium";
import CreatePremium from "./components/reward/premium/create-premium";
import EditPremium from "./components/reward/premium/edit-premium";
import Trip from "./components/reward/trip/trip";
import CreateTrip from "./components/reward/trip/create-trip";
import EditTrip from "./components/reward/trip/edit-trip";
import RewardHistory from "./components/reward/AllReward/RewardHistory";
import ArchiveMngt from "./pages/AccountManagement/ArchiveMngt";
import BlockUserMngt from "./pages/AccountManagement/blockUserMngt";
import LevelMngt from "./pages/levelMngt/levelMngt";
import AddLevel from "./pages/levelMngt/addlevel";
import EditLevel from "./pages/levelMngt/editLevel";
import Daily_Achievement from "./pages/DailyAchievement/Daily-Achievement";
import Add_Achievement from "./pages/DailyAchievement/Add-achivement";
import DailyAchHistory from "./pages/DailyAchievement/ViewDetails"
import PrivacyPolicy from "./pages/cms/privacy_policy";
import AboutUs from "./pages/cms/about_us";
import TermsAndCondition from "./pages/cms/terms_and_condition";
import Faqs from "./pages/cms/faqs";
import ContactUs from "./pages/cms/contact_us";
import CreateFAQs from "./pages/cms/createFaq";
import EditFAQs from "./pages/cms/editFaq";
import SubAdmin from "./pages/subAdmin.js/subAdmin";
import NotificationMngt from "./pages/Notification/Notification";
import DailyBooster from "./pages/RewardManagement/DailyBooster";
import MilesReward from "./pages/RewardManagement/Miles-Reward";
import Purchase from "./components/ViewProfile/Purchase";
import { AddSubAdmin } from "./pages/subAdmin.js/AddSubAdmin";
import { EditSubAdmin } from "./pages/subAdmin.js/editSubAdmin";
import AddNotification from "./pages/Notification/AddNotification";
import TicketMngt from "./pages/ticketManagent/TicketMngt";
import SubAdminHistory from "./pages/subAdmin.js/subAdminLogs";
import LoginDevices from "./components/ViewProfile/loginDevices";
import DashBoard from "./pages/dashBoard/DashBoard";
import RedFlag from "./components/ViewProfile/redFlag";
import ClaimManagement from "./pages/claimManagement/ClaimManagement";
import ViewClaimDetailsPage from "./pages/claimManagement/ViewClaimDetailsPage";
import Rate from "./pages/RewardManagement/rate";
import HeweClaim from "./pages/claimManagement/heweClaim";
import QueryMngt from "./pages/queryManagement/QueryMngt";
const PublicRoute = (props) => {
  const { defaultState, setDefaultState } = props;

  const history = useHistory()
  if (!localStorage.getItem('token') || !sessionStorage.getItem('token')) {
    history.push("/")
  }

  


  return (
    <Switch>
      <Route path="/" exact>
        <LoginSection {...defaultState} />
      </Route>
     
     
      
    </Switch>
  );
};

const PrivateRoute = (props) => {
  const { defaultState, setDefaultState, userData } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return userData ? (
    <Switch>
      <Route path="/dashboard" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <DashBoard/>
      </Route>
      <Route path="/profile" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <ProfileManagement />
      </Route>
      <Route path="/manageDevice" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <ManageDevice />
      </Route>
      <Route path="/accountManagement" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <AccountManagement />
      </Route>
      <Route path="/accountManagement/archiveUser" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <ArchiveMngt />
      </Route>
      <Route path="/accountManagement/blockUser" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <BlockUserMngt />
      </Route>
      <Route path="/accountManagement/login-history/:id" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <LoginDevices/>
      </Route>
      <Route path="/accountManagement/purchase/:id" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <Purchase/>
      </Route>
      <Route path="/accountManagement/redflag/:id" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
       <RedFlag/>
      </Route>
      {/* voucher */}
      <Route path="/voucher" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <Voucher />
      </Route>
      <Route path="/other-reward/create-voucher" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <CreateVoucher />
      </Route>
      <Route path="/other-reward/edit-voucher/:id" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <EditVoucher />
      </Route>
      {/* inapp reward */}
      <Route path="/inApp-reward" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <InAppReward />
      </Route>
      <Route path="/other-reward/create-inApp-reward" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <CreateInAppReward />
      </Route>
      <Route path="/other-reward/edit-inApp-reward/:id" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <EditInAppReward />
      </Route>
      {/* exit */}
      {/* audit token */}
      <Route path="/audit-token" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <AuditToken />
      </Route>
      <Route path="/other-reward/create-auditToken" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <CreateAuditToken />
      </Route>
      <Route path="/other-reward/edit-auditToken/:id" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <EditAuditToken />
      </Route>
      {/* exit */}

      {/* premium token */}
      <Route path="/premium-reward" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <Premium />
      </Route>
      <Route path="/other-reward/create-premium-reward" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <CreatePremium />
      </Route>
      <Route path="/other-reward/edit-premium-reward/:id" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <EditPremium />
      </Route>
      {/* exit */}
      {/* trip token */}
      <Route path="/trip-reward" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <Trip />
      </Route>
      <Route path="/other-reward/create-trip-reward" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <CreateTrip />
      </Route>
      <Route path="/other-reward/edit-trip-reward/:id" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <EditTrip />
      </Route>
      {/* exit */}
      <Route path="/other-reward/history/:id" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <RewardHistory />
      </Route>

      <Route path="/level-management" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <LevelMngt/>
      </Route>
      <Route path="/level-management/add-level" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <AddLevel/>
      </Route>
      <Route path="/level-management/edit-level/:id" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <EditLevel/>
      </Route>
      <Route path="/daily-achievement" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <Daily_Achievement/>
      </Route>
      <Route path="/details-achievement/:id" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <DailyAchHistory/>
      </Route>

      <Route path="/daily-achievement/add-achievement" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <Add_Achievement/>
      </Route>

      <Route path="/privacy-policy" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <PrivacyPolicy/>
      </Route>

      <Route path="/about-us" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <AboutUs/>
      </Route>
      <Route path="/contact-us" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
      <ContactUs/>
      </Route>

      <Route path="/terms-and-conditions" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <TermsAndCondition/>
      </Route>
      <Route path="/faq" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <Faqs/>
      </Route>
      <Route path="/faq/create-faq" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
       <CreateFAQs/>
      </Route>
      <Route path="/edit-faq" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
       <EditFAQs/>
      </Route>
      <Route path="/refer-and-earn" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <ReferralAndEarn />
      </Route>
      <Route path="/dailyBooster" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <DailyBooster/>
      </Route>
      <Route path="/miles-reward" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <MilesReward/>
      </Route>
      <Route path="/rate" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <Rate/>
      </Route>
      <Route path="/other-reward" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <OtherReward />
      </Route>
      <Route path="/sub-admin-management" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <SubAdmin/>
      </Route>
      <Route path="/sub-admin-management/add-subAdmin" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <AddSubAdmin/>
      </Route>
      <Route path="/sub-admin-management/SubAdminHistory/:id" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <SubAdminHistory/>
      </Route>
      
      <Route path="/sub-admin-management/history" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <AddSubAdmin/>
      </Route>
      <Route path="/claim-management" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <ClaimManagement/>
      </Route>
      <Route path="/claim-management/view-details/:id" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <ViewClaimDetailsPage/>
      </Route>

      <Route path="/hewe-claim" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <HeweClaim/>
      </Route>
      <Route path="/sub-admin-management/edit-subAdmin/:id" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <EditSubAdmin/>
      </Route>
      <Route path="/notification-management" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <NotificationMngt/>
      </Route>
      <Route path="/notification-management/add-new" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <AddNotification/>
      </Route>
      <Route path="/ticket-management" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <TicketMngt/>
      </Route>
      <Route path="/query-management" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <QueryMngt/>
      </Route>


      <Route path="/accountManagement/view-profile/:id" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <ViewProfile />
      </Route>
      <Route path="/accountManagement/view-referral/:id" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <ViewReferral />
      </Route>
    
     
      <Route path="/changedPassword" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <ChangedPassword />
      </Route>
  
      <Route path="/" exact>
        <LoginSection {...defaultState} />
      </Route>
    </Switch>
  ) : (
    <PublicRoute />
  );
};

function App(props) {
  const { defaultState, setDefaultState, userData } = props;
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Router basename="/adminPanel">
        {userData ? <PrivateRoute userData={userData} /> : <PublicRoute />}
      </Router>
      <ToastContainer theme="colored" />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    locationData: state.locations,
    defaultState: state.defaultState,
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setLocations: (updatedValue) => {
      dispatch({
        type: actionTypes.GET_LOCATIONS,
        locationData: updatedValue,
      });
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
