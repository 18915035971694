import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useStyles } from "../../components/rewardTableTheme";
import axios from "../../axios";
import { toast } from "react-toastify";
import Overlay from "../../components/Overlay";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { FiArrowLeft } from "react-icons/fi";
import { FaAngleDown, FaArrowRight } from "react-icons/fa";
import Select from "react-select";
import { Stack } from "@mui/material";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { BsEye, BsPencil } from "react-icons/bs";
import moment from "moment/moment";
import { ArrowDown, ArrowUp, Filter } from "../../components/svg";
import { Modal } from "../../components/Modal";
import { MdOutlineClose } from "react-icons/md";

function PremiumClaims() {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);

  const parsedUserData =
    JSON.parse(localStorage.getItem("userData")) ||
    JSON.parse(sessionStorage.getItem("userData"));
  let modules = parsedUserData?.data?.modules;
  const permissions = modules?.find(
    (item) => item.moduleName === "Claim Management"
  )?.permissions;
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [paginationData, setPaginationData] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedOption, setSelectedOption] = useState();
  const [sortingValue, setSortingValue] = useState("");
  const [sortingType, setSortingType] = useState("")
  const options = [

    {
      label: "In Progress",
      value: "1"
    },
    {
      label: "View Details",
      value: "5"
    }
  ]
  // filter
  const [isShow, setIsShow] = useState(false)
  const [type, setType] = useState("")
  const handleCheckboxChange = (value) => {
    setType(prevType => (prevType === value ? "" : value));
  };

  const handleSort = (key) => {
    setSortingValue((prevSortingValue) => (prevSortingValue === key ? key : key));
    setSortingType((prevSortType) => {
      if (sortingValue === key) {
        return prevSortType === "1" ? "2" : "1";
      } else {
        return "1";
      }
    });
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    window.scrollTo(0, 0);
  };

  const handleRespose = async (types = type) => {
    setIsLoading(true);
    try {
      const data = await axios.get(`/private/getPremiums?page=${page}&limit=${limit}&sortType=${sortingType}&sortingValue=${sortingValue}&status=${types}`);
      setTableData(data?.data?.data?.docs);
      setPaginationData(data?.data?.data)
      setIsShow(false)
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.loog(error);
    }
  };
  useEffect(() => {
    handleRespose();
  }, [sortingType, sortingValue]);


  const [trackId, setTrackId] = useState("");
  const [trackingId, setTrackingId] = useState("");
  const [courierService, setCourierService] = useState("");
  const submit = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      let formdata = {
        trackingID: trackingId,
        courierService: courierService
      };
      await axios.post(`/private/addTrackingId/${trackId}`, formdata);
      handleRespose()
      setOpenModal(false)
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // filter
  const handleChange = async () => {
    setType("")
    await handleRespose("")
  }

  // out side click handle
  const dropdownRef = useRef(null);
  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div>
      <div className='d-flex align-items-center justify-content-end'>
        <div className="custom-dropdown-container " ref={dropdownRef}>
          <button className='filtertbn me-0' onClick={() => setIsShow(!isShow)}>
            <div className='d-flex align-items-center'><Filter /> <p className="ms-1 font-italic fw-400" style={{ color: "#464646c1" }}>Filter</p></div>   <span><FaAngleDown color='gray' /></span>
          </button>

          {
            isShow && <div className="otherfilterbody">
              <div>
              <div className="flex-align-center my-2">
                  <input
                    type="checkbox"
                    checked={type === "0"}
                    onChange={() => handleCheckboxChange("0")}
                  />
                  <label className="ms-3">Pending</label>
                </div>
                <div className="flex-align-center my-2">
                  <input
                    type="checkbox"
                    checked={type === "1"}
                    onChange={() => handleCheckboxChange("1")}
                  />
                  <label className="ms-3">In Progress</label>
                </div>
                <div className="flex-align-center my-2">
                  <input
                    type="checkbox"
                    checked={type === "2"}
                    onChange={() => handleCheckboxChange("2")}
                  />
                  <label className="ms-3">Completed</label>
                </div>
                <div className="mt-4 d-flex justify-content-end">
                  <button className="savebtn" onClick={() => handleRespose()}>Apply</button>
                  <button className="canclebtn ms-4" onClick={() => handleChange()}>Reset</button>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      {tableData?.length > 0 ? (
        <>
          <Paper
            className="mt-3"
          >
            <>
              <TableContainer className={classes.tableMainContainer} >
                <Table >
                  <TableHead>
                    <TableRow className={classes.tableHeadingRow}>
                      <TableCell className={classes.tablseHeadingCell}>
                        S.No
                      </TableCell>
                      <TableCell className={classes.tablseHeadingCell}>
                        Action
                      </TableCell>
                      <TableCell className={classes.tablseHeadingCell}>
                        Tracking Id
                      </TableCell>
                      <TableCell className={classes.tablseHeadingCell}>
                        User Name
                        <span onClick={() => handleSort("1")}>
                          {sortingValue === "1" && sortingType === "1" ? <ArrowUp /> : sortingValue === "1" && sortingType === "2" ? <ArrowDown /> : <ArrowUp />}
                        </span>
                      </TableCell>
                      <TableCell className={classes.tablseHeadingCell}>
                        Received on
                      </TableCell>
                      <TableCell className={[classes.tablseHeadingCell]}>
                        Claimed On
                      </TableCell>
                      <TableCell className={[classes.tablseHeadingCell]}>
                        Reward
                      </TableCell>
                      <TableCell className={[classes.tablseHeadingCell]}>
                        Address
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell className={classes.textMiddle} >
                          <div>{index + 1 + rowsPerPage * (page - 1)}</div>
                        </TableCell>

                        <TableCell className={classes.textMiddle}>
                          <div className="d-flex justify-content-center">
                            {
                              item.action == "0" ? (
                                <Select
                                  options={options}
                                  value={[{
                                    label: "Pending",
                                    value: "0"
                                  },]}
                                  onChange={(i) => {
                                    if (i?.value == "5") {
                                      history.push(`/claim-management/view-details/${item?._id}`)
                                    }
                                    if (i?.value == "1") {
                                      setTrackId(item?._id)
                                      setOpenModal(true)
                                    }
                                  }}
                                  className="w-120"
                                  isSearchable={false}
                                  classNamePrefix="react-select"
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      backgroundColor: "rgba(255, 251, 238, 0.38)",
                                      borderColor: "rgba(223, 222, 221, 0.54)",
                                      textAlign: "left",
                                      boxShadow: "none",
                                      color: "black",
                                      "&:hover": { borderColor: "black" },
                                    }),
                                  }}
                                />
                              ) : item.action == "1" ?
                                (<Select
                                  options={[{ label: "View Details" }]}
                                  value={[{ label: "In Progress" }]}
                                  onChange={(Select) => history.push(`/claim-management/view-details/${item?._id}`)}
                                  className="w-120"
                                  isSearchable={false}
                                  classNamePrefix="react-select"
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      backgroundColor: "rgba(255, 241, 191, 0.38)",
                                      borderColor: "rgba(251, 190, 29, 0.54)",
                                      textAlign: "left",
                                      boxShadow: "none",
                                      color: "black",
                                      "&:hover": { borderColor: "black" },
                                    }),
                                  }}
                                />) : item?.action == "2" ? (
                                  <Select
                                    options={[{ label: "View Details" }]}
                                    value={[{ label: "Completed" }]}
                                    onChange={(Select) => history.push(`/claim-management/view-details/${item?._id}`)}
                                    className="w-120"
                                    isSearchable={false}
                                    classNamePrefix="react-select"
                                    styles={{
                                      control: (provided) => ({
                                        ...provided,
                                        backgroundColor: "rgba(235, 255, 238, 1)",
                                        borderColor: "rgba(17, 153, 38, 0.25)",
                                        textAlign: "left",
                                        boxShadow: "none",
                                        color: "black",
                                        "&:hover": { borderColor: "black" },
                                      }),
                                    }}
                                  />
                                ) : null
                            }
                          </div>

                        </TableCell>
                        <TableCell className={classes.textMiddle}>
                          {item?.trakingId ?? "-"}
                        </TableCell>
                        <TableCell className={classes.textMiddle}>
                          <div className="link" onClick={()=>history.push(
                                    `/accountManagement/view-profile/${item?.userId}`
                                )}>
                          {item?.profileName}
                          </div>
                        </TableCell>
                        <TableCell className={classes.textMiddle}>
                          {moment(item?.receivedOn).format("lll")}
                        </TableCell>
                        <TableCell className={classes.textMiddle}>
                          {item?.createdAt ? moment(item.createdAt).format("lll") : "-"}
                        </TableCell>
                        <TableCell className={classes.textMiddle}>    {item?.rewardName}</TableCell>
                        <TableCell className={classes.textMiddle}>
                          <span className="truncate" >
                            <Tooltip
                              title={` ${item?.address?.addressLine1}, ${item?.address?.addressLine2}, ${item?.address?.city}, ${item?.address?.state} ${item?.address?.zipCode}`}
                              arrow
                            >
                              <span className="d-block ">
                              {item?.address?.addressLine1}, {item?.address?.addressLine2}, {item?.address?.city}, {item?.address?.state}, {item?.address?.zipCode}
                              </span>
                            </Tooltip>
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              <Stack
                spacing={2}
                className="flex-align-center justify-content-center w-100 py-3"
              >
                <Pagination
                  count={Math.ceil(paginationData?.totalDocs / rowsPerPage)}
                  page={page}
                  onChange={(event, value) => handleChangePage(event, value)}
                  renderItem={(item) => (
                    <PaginationItem
                      slots={{ previous: FiArrowLeft, next: FaArrowRight }}
                      {...item}
                    />
                  )}
                />
              </Stack>
              </TableContainer>
            </>
          </Paper>
        </>
      ) : (
        <div className="emptyTable mt-3">No Data Found</div>
      )}
      <Modal
        maxWidth="lg"
        width="680px"
        RoundedCorners={true}
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        backgroundModal={false}
        backgroundModalContent={false}
        title={
          <div className="p-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <p className="ps-2">Status:In Progress</p>
              </div>
              <MdOutlineClose
                style={{
                  fontWeight: 600,
                  fontSize: "22px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenModal(false);
                }}
              />
            </div>

          </div>
        }
        content={
          <div className=''>
            <form onSubmit={submit}>
              <div className="d-flex justify-content-center align-items-center">
                <label>Courier service</label>
                <input
                  type="text"
                  required
                  className="tracinpt ms-5"
                  placeholder=""
                  value={trackingId}
                  onChange={(e) => setTrackingId(e.target.value)}
                />
              </div>
              <div className="d-flex justify-content-center align-items-center mt-4">
                <label>Tracking Id</label>
                <input
                  type="text"
                  required
                  className="tracinpt ms-5"
                  placeholder=""
                  value={courierService}
                  onChange={(e) => setCourierService(e.target.value)}
                />
              </div>
              <div className="d-flex justify-content-center my-4">
                <button type="submit" className="savebtn px-5 py-2" disabled={isLoading}>
                  {isLoading ? "Submitting..." : "Submit"}
                </button>
                <button
                  type="button"
                  className="cancelbtn px-5 py-2 ms-4"
                  onClick={() => setOpenModal(false)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        }
      />
      {isLoading && <Overlay />}
    </div>

  );
}

export default PremiumClaims;
