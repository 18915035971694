import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import axios from "../../../axios"
import { toast } from 'react-toastify';
import Overlay from '../../Overlay';
import "../rewardspin.scss"
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { BsEye } from 'react-icons/bs';
import CustomizedSwitches from '../../togglebtn';
import CustomizedSwitchesProbility from '../../ProbilityToggleButton';
import { useStyles } from '../../rewardTableTheme';
export default function AllReward({ userData }) {
    const parsedUserData = JSON.parse(localStorage.getItem("userData")) || JSON.parse(sessionStorage.getItem("userData"));
    let modules = parsedUserData?.data?.modules;
    const permissions = modules?.find((item) => item.moduleName === "Reward Management")?.permissions;
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    const [tableData, setTableData] = useState();
    const history = useHistory();
    const [refreshData, setRefreshData] = useState(true);
    const [activeReward, setActiveReward] = useState()
    const [totalReward, setTotalReward] = useState()
    const handleResponse = async () => {
        setIsLoading(true);
        try {
            const data = await axios.get("/private/getRewardByCategory/0");
            setActiveReward(data?.data?.data?.activeReward);
            setTotalReward(data?.data?.data?.totalReward)
            setTableData(data?.data?.data?.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (refreshData) {
            handleResponse();
            setRefreshData(false);
        }
    }, [refreshData]);
    const changeStatus = async (id, status) => {
        setIsLoading(true);
        try {
            const formData = { status };
            const data = await axios.put(`private/changeRewardStatus/${id}`, formData);
            await handleResponse();
            toast.success(data?.data?.message);
            setRefreshData(true);
            window.location.reload();
        } catch (error) {
            if (error.response && error.response.status === 400) {
                toast.error(error.response.data.message);
                handleResponse();
            }
            setTimeout(() => {
                window.location.reload()
            }, 2000);
            // toast.error(error.response.message)
        } finally {
            setIsLoading(false);
        }
    };

    const changeProbilityStatus = async (id, status) => {
        setIsLoading(true);
        try {
            const formData = { status };
            const data = await axios.put(`private/changeProbilityStatus/${id}`, formData);
            await handleResponse();
            toast.success(data?.data?.message);
        } catch (error) {
            if (error.message === 'Network Error') {
                window.location.reload();
            }
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <div>
            <div className="referralcont">
                <div className="flex-align-center justify-content-between">
                    <div className='flex-align-center justify-content-between w-100'>
                        <h4 className="font-dark-green mb-0 fs-22">All Reward</h4>
                        <div className='d-flex flex-column align-items-end' >
                            <p className=''>Total Reward: <strong>{totalReward}</strong></p>
                            <p>Active Reward: <strong>{activeReward}</strong></p>
                        </div>
                    </div>

                </div>
                {tableData?.length > 0 ? (<>
                    <Paper className="mt-3" style={{ position: "relative", height: "60vh", overflowY: "scroll" }}>
                        <>
                            <TableContainer className={classes.tableMainContainer}>
                                <Table >
                                    <TableHead >
                                        <TableRow className={classes.tableHeadingRow}>
                                            <TableCell className={classes.tablseHeadingCell} >
                                                S.No
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Category Name
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Reward Name
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Available Qty
                                            </TableCell>
                                            <TableCell className={[classes.tablseHeadingCell]}>
                                                Details
                                            </TableCell>
                                            <TableCell className={[classes.tablseHeadingCell]}>
                                                Probability
                                            </TableCell>
                                            <TableCell className={[classes.tablseHeadingCell]}>
                                                Probability Calculation
                                            </TableCell>
                                            <TableCell className={[classes.tablseHeadingCell]}>
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableData?.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell className={classes.textMiddle}>
                                                    <div>{index + 1}</div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <div>{item?.categoryName == 1
                                                        ? "Vouchers"
                                                        : item?.categoryName == 2
                                                            ? "In App Rewards"
                                                            : item?.categoryName == 3
                                                                ? "Audit Token"
                                                                : item?.categoryName == 4
                                                                    ? "Premium Rewards"
                                                                    : item?.categoryName == 5
                                                                        ? "Trip Rewards"
                                                                        : "Better luck next time"}</div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <div>{item?.rewardAmount} {item?.rewardName}</div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <div>{item?.quantity}</div>
                                                </TableCell>

                                                <TableCell className={classes.textMiddle}>
                                                    <div className='historybtn' onClick={() => history.push(`/other-reward/history/${item?._id}`, { state: item })}><BsEye /> history</div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <div>{item?.probability}%</div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    {(userData.userType === "admin" || userData.userType == "subAdmin" && permissions?.edit == true) ?
                                                        <>
                                                            {item?._id != "6719e313c3903216351314da" ? <div className='d-flex justify-content-center'>
                                                                <CustomizedSwitchesProbility item={item} changeProbilityStatus={changeProbilityStatus} />
                                                            </div> : null}
                                                        </> : "N/A"
                                                    }
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <div className='d-flex justify-content-center'>
                                                        {(userData.userType === "admin" || userData.userType == "subAdmin" && permissions?.edit == true) ?
                                                            <>
                                                                {item?._id != "6719e313c3903216351314da" ? <div className='d-flex justify-content-center'>
                                                                    <CustomizedSwitches item={item} changeStatus={changeStatus} />
                                                                </div> : null}
                                                            </> : null
                                                        }
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    </Paper>
                </>) : (
                    <div className="emptyTable mt-3">
                        No Data Found
                    </div>
                )}
            </div>
            {isLoading && <Overlay />}
        </div>
    )
}
