import React, { useEffect, useState } from 'react'
import { Formik } from "formik";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";
import { DashHeading } from '../AccountManagement/AccountManagementElements';
import axios from "../../axios";
import Overlay from "../../components/Overlay";
import { connect } from "react-redux";
import { withRouter, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
function ContactUs({ userData }) {
    const parsedUserData = JSON.parse(localStorage.getItem("userData")) || JSON.parse(sessionStorage.getItem("userData"));
    let modules = parsedUserData?.data?.modules;
    const permissions =modules?.find((item) => item.moduleName === "CMS Management")?.permissions;
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState("")


    const getData = async () => {
        try {
            const data = await axios.get(
                `/private/findCms/?type=contactUs`
            );
            setData(data?.data?.data[0]?.content);
            toast.success(data?.message);
        } catch (error) {
            console.log(error)
        }
    }
    const cancelFun = async (setFieldValue) => {
        await getData(); 
        setFieldValue("content", data);
    };

    useEffect(() => {
        getData()
    }, [])

    const updatePrivacy = async (values) => {
        setIsLoading(true)

        let formData = {
            content: values.content
        }
        try {
            const data = await axios.post(`/private/updateCms?type=contactUs`,
                formData
            );
            getData();
            toast.success(data?.data?.message);
            setIsLoading(false)
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <div className='hewe-cont'>
                <div className="flex-align-center mb-5">
                    <div className="dott me-3"></div>
                    <DashHeading>Contact Us</DashHeading>
                </div>

                <Formik
                    enableReinitialize
                    initialValues={{ content: data }}
                    onSubmit={(values) => updatePrivacy(values)}
                >
                    {({ values, setFieldValue, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <JoditEditor
                                config={{
                                    askBeforePasteFromWord: false,
                                    askBeforePasteHTML: false,
                                    defaultActionOnPaste: "",
                                    readonly: false,
                                    toolbarStyle: {
                                        backgroundColor: "red",
                                    },
                                }}
                                value={values.content}
                                onBlur={(newContent) => {
                                    setFieldValue("content", newContent);
                                }}
                            />
                            <div className="mt-4 d-flex">

                                {(userData.userType === "admin" || userData.userType == "subAdmin" && permissions?.edit == true) &&
                                    <button type="submit" className="savebtn">
                                        Save
                                    </button>
                                }
                                <button
                                    type="button"
                                    className="canclebtn ms-3"
                                    onClick={() => cancelFun(setFieldValue)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>

            </div>
            {isLoading && <Overlay />}

        </>
    )
}


const mapStateToProps = (state) => {
    return {
        userData: state.userData,
        locationData: state.locations,
        defaultState: state.defaultState,
        sidebar: state.sidebar,
    };
};

export default connect(
    mapStateToProps,
    // mapDispatchToProps
)(withRouter(ContactUs));
