import React, { useState, useEffect } from "react";
import { DashHeading } from '../AccountManagement/AccountManagementElements'
import { FaPlus } from 'react-icons/fa'
import "./style.scss"
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import axios from "../../axios"
import { makeStyles } from "@material-ui/core/styles";
import { get } from "lodash";
import {  useHistory } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import { FaArrowRight } from "react-icons/fa";
import { PaginationItem } from "@material-ui/lab";
import { Pagination, Stack } from "@mui/material";
import Overlay from "../../components/Overlay";
const useStyles = makeStyles((theme) => ({
  paperTableHeight: {
    width: "100%",
    minHeight: "50vh",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    border: "1px solid #D9E3D9",
    overflowX: "scroll",
  },
  tableMainContainer: {
    overflowX: "scroll",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  tableHeadingRow: {
    // backgroundColor: "#21AFE6",
  },
  tablseHeadingCell: {
    padding: "0.8rem 20px !important",
    textAlign: "center",
    fontSize: "14px",
    color: "#264503",
    fontWeight: 600,
    borderBottom: "1px solid #D9E3D9",
    whiteSpace: "nowrap",
  },
  textMiddle: {
    padding: "0.6rem 20px !important",
    verticalAlign: "middle !important",
    textAlign: "center",
    fontSize: "14px",
    color: "black",
  },
  tablePaginationStyle: {
    borderTop: "1px solid #0000001a",
    borderRadius: "0rem 0rem 0.4rem 0.4rem",
    overflowY: "hidden",
  },
  sticky: {
    position: "sticky",
    left: "0"
  }
}));
function NotificationMngt() {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, SetTableData] = useState([]);
  // For Pagination
  const [page, setPage] = useState(1);
  // For Search
  const [limit, setLimit] = useState(10);
  const [paginationData, setPaginationData] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    window.scrollTo(0, 0);
  };
  const getData = async () => {
    try {
      setIsLoading(true)
      const { data } = await axios.get(
        `/private/getNotification?page=${page}&limit=${limit}`
      );
      setPaginationData(data?.data);
      SetTableData(data?.data?.docs);
      setIsLoading(false)

    } catch (error) {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [page])

  return (
    <div className='hewe-cont'>
      <div className='flex-align-center  justify-content-between'>
        <div className="flex-align-center mb-0 ">
          <div className="dott me-3"></div>
          <DashHeading>Notification Management</DashHeading>
        </div>
        <div>
          <button className='savebtn' onClick={() => history.push("/notification-management/add-new")}> <FaPlus /> &nbsp; Create New</button>
        </div>
      </div>
      <div className="mt-4">
        {tableData?.length > 0 ? (<>
          <Paper className={classes.paperTableHeight}>
            <>
              <TableContainer
                className={classes.tableMainContainer}
              >
                <Table>
                  <TableHead>
                    <TableRow className={classes.tableHeadingRow}>
                      <TableCell className={classes.tablseHeadingCell}>
                        S.No
                      </TableCell>
                      <TableCell className={classes.tablseHeadingCell}>
                        Notification Type
                      </TableCell>
                      <TableCell className={classes.tablseHeadingCell}>
                        Title
                      </TableCell>
                      <TableCell className={classes.tablseHeadingCell}>
                        Description
                      </TableCell>
                      <TableCell className={classes.tablseHeadingCell}>
                        User Type
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.map((category, index) => (
                      <TableRow key={category._id}>
                        <TableCell className={classes.textMiddle}>
                          <div>{index + 1 + rowsPerPage * (page - 1)}</div>
                        </TableCell>

                        <TableCell className={classes.textMiddle}>
                          <div>
                            {
                              get(category, "notificationType") === "0" ? <div className="General">General</div> :
                                get(category, "notificationType") === "1" ? <div className="Warning">Warning</div> :
                                  get(category, "notificationType") === "2" ? <div className="Schedule">Schedule</div> :
                                    "Unknown"
                            }
                          </div>
                        </TableCell>
                        <TableCell className={classes.textMiddle}>
                          <div>
                          {get(category, "title", 0)}

                          </div>
                        </TableCell>
                        <TableCell className={classes.textMiddle}>
                        {get(category, "description", 0)}
                        </TableCell>
                        <TableCell className={classes.textMiddle}>
                          <div >
                            {get(category, "sendType")}
                          </div>
                        </TableCell>

                      </TableRow>
                    ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <Stack spacing={2} className="flex-align-center justify-content-center w-100">
                <Pagination
                  count={Math.ceil(paginationData?.totalDocs / rowsPerPage)}
                  page={page}
                  onChange={(event, value) => handleChangePage(event, value)}
                  renderItem={(item) => (
                    <PaginationItem
                      slots={{ previous: FiArrowLeft, next: FaArrowRight }}
                      {...item}
                    />
                  )}
                />
              </Stack>
            </>
          </Paper>
        </>) : (
          <div className="emptyTable mt-3">
            No Data Found
          </div>
        )}
      </div>
      {isLoading && <Overlay />}
    </div>
  )
}

export default NotificationMngt