import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import axios from "../../../axios"
import Overlay from '../../Overlay';
import "./style.scss"
import "../rewardspin.scss"
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    makeStyles,
} from "@material-ui/core";
import { FiArrowLeft } from "react-icons/fi";
import { FaArrowRight } from "react-icons/fa";
import { PaginationItem } from "@material-ui/lab";
import { Pagination, Stack } from "@mui/material";
import { IoChevronBack } from 'react-icons/io5';
import { DateFormater } from '../../dateFormater';
import SearchBar from 'material-ui-search-bar';
import { FilterSvg } from '../../svg';
import moment from 'moment';
const useStyles = makeStyles((theme) => ({
    paperTableHeight: {
        width: "95%",
        marginLeft: "2rem",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        border: "1px solid #D9E3D9",
        overflowX: "scroll",
    },
    tableMainContainer: {
        overflowX: "scroll",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
    },
    tableHeadingRow: {
        // backgroundColor: "#21AFE6",
    },
    tablseHeadingCell: {
        padding: "0.8rem 20px !important",
        textAlign: "center",
        fontSize: "14px",
        color: "#264503",
        fontWeight: 600,
        borderBottom: "1px solid #D9E3D9",
        whiteSpace: "nowrap",
    },
    textMiddle: {
        padding: "0.6rem 20px !important",
        verticalAlign: "middle !important",
        textAlign: "center",
        fontSize: "14px",
        color: "#00000080",
    },
    tablePaginationStyle: {
        borderTop: "1px solid #0000001a",
        borderRadius: "0rem 0rem 0.4rem 0.4rem",
        overflowY: "hidden",
    },
    button: {
        textAlign: "center",
        backgroundColor: "#88AE40",
        padding: "7px 30px",
        color: "white",
        width: "250px",
        border: "none",
        borderRadius: "4px"
    },
}));
export default function RewardHistory() {
    const [stateData, setStateData] = useState()
    const param = useParams();
    const { state } = useLocation();
    useEffect(() => {
        setStateData(state?.state)
    }, [])
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    const [tableData, setTableData] = useState();
    const [totalReward, setTotalReward] = useState();
    const [recivedReward, setRecivedReward] = useState();
    const [voucherCode, setVoucherCode] = useState()
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [paginationData, setPaginationData] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState("");
    const [filter, setFilterValue] = useState({
        startDate: "",
        endDate: "",
    })
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        window.scrollTo(0, 0);
    };
    const history = useHistory();
    const handleResponse = async (id, filterPayload = filter) => {
        setIsLoading(true);
        try {
            const data = await axios.post(`/private/history/${param?.id}?search=${searchTerm}&page=${page}&limit=${limit}`, filterPayload);
            setFilterOpen(false)
            setTableData(data?.data?.data?.data?.docs);
            setPaginationData(data?.data?.data?.data);
            setTotalReward(data?.data?.data?.totalReward);
            setRecivedReward(data?.data?.data?.receivedReward);
            setVoucherCode(data?.data?.data?.voucherCode)
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        handleResponse();
    }, [page, searchTerm]);

    const [filterOpen, setFilterOpen] = React.useState(false)
    const handleDateChange = (event) => {
        const { name, value } = event.target;
        const formattedDate = new Date(value).toISOString();
        setFilterValue((prev) => ({
            ...prev,
            [name]: formattedDate,
        }));
    };

    const handleCancel = () => {
        setFilterValue({
            deviceType: [],
            startDate: "",
            endDate: "",
        });
        setFilterOpen(!filterOpen);
        handleResponse(null, {});
    };
    const handleFilterOpen = () => {
        setFilterOpen(!filterOpen)
    }

    const cancelSearch = () => {
        setSearchTerm("");
    };
    function myDeb(call, d = 1000) {
        let timer;
        return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                call(...args);
            }, d);
        };
    }

    const SearchAccountManagement = myDeb((search) => {
        setSearchTerm(search);
    });
    return (
        <div className="hewe-cont">
            <div className="referralcont">
                <div className="flex-align-center justify-content-between">
                    <div className='flex-align-center'>
                        <IoChevronBack
                            size={30}
                            onClick={() =>
                                history.push("/other-reward")
                            }
                        />
                        <h3 className="font-dark-green mb-0 fs-22 main-heading">History</h3>
                    </div>
                </div>
                <div className="mt-3 ms-2 me-3 d-flex flex-wrap historystat">
                    <h5>Reward Name: <strong>{stateData?.rewardName}</strong></h5>

                    {stateData?.brandName && (
                        <>
                            <h5 className="mx-2">|</h5>

                            <h5>Brand Name: <strong>{stateData?.brandName}</strong></h5>
                        </>
                    )}

                    <h5 className="mx-2">|</h5>
                    <h5>
                        Distributed Qty:{" "}
                        <strong>
                            {recivedReward}
                            {totalReward != "" && <span>/{totalReward}</span>}
                        </strong>
                    </h5>
                    {stateData?.rewardExpireDate && <>
                        <h5 className="mx-2">|</h5>
                        <h5 className='flex-align-center'>Expire Date:<strong className='ps-1'><DateFormater item={stateData?.rewardExpireDate} /></strong></h5>
                    </>}

                    {voucherCode && (
                        <>
                            <h5 className="mx-2">|</h5>
                            <h5>Voucher Code: <strong>{voucherCode}</strong></h5>
                        </>
                    )}
                </div>


                <div className='d-flex ms-2 align-items-center position-relative mb-3'>
                    <SearchBar
                        style={{ background: "#FDFFFB", minWidth: "353px", Height: "33px", borderRadius: "4px", fontStyle: "italic", color: "#FDFFFB" }}
                        className='searchbars'
                        onChange={(e) => {
                            SearchAccountManagement(e);
                        }}
                        onCancelSearch={cancelSearch}
                        placeholder="Search User Name...."
                        inputProps={{ maxLength: 30 }}
                    />
                    <div className='ms-4 pointer' style={{ color: "#264623" }} onClick={handleFilterOpen}> <FilterSvg /> Advanced Filters</div>
                    {
                        filterOpen && (
                            <div className="filterBody">
                                <div className="py-2">
                                    <div>
                                        <label className="pb-1 text-24363a  fw-600">Start Date</label>
                                    </div>
                                    <input
                                        type="date"
                                        name="startDate"
                                        color='#464646a4'
                                        max={new Date().toISOString().split('T')[0]}
                                        className="calender"
                                        value={filter.startDate ? new Date(filter.startDate).toISOString().split('T')[0] : ""}
                                        onChange={handleDateChange}
                                    />
                                </div>

                                <div>
                                    <div className="pb-1">
                                        <label className='text-24363a fw-600 '>End Date</label>
                                    </div>
                                    <input
                                        type="date"
                                        name="endDate"
                                        color='#464646a4'
                                        min={filter.startDate}
                                        max={new Date().toISOString().split('T')[0]}
                                        className="calender"
                                        value={filter.endDate ? new Date(filter.endDate).toISOString().split('T')[0] : ""}
                                        onChange={handleDateChange}
                                    />
                                </div>

                                <div className="mt-3 d-flex align-items-center justify-content-end">
                                    <button
                                        type="button"
                                        className="canclebtn"
                                        onClick={handleCancel}

                                    >
                                        Reset
                                    </button>
                                    <button
                                        className="savebtn ms-3"
                                        onClick={handleResponse}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        )}
                </div>

                {tableData?.length > 0 ? (<>
                    <Paper className=" ms-2">
                        <>
                            <TableContainer className={classes.tableMainContainer}>
                                <Table>
                                    <TableHead>
                                        <TableRow className={classes.tableHeadingRow}>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                S.No
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                User Name
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Received On
                                            </TableCell>

                                            {tableData?.[0]?.RewardData?.categoryName != "2" && <TableCell className={classes.tablseHeadingCell}>
                                                Claimed On
                                            </TableCell>}

                                            <TableCell className={[classes.tablseHeadingCell]}>
                                                Source
                                            </TableCell>
                                            {tableData?.[0]?.RewardData?.categoryName != "2" && <TableCell className={classes.tablseHeadingCell}>
                                                Action Taken
                                            </TableCell>}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableData?.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell className={classes.textMiddle}>
                                                    {index + 1 + rowsPerPage * (page - 1)}
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <div>{item?.profileName}</div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <DateFormater item={item?.createdAt} />
                                                </TableCell>
                                                {tableData?.[0]?.RewardData?.categoryName != "2" &&
                                                    <TableCell className={classes.textMiddle}>
                                                        <div>{moment(item?.createdAt).format('lll')}</div>
                                                    </TableCell>
                                                }

                                                <TableCell className={classes.textMiddle}>
                                                    <div > {item?.RewardData?.source}</div>
                                                </TableCell>

                                                {tableData?.[0]?.RewardData?.categoryName != "2" &&
                                                    <TableCell className={classes.textMiddle}>
                                                        <div>{item?.action == "1" ? <p className='fw-700  font-g1'>Claimed</p> : item?.action == "2" ? <p className='fw-700 font-dark-green '>Reedeemed</p> : item?.action == "3" ? <p className='fw-700 text-red'>Expire</p> : "-"}</div>
                                                    </TableCell>
                                                }
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Stack spacing={2} className="flex-align-center justify-content-center w-100">
                                <Pagination
                                    count={Math.ceil((paginationData?.totalDocs || 0) / rowsPerPage) || 1}
                                    page={page}
                                    onChange={handleChangePage}
                                    renderItem={(item) => (
                                        <PaginationItem
                                            slots={{ previous: FiArrowLeft, next: FaArrowRight }}
                                            {...item}
                                        />
                                    )}
                                />
                            </Stack>
                        </>
                    </Paper>
                </>) : (
                    <div className="emptyTable mt-3 ms-2">
                        No Data Found
                    </div>
                )}
            </div>
            {isLoading && <Overlay />}
        </div>
    )
}
