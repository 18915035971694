import React, { useState, useEffect } from "react";
import { DashHeading } from '../AccountManagement/AccountManagementElements'
import "./style.scss"
import { FaEdit, FaPlus, FaRegTrashAlt } from 'react-icons/fa'
import { BsPencil } from 'react-icons/bs';
import Select from "react-select";
import { Modal } from "../../components/Modal";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
} from "@material-ui/core";
import axios from "../../axios"
import { makeStyles } from "@material-ui/core/styles";
import { get } from "lodash";
import { withRouter, useHistory } from "react-router-dom";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { FiArrowLeft } from "react-icons/fi";
import { FaArrowRight } from "react-icons/fa";
import { Stack } from "@mui/material";
import Overlay from "../../components/Overlay";
import { BiPencil } from "react-icons/bi";
import { MdOutlineClose } from "react-icons/md";
import { toast } from "react-toastify";
import { Delete } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
    paperTableHeight: {
        width: "100%",
        maxHeight: "120vh",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        border: "1px solid #D9E3D9",
        overflowX: "scroll",
    },
    tableMainContainer: {
        minHeight: "50vh",
        overflowX: "scroll",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
    },
    tableHeadingRow: {
        // backgroundColor: "#21AFE6",
    },
    tablseHeadingCell: {
        padding: "0.8rem 20px !important",
        textAlign: "center",
        fontSize: "14px",
        color: "#264503",
        fontWeight: 600,
        borderBottom: "1px solid #D9E3D9",
        whiteSpace: "nowrap",
    },
    textMiddle: {
        padding: "0.6rem 20px !important",
        verticalAlign: "middle !important",
        textAlign: "center",
        fontSize: "14px",
        color: "black",
    },
    tablePaginationStyle: {
        borderTop: "1px solid #0000001a",
        borderRadius: "0rem 0rem 0.4rem 0.4rem",
        overflowY: "hidden",
    },
    sticky: {
        position: "sticky",
        left: "0"
    }
}));
function QueryMngt() {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, SetTableData] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [query, setQuery] = useState("");
    const [deleteId, setDeleteId] = useState();
    const [editQuerys, setEditQuerys] = useState({});


    const getData = async () => {
        try {
            setIsLoading(true)
            const { data } = await axios.get(
                `/private/getAllQueries`
            );
            SetTableData(data?.data);
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }
    useEffect(() => {
        getData()
    }, [])

    const create = async () => {
        setIsLoading(true);
        try {
            const formdata = { question: query };
            const { data } = await axios.post("/private/createQueries", formdata);
            toast.success(data?.message);
            setQuery("");
            getData()
            setOpenModal(false);
        } catch (error) {
            toast.error(error.response?.data?.message || "Something went wrong!");
        } finally {
            setIsLoading(false);
        }
    };
    const deleteQuery = async () => {
        setIsLoading(true);
        try {
            const { data } = await axios.delete(`/private/deleteQueries/${deleteId}`);
            toast.success(data?.message);
            setEditQuerys({})
            getData()
            setOpenModalDelete(false);

        } catch (error) {
            toast.error(error.response?.data?.message || "Something went wrong!");
        } finally {
            setIsLoading(false);
        }
    };

    const editQuery = async () => {
        setIsLoading(true);
        try {
            const formdata = { question: editQuerys.question };
            const { data } = await axios.post(`/private/updateQueries/${editQuerys?._id}`, formdata);
            toast.success(data?.message);
            setEditQuerys({});
            getData()
            setOpenModalEdit(false);
        } catch (error) {
            toast.error(error.response?.data?.message || "Something went wrong!");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='hewe-cont'>
            <div className='flex-align-center  justify-content-between'>
                <div className="flex-align-center mb-0 ">
                    <div className="dott me-3"></div>
                    <DashHeading>Query Management</DashHeading>
                </div>
                <div>
                    <button className="savebtn" onClick={() => setOpenModal(!openModal)}>+ Add Query</button>
                </div>
            </div>
            <div className="mt-4">
                {tableData?.length > 0 ? (<>
                    <Paper className={classes.paperTableHeight}>
                        <>
                            <TableContainer
                                className={classes.tableMainContainer}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow className={classes.tableHeadingRow}>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                S.No
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Ticket ID
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableData.map((category, index) => (
                                            <TableRow key={category._id}>
                                                <TableCell className={classes.textMiddle}>
                                                    <div>{index + 1}</div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    {get(category, "question", "-")}
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <div className="d-flex justify-content-center">
                                                        <div className="pointer" onClick={() => { setOpenModalEdit(true); setEditQuerys(category) }}>
                                                            <BsPencil className='actionicon'
                                                            /><span className='underline p-1  fs-16 font-g2'>Edit</span>
                                                        </div>
                                                        <div className="ms-3 pointer" onClick={() => { setOpenModalDelete(true); setDeleteId(category._id) }} >
                                                            <FaRegTrashAlt className='deleteicon' /> <spna
                                                                className="font-b2 underline p-1 fs-16">Delete</spna>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    </Paper>
                </>) : (
                    <div className="emptyTable mt-3">
                        No Data Found
                    </div>
                )}
            </div>
            {/* for create */}
            <Modal
                maxWidth="lg"
                width="680px"
                RoundedCorners={true}
                isOpen={openModal}
                onClose={() => {
                    setOpenModal(false);
                }}
                backgroundModal={false}
                backgroundModalContent={false}
                title={
                    <div className="">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <p className="ps-2">Add Query</p>
                            </div>
                            <MdOutlineClose
                                style={{
                                    fontWeight: 600,
                                    fontSize: "22px",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setQuery("")
                                    setOpenModal(false);
                                }}
                            />
                        </div>

                    </div>
                }
                content={
                    <div className=''>
                        <form onSubmit={(e) => { e.preventDefault(); create(); }}>
                            <div className="">
                                {/* <label className="fs-20 pb-2">Enter Query</label> */}
                                <div>
                                    <textarea
                                        required
                                        className="tracinpt w-100" style={{ height: "150px" }}
                                        placeholder="Enter Query"
                                        
                                        value={query}
                                        onChange={(e) => {
                                            const trimmedValue = e.target.value.replace(/\s{2,}/g, ' ');
                                            // formik.setFieldValue('title', trimmedValue.trimStart());
                                            setQuery(trimmedValue.trimStart())}}
                                    >
                                    </textarea>
                                </div>
                            </div>
                            <div className="d-flex justify-content-start my-4">
                                <button type="submit" className="savebtn px-5 py-2" disabled={isLoading}>
                                    {isLoading ? "Submitting..." : "Submit"}
                                </button>
                                <button
                                    type="button"
                                    className="cancelbtn px-5 py-2 ms-4"
                                    onClick={() => { setQuery(""); setOpenModal(false) }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                }
            />

            {/* for delete */}
            <Modal
                maxWidth="lg"
                RoundedCorners={true}
                isOpen={openModalDelete}
                onClose={() => {
                    setOpenModalDelete(false);
                }}
                backgroundModal={false}
                backgroundModalContent={false}
                title={
                    <div className="">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <p className="ps-2">Are you sure?</p>
                            </div>
                            <MdOutlineClose
                                style={{
                                    fontWeight: 600,
                                    fontSize: "22px",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setOpenModalDelete(false);
                                    setDeleteId("")
                                }}
                            />
                        </div>

                    </div>
                }
                content={
                    <div className=''>
                        <div className="d-flex justify-content-start my-4">
                            <button type="submit" onClick={() => deleteQuery()} className="savebtn px-5 py-2" >
                                Delete
                            </button>
                            <button
                                type="button"
                                className="cancelbtn px-5 py-2 ms-4"
                                onClick={() => { setOpenModalDelete(false); setDeleteId("") }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                }
            />

            {/* for edit */}
            <Modal
                maxWidth="lg"
                width="680px"
                RoundedCorners={true}
                isOpen={openModalEdit}
                onClose={() => {
                    setEditQuerys({})
                    setOpenModalEdit(false);
                }}
                backgroundModal={false}
                backgroundModalContent={false}
                title={
                    <div className="">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <p className="ps-2">Edit Query</p>
                            </div>
                            <MdOutlineClose
                                style={{
                                    fontWeight: 600,
                                    fontSize: "22px",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setEditQuerys({})
                                    setOpenModalEdit(false);
                                }}
                            />
                        </div>

                    </div>
                }
                content={
                    <div className=''>
                        <form onSubmit={(e) => { e.preventDefault(); editQuery(); }}>
                            <div className="">
                                {/* <label className="fs-20 pb-2">Enter Query</label> */}
                                <div>
                                    <textarea
                                        required
                                        className="tracinpt w-100" style={{ height: "150px" }}
                                        placeholder="Enter Query"
                                        value={editQuerys.question}
                                        onChange={(e) => {
                                            const trimmedValue = e.target.value.replace(/\s{2,}/g, ' ');
                                            setEditQuerys((prev) => ({ ...prev, question:trimmedValue.trimStart() }))}}
                                    >
                                    </textarea>
                                </div>
                            </div>
                            <div className="d-flex justify-content-start my-4">
                                <button type="submit" className="savebtn px-5 py-2" disabled={isLoading}>
                                    {isLoading ? "Submitting..." : "Submit"}
                                </button>
                                <button
                                    type="button"
                                    className="cancelbtn px-5 py-2 ms-4"
                                    onClick={() => { setEditQuerys({}); setOpenModalEdit(false) }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                }
            />

            {isLoading && <Overlay />}
        </div>
    )
}

export default QueryMngt

