import React, { useState, useEffect } from "react";
import { MdEmail, MdPhone } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import {
  DashboardContainer,
  DashboardWrapper,
  DashboardHeading,
  DashHeading,
  MenuAndBack,
  ProfileDetailForm,
  InputDivide,
} from "./ProfileElements";
import { LoginButton } from "./ProfileElements";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Field, Form } from "formik";
import InputLogin from "../../../components/InputLogin";
import axios from "../../../axios";
import Axios from "axios";
import Overlay from "../../../components/Overlay";
import Input from "../../../components/Input";
import { toast } from "react-toastify";
import { FaArrowLeft } from "react-icons/fa";
import { FaCamera } from "react-icons/fa";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import * as actionTypes from "../../../store/actions";
import { withRouter, Link, useHistory } from "react-router-dom";
import { EditProfile, passwordProfileValidator } from "../../../utils/validators";
import Avatar from "@mui/material/Avatar";
import dummyProfile from "../../../assets/images/lady.png";
import "./profile.css";
// import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';

import PhoneInput from "react-phone-input-2";
import { BsDot } from "react-icons/bs";
const useStyles = makeStyles((theme) => ({
  textMiddle: {
    verticalAlign: "middle !important",
    textAlign: "center",
  },
  tablePadding: {
    padding: "0.5rem",
    textAlign: "center",
    fontSize: "0.8rem",
  },
  paperTableHeight: {
    width: "95%",
    // marginLeft: "2rem",
  },
  "@media (max-width: 780px)": {
    paperTableHeight: {
      marginLeft: "0.75rem",
    },
  },
  "@media (max-width: 480px)": {
    paperTableHeight: {
      marginLeft: "0.75rem",
    },
  },
  tablePaginationStyle: {
    border: "1px solid #0000001a",
    borderRadius: "0rem 0rem 0.4rem 0.4rem",
    overflowY: "hidden",
  },
  tableFlex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ProfileManagement = ({ setUsers, userData }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = localStorage.getItem("token") || sessionStorage.getItem("token");
  const [profileImage, setProfileImage] = useState("");

  const [profileUpdate, setProfileUpdate] = useState({
    firstName: "",
    lastName: "",
    countryCode: "",
    phoneNumber: "",
    countryCodeName: "",
    email: "",
  });




  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`/private/getProfile`);

      setProfileUpdate(data.data);
      setPhoneData({
        countryCodeName: data.data.countryCodeName || "",
        countryCode: data.data.countryCode || ""
      });
      setProfileImage(data.data.profileImage);
      setUsers(data.data);
      localStorage.setItem("userData", JSON.stringify(data.data)) || sessionStorage.setItem("userData", JSON.stringify(data.data));
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(`${error.response.data?.error}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };


  const [phoneData, setPhoneData] = useState({
    countryCodeName: "",
    countryCode: "",
  });

  const handlePhoneChange = (value, data) => {
    const phoneNumber = value.slice(data.dialCode.length);
    setPhoneData({
      countryCodeName: data.countryCode,
      countryCode: `+${data.dialCode}`,
    });
  };

  const handleUserProfile = async (values) => {
    setIsLoading(true);
    var fromData = {
      firstName: values.firstName,
      lastName: values.lastName,
      countryCode: phoneData.countryCode,
      countryCodeName: phoneData.countryCodeName,
      phoneNumber: values.phoneNumber,
      email: values.email,
      profileImage: profileImage,
    };

    try {
      const { data } = await axios.put(`/private/editProfile`, fromData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setProfileUpdate(data.data);
      setUsers(data.data);
      localStorage.setItem("userData", JSON.stringify(data.data)) || sessionStorage.setItem("userData", JSON.stringify(data.data));
      getUserData()
      toast.success(`${data.message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      getUserData();
    } catch (error) {
      if (error.response.status == 422) {
        toast.error(error.response.data?.errors[0]?.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(`Something went wrong`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleProfileImage = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    setIsLoading(true);
    try {
      const { data } = await Axios.post(
        "https://game.hewe.club/fileUpload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setProfileImage(data?.data?.image_url);
      // toast.success(data?.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    } catch (error) {
      toast.error("Failed to update profile image", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <section className="hewe-cont">
      <DashboardWrapper>
        <div className="flex-align-center mb-0 ">
          <div className="dott me-3"></div>
          <DashHeading>My Profile</DashHeading>
        </div>

        <section className="w-100 mt-4" >
          <ProfileDetailForm>
            <Formik
              enableReinitialize
              initialValues={profileUpdate}
              validate={EditProfile}
              validateOnChange
              onSubmit={(values) => handleUserProfile(values)}
            >
              {(formikBag) => {
                return (
                  <Form className="profile-form">
                    <div className="d-flex flex-wrap align-items-center bg-white p-3">
                      <div className="profile-show me-4">
                        <label className="propfilelabel">Your profile picture</label>
                        <input
                          type="file"
                          id="avatar-upload"
                          accept=".png,.jpg,.jpeg,.img,.webp"
                          style={{ display: "none" }}
                          onChange={(e) => handleProfileImage(e)}
                        />
                        <Avatar
                          alt="profile"
                          src={profileImage ? profileImage : dummyProfile}
                          sx={{ width: 120, height: 120 }}
                        />
                      </div>
                      <div className="d-flex flex-wrap">
                        <div
                          className="savebtn me-4 my-1"
                          onClick={() =>
                            document.getElementById("avatar-upload").click()
                          }
                        >
                          Upload New
                        </div>
                        {
                          profileUpdate?.profileImage != "" ?
                            <div
                              className="savebtn my-1"
                              onClick={() => {
                                // toast.error("Profile picture has been removed.") 
                                setProfileImage("")
                              }
                              }
                            >
                              Remove Profile Picture
                            </div> : null
                        }
                      </div>

                    </div>
                    <div className="p-3">
                      <div className="bg-white row mt-5">
                        <div className="col-xxl-4 col-xl-6 col-lg-6" style={{ padding: "1rem" }}>
                          <label>First Name</label>
                          <div className="form-controlcont">
                            <FaUserAlt
                              style={{ fontSize: "18px", color: "#88AE40" }}
                            />
                            <Field name="firstName w-100">
                              {({ field }) => (
                                <input
                                  {...field}
                                  type="text"
                                  value={formikBag.values.firstName}
                                  autoComplete="off"
                                  onChange={(e) => {
                                    const value = e.target.value.replace(/\s+/g, ''); // Remove spaces
                                    const regex = /^[A-Za-z]*$/; // Only alphabets without spaces
                                    if (regex.test(value) || value === "") {
                                      formikBag.setFieldValue("firstName", value);
                                    }
                                  }}
                                  className="form-control text-black"
                                  placeholder="Enter First Name"
                                  maxlength="12"
                                />
                              )}
                            </Field>

                          </div>

                          <div className="error">{
                            formikBag.errors.firstName
                              ? formikBag.errors.firstName
                              : null
                          }</div>
                        </div>
                        <div className="col-2 d-xxl-block d-none"></div>
                        <div className="col-xxl-4 col-xl-6 col-lg-6" style={{ padding: "1rem" }}>
                          <label>Last Name</label>
                          <div className="form-controlcont">
                            <FaUserAlt
                              style={{ fontSize: "18px", color: "#88AE40" }}
                            />
                            <Field name="lastName">
                              {({ field }) => (
                                <input
                                  {...field}
                                  type="text"
                                  value={formikBag.values.lastName}
                                  autoComplete="off"
                                  aria-autocomplete="none"
                                  onChange={(e) => {
                                    const value = e.target.value.replace(/\s+/g, ''); // Remove spaces
                                    const regex = /^[A-Za-z]*$/; // Only alphabets without spaces
                                    if (regex.test(value) || value === "") {
                                      formikBag.setFieldValue("lastName", value);
                                    }
                                  }}
                                  className="form-control text-black"
                                  placeholder="Enter Last Name"
                                  maxlength="12"
                                />
                              )}
                            </Field>
                          </div>
                          <div className="error">{
                            formikBag.errors.lastName
                              ? formikBag.errors.lastName
                              : null
                          }</div>
                        </div>
                        <div className="col-xxl-4 col-xl-6 col-lg-6" style={{ padding: "1rem" }}>
                          <label>Email</label>
                          <div className="form-controlcont">
                            <MdEmail
                              style={{ fontSize: "18px", color: "#88AE40" }}
                            />
                            <Field name="email">
                              {({ field }) => (
                                <Input
                                  {...field}
                                  type="text"
                                  value={formikBag.values.email}
                                  onChange={(e) => {
                                    formikBag.setFieldValue(
                                      "email",
                                      e.target.value
                                    );
                                  }}
                                  error={
                                    formikBag.touched.email &&
                                      formikBag.errors.email
                                      ? formikBag.errors.email
                                      : null
                                  }
                                  className="form-control text-black"
                                  placeholder="Enter Your Email"
                                  readOnly={true}
                                  maxlength="30"
                                  disable={true}
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                        <div className="col-2 d-xxl-block d-none"></div>
                        <div className="col-xxl-4 col-xl-6 col-lg-6 d-flex" style={{ padding: "1rem" }}>
                          <div className="w-100">
                            <label>Phone Number</label>
                            <Field name="phoneNumber" >
                              {({ field }) => (
                                <PhoneInput
                                  country="IN"
                                  enableSearch
                                  className="phone-input"
                                  placeholder="Enter Phone Number"
                                  value={formikBag.values.countryCode + formikBag.values.phoneNumber}
                                  onChange={(phone, country) => {
                                    const dialCode = country.dialCode ? `+${country.dialCode}` : "";
                                    const phoneNumber = phone.replace(/\D/g, "").replace(country.dialCode, "");
                                    formikBag.setFieldValue("countryCode", dialCode);
                                    formikBag.setFieldValue("phoneNumber", phoneNumber);
                                    formikBag.setFieldValue("countryCodeName", country.countryCode);
                                  }}
                                />
                                // <PhoneInput
                                //   country={formikBag.values.countryCodeName || 'us'}
                                //   enableSearch={true}
                                //   className="phone-input"
                                //   value={formikBag.values.phoneNumber}
                                //   onChange={(phone, country) => {
                                //     formikBag.setFieldValue('countryCode', country.dialCode);
                                //     formikBag.setFieldValue('phoneNumber', phone);
                                //     formikBag.setFieldValue('countryCodeName', country.countryCode);
                                //   }}
                                // />
                              )}
                            </Field>
                            <p className="error">{formikBag.touched.phoneNumber &&
                              formikBag.errors.phoneNumber
                              ? formikBag.errors.phoneNumber
                              : null}</p>
                          </div>
                        </div>
                        <InputDivide>
                          <div className=" my-3 ms-1" >
                            <LoginButton
                              type="submit"
                              className="buttonWidthResponsive"
                            >
                              Update Profile
                            </LoginButton>
                          </div>
                        </InputDivide>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </ProfileDetailForm>
        </section>
      </DashboardWrapper>

      {isLoading && <Overlay />}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setDefaultState: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_DEFAULT,
        updateDefault: updatedValue,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProfileManagement));
