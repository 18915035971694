import React, { useEffect, useState } from 'react';
import axios from "../../axios";
import "./style.scss";
import { toast } from 'react-toastify';
import Overlay from '../../components/Overlay';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

function DailyBooster({ userData }) {
  const [isLoading, setIsLoading] = useState(false);
  const parsedUserData = JSON.parse(localStorage.getItem("userData")) || JSON.parse(sessionStorage.getItem("userData"));
  let modules = parsedUserData?.data?.modules;
  const permissions = modules?.find((item) => item.moduleName === "Reward Management")?.permissions;

  const [formData, setFormData] = useState({
    ball: { id: "67e12072d5241cb1a24a193e", value: "", rewards: 0 },
    arrow: { id: "67e1209ad5241cb1a24a193f", value: "", rewards: 0 },
    spin: { id: "67e120bdd5241cb1a24a1940", value: "" }
  });

  const fetchRewardValue = async (key) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`/findRewardValueById/${formData[key].id}`);
      const { value, rewards } = res.data.data[0];
      setFormData((prev) => ({
        ...prev,
        [key]: { ...prev[key], value, rewards },
      }));
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const updateRewardValue = async () => {
    try {
      setIsLoading(true);

      const payload = {
        totalAttempt1: Number(formData.ball.value),
        heweCoins1: Number(formData.ball.rewards),
        totalAttempt2: Number(formData.arrow.value),
        heweCoins2: Number(formData.arrow.rewards),
        totalAttempt3: Number(formData.spin.value),
      };
      if (Object.values(payload).some(value => value <= 0 || isNaN(value))) {
        toast.error("All values must be greater than zero.");
        setIsLoading(false);
        return;
      }
      await axios.post("/private/setRewardType", payload);
      toast.success("Daily Booster updated successfully.");

      // Fetch updated values after submission
      await Promise.all([
        fetchRewardValue("ball"),
        fetchRewardValue("arrow"),
        fetchRewardValue("spin"),
      ]);
    } catch (error) {
      console.error(error);
      toast.error("Failed to update Daily Booster");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRewardValue("ball");
    fetchRewardValue("arrow");
    fetchRewardValue("spin");
  }, []);

  return (
    <>
      <div className='hewe-cont'>
        <div className='referralcont'>
          <div className='flex-align-center'>
            <div className="dott me-3"></div>
            <p className='main-heading'>Daily Booster</p>
          </div>
          <div className=''>
            <div className='desc my-4'>
              <p>Set daily limits for Arrow Booster, Ball Booster, and Spin the Wheel. Customize the number of attempts to enhance user participation and retention.</p>
            </div>
            <div className='notes my-4'>
              <p><strong>Note: </strong>Any updates will take effect from {(() => {
                const today = new Date();
                today.setDate(today.getDate() + 1);
                return today.toLocaleDateString('en-GB');
              })()}, 12:00 AM midnight.</p>
            </div>
            <div className="refcont">
              <div className="row">
                <div className="col-xxl-3  col-4 text-center">
                  <h3 >Type</h3>
                </div>
                <div className='col-xxl-1 d-xxl-block d-none'></div>
                <div className="col-xxl-3 col-4 text-center">
                  <h3>Total Attempt (per day)</h3>
                </div>
                <div className='col-xxl-1 d-xxl-block d-none'></div>
                <div className="col-xxl-3 col-4 text-center">
                  <h3>HeXP (per attempt)</h3>
                </div>
              </div>

              {["ball", "arrow", "spin"].map((key) => (
                <div className="row  mt-4" key={key}>
                  <div className="col-xxl-3 col-4 text-center">
                    <div className="card-type pagerespo w-100" >{key.charAt(0).toUpperCase() + key.slice(1)} Booster</div>
                  </div>
                  <div className='col-xxl-1 d-xxl-block d-none'></div>
                  <div className="col-xxl-3 col-4 text-center">
                    <input
                      className='pagerespo'
                      type="number"
                      min={1}
                      placeholder="Enter attempts"
                      value={formData[key].value}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          [key]: { ...prev[key], value: e.target.value },
                        }))
                      }
                    />
                  </div>
                  <div className='col-xxl-1 d-xxl-block d-none'></div>
                  {key !== "spin" && (
                    <div className="col-xxl-3 col-4 text-center">
                      <input
                        type="number"
                        min="1"
                        className='pagerespo'
                        placeholder="Enter Hewe Coins"
                        value={formData[key].rewards}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            [key]: { ...prev[key], rewards: e.target.value },
                          }))
                        }
                      />
                    </div>
                  )}
                </div>
              ))}

              <div className="row mt-4 ">
                <div className="col-xxl-11  text-center">
                  {(userData.userType === "admin" || (userData.userType === "subAdmin" && permissions?.edit)) && (
                    <button onClick={updateRewardValue} >Update</button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Overlay />}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
    sidebar: state.sidebar,
  };
};

export default connect(mapStateToProps)(withRouter(DailyBooster));
