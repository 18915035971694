import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import "./style.scss"
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "../../axios";
import { get } from "lodash";
import { CycleIcon, Filter, FootIcon } from '../../components/svg';
import { FaAngleDown } from 'react-icons/fa';
import "react-datepicker/dist/react-datepicker.css";
import { IoChevronBack } from 'react-icons/io5';
import { FiArrowLeft } from "react-icons/fi";
import { FaArrowRight } from "react-icons/fa";
import { PaginationItem } from "@material-ui/lab";
import { Pagination, Stack } from "@mui/material";
import Overlay from '../Overlay';
const useStyles = makeStyles((theme) => ({
    paperTableHeight: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        border: "1px solid #D9E3D9",
        overflowX: "scroll",
    },
    tableMainContainer: {
        overflowX: "scroll",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
    },
    tableHeadingRow: {
        // backgroundColor: "#21AFE6",
    },
    tablseHeadingCell: {
        padding: "0.8rem 20px !important",
        textAlign: "center",
        fontSize: "14px",
        color: "#264503",
        fontWeight: 600,
        borderBottom: "1px solid #D9E3D9",
        whiteSpace: "nowrap",
    },
    textMiddle: {
        padding: "0.6rem 20px !important",
        verticalAlign: "middle !important",
        textAlign: "center",
        fontSize: "14px",
        color: "black",
    },
    tablePaginationStyle: {
        borderTop: "1px solid #0000001a",
        borderRadius: "0rem 0rem 0.4rem 0.4rem",
        overflowY: "hidden",
    },
    sticky: {
        position: "sticky",
        left: "0"
    }
}));
function RedFlag() {
    const param = useParams();
    const classes = useStyles();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, SetTableData] = useState([]);
    // For Pagination
    const [page, setPage] = useState(1);
    // For Search
    const [limit, setLimit] = useState(10);
    const [paginationData, setPaginationData] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        window.scrollTo(0, 0);
    };

    const [filterType, setFilterType] = useState("")
    const toggleDropdown = () => {
        setIsDropdownOpen((prevState) => !prevState);
    };

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const cancelFilter = () => {
        setIsDropdownOpen(false);
        setFilterType("");
        getData();
    };

    const handleFilterChange = (value) => {
        setFilterType((prev) => (prev === value ? "" : value));
    };

    const getData = async () => {
        try {
            setIsLoading(true)
            const body = {
                type: filterType
            }
            const { data } = await axios.post(
                `/private/redFlagHistory/${param.id}?page=${page}&limit=${limit}`, body
            );
            setPaginationData(data?.data);
            SetTableData(data?.data?.docs);
            setIsDropdownOpen(false);
            setIsLoading(false)

        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }

    useEffect(() => {
        getData();
    }, [page, filterType]);


    const calculateDuration = (start, end) => {
        const startTime = new Date(start);
        const endTime = new Date(end);
        const diffMs = endTime - startTime;

        const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
        const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
        const diffSeconds = Math.floor((diffMs % (1000 * 60)) / 1000);

        return `${diffHours}h ${diffMinutes}min ${diffSeconds}s`;
    };

    // out side click handle
    const dropdownRef = useRef(null);
    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (
        <div className='hewe-cont'>

            <div className="flex-align-center justify-content-between mb-4 w-100">
                <div className="flex-align-center ">
                    <IoChevronBack
                        size={32}
                        className="back-color"
                        onClick={() =>
                            history.goBack()
                        }
                    />
                    <h3 className='font-dark-green mb-0 fs-22 main-heading' >Red Flag history</h3>
                </div>
                <div className='d-flex align-items-center'>
                    <div className="custom-dropdown-container" ref={dropdownRef}>
                        <button className='filtertbn' onClick={toggleDropdown} >
                            <div className='d-flex align-items-center'><Filter /> <p className="ms-1 font-italic fw-400" style={{ color: "#464646c1" }}>Filter</p></div>   <span><FaAngleDown color='gray' /></span>
                        </button>
                        {isDropdownOpen && (
                            <div className="custom-dropdown">
                                <div>
                                    <h6 className='py-2 fw-700'>Purchase Type</h6>
                                </div>

                                <div className='flex-align-center'>
                                    <input
                                        type="checkbox"
                                        className="me-3"
                                        checked={filterType === "2"}
                                        onChange={() => handleFilterChange("2")}
                                    /><CycleIcon />
                                </div>
                                <div className='flex-align-center my-3'>
                                    <input
                                        type="checkbox"
                                        className="me-3"
                                        checked={filterType === "1"}
                                        onChange={() => handleFilterChange("1")}
                                    /><FootIcon />
                                </div>
                                <div className="dropdown-actions d-flex mt-2">
                                    <button className='cancle  me-3' onClick={cancelFilter}>Reset</button>
                                    {/* <button className='apply bg-green' onClick={getData}>Apply</button> */}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div>
                {tableData?.length > 0 ? (<>
                    <Paper className={classes.paperTableHeight}>
                        <>
                            <TableContainer
                                className={classes.tableMainContainer}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow className={classes.tableHeadingRow}>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                S.No
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Date
                                            </TableCell>
                                            <TableCell className={[classes.tablseHeadingCell]}>
                                                Start Time
                                            </TableCell>
                                            <TableCell className={[classes.tablseHeadingCell]}>
                                                Source
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Total Distance
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Attempt Used
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                HeXP Earned
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Duration
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                HeXP Earned (Per Mile)
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Location Start
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Location End
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                City
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                End Time
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableData?.length > 0 ? (<>

                                            {tableData.map((category, index) => (
                                                <TableRow key={category._id}>
                                                    <TableCell className={classes.textMiddle}>
                                                        <div>{index + 1 + rowsPerPage * (page - 1)}</div>
                                                    </TableCell>

                                                    <TableCell className={classes.textMiddle}>
                                                        <div>{get(category, 'createdAt', 'N/A') ? new Date(get(category, 'createdAt')).toLocaleDateString("en-GB") : 'N/A'}</div>
                                                    </TableCell>
                                                    <TableCell className={classes.textMiddle}>
                                                        <div>
                                                            {get(category, 'createdAt', 'N/A') !== 'N/A'
                                                                ? new Date(get(category, 'startTime')).toLocaleString("en-GB", {
                                                                    hour: '2-digit',
                                                                    minute: '2-digit',
                                                                    hour12: true
                                                                })
                                                                : 'N/A'}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={classes.textMiddle}>
                                                        <div >
                                                            {get(category, "source", 0) == "Walking" ? <FootIcon /> : <CycleIcon />}
                                                        </div>
                                                    </TableCell>

                                                    <TableCell className={classes.textMiddle}>
                                                        <div >
                                                            {get(category, "distance", 0).toFixed(2)}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={classes.textMiddle}>
                                                        <div className="flex-align-center">
                                                            <div className="flex-align-center mx-2 bg88AE4038 py-2 px-3">Arrow <span className="ps-2">{get(category, "usedArrowBooster", 0)}</span> </div>
                                                            <div className="flex-align-center mx-2 bg5B009738 py-2 px-3">Ball <span className="ps-2">{get(category, "usedBallBooster", 0)} </span></div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={classes.textMiddle}>
                                                        <div className="flex-align-center">
                                                            <div className="flex-align-center mx-2  bg88AE4038 py-2 px-3">Arrow <span className="ps-2">{get(category, "coinCollectedUsingArrow", 0)}</span> </div>
                                                            <div className="flex-align-center mx-2 bg5B009738 py-2 px-3">Ball <span className="ps-2">{get(category, "coinCollectedUsingBall", 0)} </span></div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={classes.textMiddle}>
                                                        <div>
                                                            {get(category, 'createdAt', 'N/A') !== 'N/A' && (
                                                                <div className="nowrap">{calculateDuration(get(category, 'startTime'), get(category, 'endTime'))}</div>
                                                            )}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={classes.textMiddle}>
                                                        <div >
                                                            {get(category, "coins", 0)}
                                                        </div>
                                                    </TableCell>

                                                    <TableCell className={classes.textMiddle}>
                                                        <div >
                                                            {get(category, "startLocation.Latitude", 0)?.toFixed(6)}-{get(category, "startLocation.Longitude", 0)?.toFixed(6)}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={classes.textMiddle}>
                                                        <div >
                                                            {get(category, "endLocation.Latitude", 0)?.toFixed(6)}-{get(category, "endLocation.Longitude", 0)?.toFixed(6)}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={classes.textMiddle}>
                                                        <div >
                                                            {get(category, "city", 0)}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={classes.textMiddle}>
                                                        <div>
                                                            {get(category, 'createdAt', 'N/A') !== 'N/A'
                                                                ? new Date(get(category, 'endTime')).toLocaleString("en-GB", {
                                                                    hour: '2-digit',
                                                                    minute: '2-digit',
                                                                    hour12: true
                                                                })
                                                                : 'N/A'}
                                                        </div>

                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </>) : (
                                            <TableRow>
                                                <TableCell colSpan={12} rowSpan={10} align="center">
                                                    No Data Found
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Stack spacing={2} className="flex-align-center justify-content-center w-100">
                                <Pagination
                                    count={Math.ceil(paginationData?.totalDocs / rowsPerPage)}
                                    page={page}
                                    onChange={(event, value) => handleChangePage(event, value)}
                                    renderItem={(item) => (
                                        <PaginationItem
                                            slots={{ previous: FiArrowLeft, next: FaArrowRight }}
                                            {...item}
                                        />
                                    )}
                                />
                            </Stack>
                        </>
                    </Paper>
                </>) : (
                    <div className="emptyTable mt-3">
                        No Data Found
                    </div>
                )}
            </div>
            {isLoading && <Overlay />}

        </div>
    )
}

export default RedFlag