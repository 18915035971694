import React, { useEffect, useState } from 'react'
import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import "./style.scss"
import * as actionTypes from "../../store/actions";
import img1 from "../../images/hewe/dashboard/dash1.svg"
import img2 from "../../images/hewe/dashboard/dash2.svg"
import img3 from "../../images/hewe/dashboard/dash3.svg"
import img4 from "../../images/hewe/dashboard/dash4.svg"
import img5 from "../../images/hewe/dashboard/dash5.png"
import heweCoin from '../../images/hewe/icon.png'
import levelimg from "../../images/hewe/dashboard/leveldas.svg"
import { CycleIcon, FootIcon } from '../../components/svg';
import { Bar, Line } from "react-chartjs-2";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { FiArrowLeft } from "react-icons/fi";
import { FaArrowRight } from "react-icons/fa";
import { Stack } from "@mui/material";
import Select from "react-select";
import axios from "../../axios"
import Overlay from '../../components/Overlay';
import {
  Chart as ChartJS,
  BarElement,
  LineElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  PointElement,
} from "chart.js";
ChartJS.register(
  BarElement,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);
const Dashboard = () => {
  const history = useHistory()
  const [dasboardData, setDashboard] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [paginationData, setPaginationData] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    window.scrollTo(0, 0);
  };

  const [selectedOption, setSelectedOption] = useState({ value: "1", label: "This Week" });

  const selectoption = [
    { value: "1", label: "This Week" },
    { value: "2", label: "This Month" },
    { value: "3", label: "This Year" },
    { value: "4", label: "All" },
  ];

  const [earningType, setEarningType] = useState(false)
  const handleapi = async () => {
    try {
      setIsLoading(true)
      const { data } = await axios.get(`/dashboard?filter=${selectedOption.value}&page=${page}&limit=${limit}`)
      setTableData(data?.data?.rank?.docs)
      setPaginationData(data?.data?.rank)
      setDashboard(data?.data)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    handleapi()
  }, [page, selectedOption])

  const dataRealMoney = {
    labels: dasboardData?.graph1?.labels,
    datasets: [
      {
        label: "Earning",
        data: dasboardData?.graph1?.data,
        fill: false,
        borderColor: '#5EAB07',
        backgroundColor: '#5EAB07',
        borderWidth: 2,
        tension: 0.4,
        pointRadius: 4,
        pointBackgroundColor: '#5EAB07',
      },
    ],
  };


  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 16,
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          font: {
            size: 16,
          },
        },
        title: {
          display: true,
          text: 'Revenue in $',
          font: {
            size: 20,
            weight: 'bold',
          },
          color: '#5EAB07',
        },
      },
    },
  };

  // ------------campared line chart
  const data1 = {
    labels: dasboardData?.linegraph1?.labels,
    datasets: [
      {
        label: "Cycling",
        data: dasboardData?.linegraph2?.data, // Cycling data points
        borderColor: "green",
        backgroundColor: "rgba(0, 128, 0, 0.2)",
        pointBackgroundColor: "green",
        borderWidth: 2,
        tension: 0.4,
      },
      {
        label: "Walking",
        data: dasboardData?.linegraph1?.data,
        borderColor: "lightgreen",
        backgroundColor: "rgba(144, 238, 144, 0.2)",
        pointBackgroundColor: "lightgreen",
        borderWidth: 2,
        tension: 0.4,
      },
    ],
  };

  const options1 = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "top",
        labels: {
          font: {
            size: 14,
            weight: "bold",
          },
          color: "green",
        },
      },
      tooltip: { enabled: true },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 16,
          },
        },
      },
      y: {
        beginAtZero: true,
        min: 0,
        ticks: {
          font: {
            size: 16,
          },
        },
        title: {
          display: true,
          text: "Miles",
          ticks: {
            stepSize: 2,
          },
          font: {
            size: 20,
            weight: "bold",
          },
          color: "#5EAB07",
        },
      },

    },
  };

  return (
    <section className='hewe-cont dashboard'>
      <div className='flex-align-center justify-content-between'>
        <h3 className='headings'>Dashboard</h3>
        <Select
          options={selectoption}
          value={selectedOption}
          onChange={setSelectedOption}
          className="w-120 bg-g1"
          isSearchable={false}
          classNamePrefix="react-select"
          styles={{
            control: (provided) => ({
              ...provided,
              backgroundColor: "inherit",
              borderColor: "transparent",
              boxShadow: "none",
              color: "white",
              "&:hover": { borderColor: "transparent" },
            }),
            singleValue: (provided) => ({
              ...provided,
              color: "white",
            }),
            menu: (provided) => ({
              ...provided,
              backgroundColor: "#333",
            }),
            option: (provided, state) => ({
              ...provided,
              // width:"200px",
              backgroundColor: state.isSelected ? "#fff" : "#fff",
              color: "black",
              "&:hover": { backgroundColor: "#88ae40" },
            }),
          }}
        />

      </div>


      <div className='firstcat'>
        <div className='row  flex-wrap align-items-stretch'>
          <div className='firstcatCont p-2'>
            <div className='cnt1'>
              <div>
                <img src={img3} alt="img" className='' />
              </div>
              <div className='ms-3'>
                <h4>Total Users(till now)</h4>
                <h3>{dasboardData?.totalUser}</h3>
              </div>
            </div>
          </div>
          <div className='firstcatCont p-2'>
            <div className='cnt1'>
              <div>
                <img src={img5} alt="img" className='' />
              </div>
              <div className='ms-3'>
                <h4>Users By Referral</h4>
                <h3>{dasboardData?.totalUserByReferral}</h3>
              </div>

            </div>
          </div>
          <div className='firstcatCont p-2'>
            <div className='cnt1'>
              <div>
                <img src={img2} alt="img" className='' />
              </div>
              <div className='ms-3'>
                <h4>Active Users</h4>
                <h3>{dasboardData?.activeUser}</h3>
              </div>

            </div>
          </div>
          <div className='firstcatCont p-2'>
            <div className='cnt1'>
              <div>
                <img src={img1} alt="img" className='' />
              </div>
              <div className='ms-3'>
                <h4>New Users</h4>
                <h3>{dasboardData?.newUser}</h3>
              </div>
            </div>
          </div>
          <div className='firstcatCont p-2'>
            <div className='cnt1'>
              <div>
                <img src={img4} alt="img" className='' />
              </div>
              <div className='ms-3'>
                <h4>Total Revenue</h4>
                <div className='flex-align-center'>
                  <h3 className=''>${(dasboardData?.totalRevenue?.realMoney)?.toFixed(2)}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>


      <div className='secondcat mt-3'>
        <div className="row">
          <div className='col-xxl-7 col-xl-7 pergraph'>
            <div className="graph1">
              <div className='d-flex align-items-center mb-3'>
                <h3 className='heading ms-1 me-3 mb-0'>Revenue</h3>
                <div class="form-check form-switch">

                </div>
              </div>
              <div >
                <Line data={dataRealMoney} options={options} width={600} height={340} />
              </div>
            </div>
            <div className="stat">
              <h3 className="heading mb-3 ">Activity Stats (in miles)</h3>
              <div className="row">
                <div className="col-lg-6">
                  <div className="cont">
                    <h3>
                      <CycleIcon />&nbsp;Total Cycling
                      <span className='ps-3 cycle'>
                        {new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(Number(dasboardData?.activityStat?.cycling?.toFixed(2)))}
                      </span>
                    </h3>
                  </div>
                </div>
                <div className="col-lg-6 mt-lg-0 mt-4">
                  <div className="cont">
                    <h3>
                      <FootIcon />&nbsp;Total Walking <span className='ps-3 walk'>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(Number(dasboardData?.activityStat?.walking?.toFixed(2)))}
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xxl-5 col-xl-5 col-lg-7 col-md-10 col-sm-8 mt-xl-0 mt-4 '>
            <div className='performance'>
              <h3 className="heading py-2">Top Performers</h3>
              {
                tableData?.length == 0 ? <h3 className=' text-white d-flex align-items-center justify-content-center h-75'>No Data Found</h3> :
                  tableData?.map((item, index) => {
                    return (
                      <>
                        <div className="entry">
                          <div className='flex-align-center justify-content-between'>
                            <div className='flex-align-center'>
                              {/* <div className='position-relative'>
                                <img src={levelimg} alt="" className='levelImg' />
                                <p className='levels'>{item.CurrentLevel}</p>
                              </div> */}
                              <div className=''>
                                <h4>Rank:{item.CurrentRank}</h4>
                                <h5>{item.profileName}</h5>
                              </div>
                            </div>
                            <div>
                              <p className='underline pointer text-white' onClick={() => history.push(`/accountManagement/view-profile/${item?._id}`)}>View profile</p>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  })
              }
              {
                tableData?.length != 0 &&
                <Stack spacing={2} className="flex-align-center justify-content-center w-100 py-3 ">
                  <Pagination
                    count={Math.ceil(paginationData?.totalDocs / rowsPerPage)}
                    page={page}
                    onChange={(event, value) => handleChangePage(event, value)}
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{ previous: FiArrowLeft, next: FaArrowRight }}
                        {...item}
                      />
                    )}
                  />
                </Stack>
              }
            </div>
          </div>
        </div>
      </div>

      <div className='thirdcat mt-3'>
        <div className="row">
          <div className="col-xxl-7 col-xl-7">
            <div className='cw-average'>
              <div className='flex-align-center justify-content-between mb-3'>
                <h3 className='heading'>Cycling & Walking Activity</h3>
                <div className='flex-align-center'>
                  <div className='cycling'></div>
                  <p>Cycling</p>

                  <div className='walking ms-4'></div>
                  <p>Walking</p>
                </div>
              </div>
              <div style={{ width: "100%", height: "280px" }}>
                <Line data={data1} options={options1} />
              </div>
            </div>
          </div>
          <div className="col-xxl-5 col-xl-5 ">
            <div className='p-3 distribute'>
              <h3 className='heading'>Reward  Distributed to Users</h3>
              <div className="table-container">
                <table>
                  <tr>
                    <th>S.No</th>
                    <th>Rewards</th>
                    <th>Daily Rewards</th>
                    <th>Rewarded</th>
                    <th>Consumed</th>
                  </tr>
                  {dasboardData?.dailyreward[0] &&
                    Object.entries(dasboardData?.dailyreward[0]).map(([key, value], index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="fw-600" style={{ textTransform: "capitalize" }}>{key}</td>
                        <td>{value.dailyBooster}</td>
                        <td>{value.rewarded}</td>
                        <td>{value.consumed}</td>
                      </tr>
                    ))}
                </table>
              </div>

            </div>
          </div>

        </div>
      </div>
      {isLoading && <Overlay />}

    </section>
  )
}
const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
    sidebar: state.sidebar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setDefaultState: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_DEFAULT,
        updateDefault: updatedValue,
      });
    },
    setSidebar: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_SIDEBAR,
        updateSidebar: updatedValue,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Dashboard));
