import React, { useEffect, useState } from 'react'
import axios from "../../axios";
import "./style.scss"
import { toast } from 'react-toastify';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { FiArrowLeft } from "react-icons/fi";
import { FaAngleDown, FaArrowRight } from "react-icons/fa";
import { Stack } from "@mui/material";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import moment from 'moment';
import Overlay from '../../components/Overlay';
const useStyles = makeStyles((theme) => ({
  paperTableHeight: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    border: "1px solid #D9E3D9",
    overflowX: "scroll",
  },
  tableMainContainer: {
    overflowX: "scroll",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  tableHeadingRow: {
    // backgroundColor: "#21AFE6",
  },
  tablseHeadingCell: {
    // position: "sticky",
    top: 0,
    zIndex: 99,
    padding: "0.9rem 20px !important",
    textAlign: "center",
    fontSize: "14px",
    color: "#264503",
    fontWeight: 600,
    backgroundColor: "white",
    borderBottom: "1px solid #D9E3D9",
    whiteSpace: "nowrap",
  },
  textMiddle: {
    padding: "0.3rem 20px !important",
    verticalAlign: "middle !important",
    textAlign: "center",
    fontSize: "14px",
    color: "black",
  },
  tablePaginationStyle: {
    borderTop: "1px solid #0000001a",
    borderRadius: "0rem 0rem 0.4rem 0.4rem",
    overflowY: "hidden",
  },
  sticky: {
    position: "sticky",
    left: "0"
  }
}));



function Rate({ userData }) {
  const parsedUserData = JSON.parse(localStorage.getItem("userData")) || JSON.parse(sessionStorage.getItem("userData"));
  let modules = parsedUserData?.data?.modules;
  const permissions = modules?.find((item) => item.moduleName === "Reward Management")?.permissions;
  const [formData, setFormData] = useState({
    rate: { id: "67d912a47789d47214bafc98", value: "" },
  });
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [paginationData, setPaginationData] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const fetchRewardValue = async (key) => {
    try {
      const res = await axios.get(`/findRewardValueById/${formData[key].id}`);
      setFormData((prev) => ({
        ...prev,
        [key]: { ...prev[key], value: res.data.data[0].value },
      }));
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
    }
  };

  const updateRewardValue = async (key) => {
    try {
      if (!formData[key] || Number(formData[key]?.value) <= 0) {
        toast.error("Please enter a valid value greater than 0.");
        return;
      }
      const res = await axios.post("/private/updateRewardValue ", formData[key]);
      toast.success("Conversion rate updated successfully.");
      fetchRewardValue(key);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchRewardValue("rate");
  }, []);

  const handleRespose = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`/private/getConvertRates?page=${page}&limit=${limit}`);
      setTableData(data?.data?.docs);
      setPaginationData(data?.data)
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.loog(error);
    }
  };
  useEffect(() => {
    handleRespose();
  }, [page]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    window.scrollTo(0, 0);
  };


  return (
    <>
      <div className='hewe-cont'>
        <div className='referralcont'>
          <div className='flex-align-center'>
            <div className="dott me-3"></div>
            <p className='main-heading'>Conversion Rate</p>
          </div>
          <div className=''>
            <div className='desc my-4'>
              <p>Configure the exchange value between Hewe Coins and HeXP to match your system preferences.</p>
            </div>
            <div className="refcont">
              <h2>Rate</h2>
              <div className='flex-align-center flex-wrap mt-3'>
                <h4 className='mb-0 perrefer'>1 Hewe Coin = </h4>
                <div className='ms-3'>
                  <label htmlFor="Total Hewe coins">Total HeXP</label>
                  <div>
                    <input
                      type="number"
                      className='me-5 '
                      min="1"
                      placeholder="Enter hewe coins"
                      value={formData.rate.value}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          rate: { ...prev.rate, value: e.target.value },
                        }))
                      }
                    />
                  </div>
                </div>
                {(userData.userType === "admin" || userData.userType == "subAdmin" && permissions?.edit == true) &&
                  <button className='mt-4' onClick={() => updateRewardValue("rate")}>Update</button>
                }
              </div>
            </div>

            <Paper
              className="mt-3 ratesTable"
              style={{
                overflowY: "scroll",
              }}
            >
              <>
                <TableContainer className={classes.tableMainContainer} style={{ minHeight: "40vh" }}>
                  <Table>
                    <TableHead>
                      <TableRow className={classes.tableHeadingRow}>
                        <TableCell className={classes.tablseHeadingCell}>
                          S.No
                        </TableCell>
                        <TableCell className={[classes.tablseHeadingCell]}>
                          Date and Time
                        </TableCell>
                        <TableCell className={[classes.tablseHeadingCell]}>
                          Conversion Rate (1 HEWE)
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData?.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell className={classes.textMiddle}>
                            <div>{index + 1 + rowsPerPage * (page - 1)}</div>
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            {item?.createdAt ? moment(item.createdAt).format("lll") : "-"}
                          </TableCell>
                          <TableCell className={classes.textMiddle}>
                            {item?.convertionRate}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Stack
                  spacing={2}
                  className="flex-align-center justify-content-center w-100 py-3"
                >
                  <Pagination
                    count={Math.ceil(paginationData?.totalDocs / rowsPerPage)}
                    page={page}
                    onChange={(event, value) => handleChangePage(event, value)}
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{ previous: FiArrowLeft, next: FaArrowRight }}
                        {...item}
                      />
                    )}
                  />
                </Stack>
              </>
            </Paper>
          </div>
        </div>
      </div>
      {isLoading && <Overlay />}

    </>
  )
}



const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
    sidebar: state.sidebar,
  };
};

export default connect(
  mapStateToProps,
  // mapDispatchToProps
)(withRouter(Rate));