import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';

const ITEM_HEIGHT = 400;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export default function DropDown({ values, field, form, option, placeholder = '' }) {
  const theme = useTheme();

  const handleChange = (event) => {
    const { value } = event.target;
    form.setFieldValue(field.name, value);
  };

  return (
    <FormControl sx={{ m: 0.8, width: "100%" }}>
      <Select
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        value={field.value || []}
        onChange={handleChange}
        label="Reward Type"
        displayEmpty
        input={<OutlinedInput />}
        MenuProps={MenuProps}
      >
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
        {option?.map((item) => (
          <MenuItem
            key={item.id}
            value={item.id}
          >
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
