import React, { useState, useEffect } from "react";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "../../axios";
import Overlay from "../../components/Overlay";
import { get } from "lodash";
import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import "react-datepicker/dist/react-datepicker.css";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { FaApple } from "react-icons/fa";
import { TiInfoOutline, TiWorld } from "react-icons/ti";
import { SlClose } from "react-icons/sl";
import { Modal } from "../../components/Modal";
import { TbCircleDotFilled } from "react-icons/tb";
import { BsAndroid2} from "react-icons/bs";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  paperTableHeight: {
    width: "100%",
    display: "flex",
    minHeight: "50vh",
    justifyContent: "space-between",
    flexDirection: "column",
    border: "1px solid #D9E3D9",
    overflowX: "scroll",
  },
  tableMainContainer: {
    overflowX: "scroll",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  tableHeadingRow: {
    // backgroundColor: "#21AFE6",
  },
  tablseHeadingCell: {
    padding: "0.8rem 20px !important",
    textAlign: "center",
    fontSize: "14px",
    color: "#264503",
    fontWeight: 600,
    borderBottom: "1px solid #D9E3D9",
    whiteSpace: "nowrap",
  },
  textMiddle: {
    padding: "0.6rem 20px !important",
    verticalAlign: "middle !important",
    textAlign: "center",
    fontSize: "14px",
    color: "black",
  },
  tablePaginationStyle: {
    borderTop: "1px solid #0000001a",
    borderRadius: "0rem 0rem 0.4rem 0.4rem",
    overflowY: "hidden",
  },
  sticky: {
    position: "sticky",
    left: "0"
  }
}));

const LoginDevice = (id) => {

  const param =useParams()

  const state=useLocation()
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const userToken = localStorage.getItem("token") || sessionStorage.getItem("token");
  const userDeviceId = localStorage.getItem("deviceId") || sessionStorage.getItem("deviceId");
  const [tableData, SetTableData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [userId, setUserId] = useState("");

  // For Pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);



  useEffect(() => {
    getDeviceData();
  }, []);

  const reversedData = [...tableData].reverse();

  const getDeviceData = async () => {
    setIsLoading(true);

    try {
      const { data } = await axios.get(`/private/userAccountsLogin/${param.id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      SetTableData(data?.data);
    } catch (error) {
      if (error?.response.status === 401) {
        toast.success(`${error.response.data?.errors[0].msg}`, {
          position: toast.POSITION.TOP_RIGHT,
        });

        history.push("/");
      } else {
        toast.error(`Something went wrong`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignout = async () => {
    setIsLoading(true);
    setOpenModal(false);

    try {
      const { data } = await axios.delete(`/private/signout/${userId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      setIsLoading(false);
      getDeviceData();
      toast.success(data.message);

      if (userId == userDeviceId) {
        const itemsToRemove = ["token", "userData", "email", "deviceId"];

        itemsToRemove.forEach((item) => {
          localStorage.removeItem(item);
          sessionStorage.removeItem(item);
        });
        Cookies.remove("showProfileSidebar");
        history.push("/");
      }
    } catch (error) {
      console.error("Error signing out:", error.response);
      setIsLoading(false);
    }
  };

  return (
    <section className="hewe-cont">
        <div className="flex-align-center mb-4">
                  <IoChevronBack
                    size={32}
                    className="back-color"
                    onClick={() =>
                      history.goBack({
                        state: { page: state?.state?.page },
                      })
                    }
                  />
                  <h5 className="back-color mb-0 ms-2 text-capitalize">{state?.state?.state}'s Device History</h5>
                </div>
      {tableData?.length > 0 ? (<>
        <Paper className={classes.paperTableHeight}>
          <>
            <TableContainer className={classes.tableMainContainer} >
              <Table>
                <TableHead>
                  <TableRow className={classes.tableHeadingRow}>
                    <TableCell className={classes.tablseHeadingCell}>
                      S.No
                    </TableCell>
                    <TableCell className={classes.tablseHeadingCell}>
                      First Sign In
                    </TableCell>
                    <TableCell className={classes.tablseHeadingCell}>
                      Device
                    </TableCell>
                    <TableCell className={classes.tablseHeadingCell}>
                      Device Id
                    </TableCell>
                    <TableCell className={classes.tablseHeadingCell}>
                      Device Name
                    </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {reversedData.map((category, index) => (
                    <TableRow key={category._id}>
                      <TableCell className={classes.textMiddle}>
                        {index + 1 + page * rowsPerPage}
                      </TableCell>
                      <TableCell className={classes.textMiddle}>
                       {moment(category?.createdAt).format("LLL")}
                      </TableCell>
                      <TableCell className={classes.textMiddle}>
                        {get(category, "deviceType", "N/A") === "3" ? (
                          <FaApple size={30} />
                        ) : get(category, "deviceType", "N/A") === "2" ? (
                          <BsAndroid2 color="#6fbe5a" size={30} />
                        ) : null}
                      </TableCell>
                      <TableCell className={classes.textMiddle}>
                        {userDeviceId === category?.deviceId ? (
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "4px" }}>
                            <TbCircleDotFilled style={{ color: "green" }} />
                            <span>{category?.deviceId}</span>
                          </div>
                        ) : (
                          <>{category?.deviceId}</>
                        )}
                      </TableCell>
                      <TableCell className={classes.textMiddle}>
                        <div>
                          {get(category, "deviceName", "N/A").split(" - ")[0]}
                        </div>
                      </TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          </>
        </Paper>
      </>) : (
        <div className="emptyTable mt-3">
          No Data Found
        </div>
      )}

      {/* Modal for logout  */}
      <Modal
        maxWidth="lg"
        width="360px"
        RoundedCorners={true}
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        backgroundModal={false}
        backgroundModalContent={false}
        title={
          <div className="">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <TiInfoOutline style={{ color: "red" }} />
                <p className="ps-2" style={{ color: "red" }}>Sign Out?</p>
              </div>
              <SlClose
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenModal(false);
                }}
              />
            </div>
            <div className="logout-header">Are you sure want to Sign Out from this device ?</div>
          </div>
        }
        content={
          <>
            {/* <span className="are-you-sure">Are You Sure?</span> */}
            <div className="logout-button-group">
              <div className="logout-button" onClick={() => handleSignout()}>
                Yes
              </div>
              <div
                className="cancel-button"
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                No
              </div>
            </div>
          </>
        }
      />

      {isLoading && <Overlay />}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
    sidebar: state.sidebar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setDefaultState: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_DEFAULT,
        updateDefault: updatedValue,
      });
    },
    setSidebar: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_SIDEBAR,
        updateSidebar: updatedValue,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginDevice));
