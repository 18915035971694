import React, { useState, useEffect } from "react";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "../../axios";
import Overlay from "../../components/Overlay";
import { get} from "lodash";
import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { DashHeading } from "../AccountManagement/AccountManagementElements";
import { IoChevronBack } from "react-icons/io5";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { FiArrowLeft } from "react-icons/fi";
import { FaArrowRight } from "react-icons/fa";
import { Stack } from "@mui/material";
import { CycleIcon, FootIcon } from "../../components/svg";

const useStyles = makeStyles((theme) => ({
    paperTableHeight: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        border: "1px solid #D9E3D9",
        overflowX: "scroll",
    },
    tableMainContainer: {
        overflowX: "scroll",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
    },
    tableHeadingRow: {
        // backgroundColor: "#21AFE6",
    },
    tablseHeadingCell: {
        padding: "0.8rem 20px !important",
        textAlign: "center",
        fontSize: "14px",
        color: "#264503",
        fontWeight: 600,
        borderBottom: "1px solid #D9E3D9",
        whiteSpace: "nowrap",
    },
    textMiddle: {
        padding: "0.6rem 20px !important",
        verticalAlign: "middle !important",
        textAlign: "center",
        fontSize: "14px",
        color: "black",
    },
    tablePaginationStyle: {
        borderTop: "1px solid #0000001a",
        borderRadius: "0rem 0rem 0.4rem 0.4rem",
        overflowY: "hidden",
    },
    sticky: {
        position: "sticky",
        left: "0"
    }
}));

const DailyAchHistory = () => {
    let { id } = useParams()

    const classes = useStyles();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, SetTableData] = useState([]);
    // For Pagination
    const [page, setPage] = useState(1);
    // For Search
    const [limit, setLimit] = useState(10);
    const [paginationData, setPaginationData] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        window.scrollTo(0, 0)
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        window.scrollTo(0, 0);
    };
    const [stateData, setStateData] = useState()
    const state = useLocation()
    useEffect(() => {
        setStateData(state?.state?.state)
    }, [])
    const getData = async () => {
        setIsLoading(true);
        try {
            const { data } = await axios.get(
                `/private/getAchieveUser/${id}?page=${page}&limit=${limit}`
            );
            setPaginationData(data?.data);
            SetTableData(data?.data?.docs);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error)
        }
    }

    useEffect(() => {
        getData();
    }, [page]);

    const date = new Date(stateData?.date);
    const formattedDate = `${date.toLocaleDateString('en-IN', { weekday: 'long' })}, ${date.toLocaleDateString('en-IN', { day: '2-digit', month: 'long', year: 'numeric' })}`;


    const formatTime = (minutes) => {
        const totalSeconds = Math.floor(minutes * 60); // Convert minutes to seconds
    
        const hours = Math.floor(totalSeconds / 3600); // Get hours
        const remainingMinutes = Math.floor((totalSeconds % 3600) / 60); // Get remaining minutes
        const seconds = totalSeconds % 60; // Get remaining seconds
    
        // Format the time as HH:MM:SS
        return `${String(hours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };
    return (
        <>
            <div className="hewe-cont">
                <div>
                    <div className="flex-align-center justify-content-between mb-4 w-100">
                        <div className="flex-align-center ">
                            <IoChevronBack
                                size={32}
                                className="back-color"
                                onClick={() =>
                                    history.goBack()}
                            />
                            <DashHeading>Target Achiever's  List</DashHeading>
                            <p className="mx-2">|</p>
                            <p>{" "}{formattedDate}</p>
                            <p className="mx-2">|</p>
                            <p>{stateData?.healthTarget} miles</p>

                        </div>
                    </div>
                    {/* <div className="d-flex mb-3 align-items-center">
                        <div className="detailsbox me-2">
                            <p><strong>Date:</strong>{" "}{formattedDate}</p>
                        </div>
                        <div className="detailsbox">
                            <p><strong>Health Target:{" "}</strong>{stateData?.healthTarget} mile</p>
                        </div>
                    </div> */}
                    {tableData?.length > 0 ? (<>
                        <div className="">
                            <Paper className={classes.paperTableHeight}>
                                <>
                                    <TableContainer
                                        className={classes.tableMainContainer}
                                    >
                                        <Table>
                                            <TableHead>
                                                <TableRow className={classes.tableHeadingRow}>
                                                    <TableCell className={classes.tablseHeadingCell}>
                                                        S.No
                                                    </TableCell>

                                                    <TableCell className={classes.tablseHeadingCell}>
                                                        User Name
                                                    </TableCell>
                                                    <TableCell className={classes.tablseHeadingCell}>
                                                        City
                                                    </TableCell>
                                                    <TableCell className={classes.tablseHeadingCell}>
                                                        Activity Duration
                                                    </TableCell>


                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {tableData.map((category, index) => (
                                                    <TableRow key={category._id}>
                                                        <TableCell className={classes.textMiddle}>
                                                            <div>{index + 1 + rowsPerPage * (page - 1)}</div>
                                                        </TableCell>

                                                        <TableCell className={classes.textMiddle}>
                                                            <div target="_blank" className="text-b1 underline" onClick={() => history.push(`/accountManagement/view-profile/${category?.userId}`)}>{get(category, 'profileName', 'N/A')}</div>
                                                        </TableCell>

                                                        <TableCell className={classes.textMiddle}>
                                                            <div>{category?.cities?.map((item) => {
                                                                return (
                                                                    <p>{item}</p>
                                                                )
                                                            })}</div>
                                                        </TableCell>
                                                        <TableCell className={classes.textMiddle}>
                                                            <div>{category?.walkingSum !== 0 ? <>
                                                                <span className="walkingTime">
                                                                    <FootIcon /><span className="ms-2">{formatTime(category?.walkingTimeSum || 0)}</span> 
                                                                </span>
                                                            </> :null} 
                                                            {category?.cyclingSum != 0 ? <>
                                                                <span className="cyclingTime">
                                                                    <CycleIcon /><span className="ms-2">{formatTime(category?.cyclingTimeSum || 0)}</span> 
                                                                </span>
                                                            </> : null}</div>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}



                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Stack spacing={2} className="flex-align-center justify-content-center w-100 py-3">
                                        <Pagination
                                            count={Math.ceil(paginationData?.totalDocs / rowsPerPage)}
                                            page={page}
                                            onChange={(event, value) => handleChangePage(event, value)}
                                            renderItem={(item) => (
                                                <PaginationItem
                                                    slots={{ previous: FiArrowLeft, next: FaArrowRight }}
                                                    {...item}
                                                />
                                            )}
                                        />
                                    </Stack>
                                </>
                            </Paper>
                        </div>
                    </>) : (
                        <div className="emptyTable mt-3">
                            No Data Found
                        </div>
                    )}
                </div>
            </div>



            {isLoading && <Overlay />}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        userData: state.userData,
        locationData: state.locations,
        defaultState: state.defaultState,
        sidebar: state.sidebar,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUsers: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_USER,
                updatedUser: updatedValue,
            });
        },
        setDefaultState: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_DEFAULT,
                updateDefault: updatedValue,
            });
        },
        setSidebar: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_SIDEBAR,
                updateSidebar: updatedValue,
            });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DailyAchHistory));
