import React, { useState } from "react";
import styled from "styled-components";
import { IconContext } from "react-icons/lib";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import SubMenu from "./SubMenu";
import "./sidebarScrollDesign.css";
import { SidebarData, profileSidebarData } from "./SidebarData";
import { RiLogoutBoxLine } from "react-icons/ri";
import { MdOutlineArrowRightAlt } from "react-icons/md";
import axios from "../../axios"
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Modal } from "../../components/Modal";
import { TiInfoOutline } from "react-icons/ti";
import { SlClose } from "react-icons/sl";
import { IoMdArrowRoundBack } from "react-icons/io";
const SidebarNav = styled.nav`
  background: #132400;
  width: 280px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 100ms;
  z-index: 8;
  @media screen and (max-width: 780px) {
    left: ${({ sidebar }) => (sidebar ? "-100%" : "0")};
  }
  @media screen and (max-width: 480px) {
    left: ${({ sidebar }) => (sidebar ? "-100%" : "0")};
  }
`;

const SidebarWrap = styled.div`
  width: 100%;
  overflow-x: hidden;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
`;

export const BannerContent = styled.div`
  width: 100%;
  height: 60px;
  margin-top: 0.5rem;
  border-radius: 4px;
  margin-right: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BannerCity = styled.div`
  text-align: left;
  font-size: 17px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  width: 100%;
`;

export const BannerState = styled.div`
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
  text-overflow: ellipsis;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
`;

export const Rating = styled.p`
  color: #878997;
  font-size: 0.8rem;
`;

export const SidebarDiv = styled.p`
  margin-top: 5rem;
`;

const Sidebar = ({setUsers,userData, sidebar, setSidebar}) => {
  var showProfileSidebar = Cookies.get("showProfileSidebar") ? JSON.parse(Cookies.get("showProfileSidebar")) : "";
  const showSidebar = () => setSidebar(!sidebar);
  const [isLoading, setIsLoading] = useState(false);
  const parsedUserData = JSON.parse(localStorage.getItem("userData")) || JSON.parse(sessionStorage.getItem("userData"));
  let modules=parsedUserData?.data?.modules;

  
  const [openModal, setOpenModal] = useState(false);

  const accessibleModules = modules
    ?.filter((item) => item.access)
    ?.map((module) => module.moduleName);

  let filteredSidebarData;
  if (userData?.userType == "subAdmin") {
    filteredSidebarData = SidebarData?.filter((item) =>
      accessibleModules?.includes(item?.title)
    )
  } else {
    filteredSidebarData = SidebarData
  }
  const history = useHistory()
  const logout = async () => {
    setIsLoading(true);
    setOpenModal(true);
    try {
      const { data } = await axios.delete(`/private/logout`);
      const itemsToRemove = ["token", "userData", "email", "deviceId"];
      itemsToRemove.forEach((item) => {
        localStorage.removeItem(item);
        sessionStorage.removeItem(item);
      });
      Cookies.remove("showProfileSidebar");
      toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);
      history.push("/");
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 401) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } finally {
    }
  }
  return (
    <>
      {showProfileSidebar ?
        <IconContext.Provider value={{ color: "#fffff" }}>
          <SidebarNav sidebar={sidebar}>
            <SidebarWrap className="designScrollbarSide">
              <SidebarDiv></SidebarDiv>
              <div className="pt-2">
                <div className="backtohome mt-3"
                  onClick={() => {
                    Cookies.set("showProfileSidebar", false, { expires: 365 });
                    history.push({
                      pathname: "/dashboard",
                    });
                  }}> <IoMdArrowRoundBack style={{ fontSize: "22px" }} /> Back to Home</div>

              </div>


              {profileSidebarData.map((item, index) => {
                return <SubMenu item={item} key={index} />;
              })}
            </SidebarWrap>
          </SidebarNav>
        </IconContext.Provider> :
        // for profile
        <IconContext.Provider value={{ color: "#fffff" }}>
          <SidebarNav sidebar={sidebar}>
            <SidebarWrap className="designScrollbarSide">
              <SidebarDiv></SidebarDiv>
              {filteredSidebarData?.map((item, index) => {
                return <SubMenu item={item} key={index} />;
              })}
            </SidebarWrap>
          </SidebarNav>
        </IconContext.Provider>
      }

      {/* Modal for logout  */}
      <Modal
        maxWidth="lg"
        width="360px"
        RoundedCorners={true}
        isOpen={openModal}
        onClose={() => {
          setOpenModal(true);
        }}
        backgroundModal={false}
        backgroundModalContent={false}
        title={
          <div className="">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <TiInfoOutline style={{ color: "red" }} />
                <p className="ps-2" style={{ color: "red" }}>Log Out?</p>
              </div>
              <SlClose
                style={{
                  cursor: "pointer",
                }}
                onClick={() => { setOpenModal(false); }}
              />
            </div>
            <div className="logout-header">Are you sure want to Log Out ?</div>
          </div>
        }
        content={
          <>
            {/* <span className="are-you-sure">Are You Sure?</span> */}
            <div className="logout-button-group">
              <div className="logout-button" onClick={() => logout()}>
                Yes
              </div>
              <div
                className="cancel-button"
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                No
              </div>
            </div>
          </>
        }
      />
    </>



  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
    sidebar: state.sidebar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setDefaultState: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_DEFAULT,
        updateDefault: updatedValue,
      });
    },
    setSidebar: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_SIDEBAR,
        updateSidebar: updatedValue,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Sidebar));
