import axios from "axios";
import { API_URL } from "./constants/Statics";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";

const SecretPassphrase = 'e0m636izi4gnbz9htaxewtr5ogepbwai';

const encryptData = (data) => {
  try {
    let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), SecretPassphrase).toString();
    return ciphertext;
  } catch (error) {
    throw error
  }
}

const decryptData = (data) => {
  try {
    console.log({ data })
    let urlDecodedData = decodeURIComponent(data);
    console.log({ urlDecodedData })
    let bytes = CryptoJS.AES.decrypt(urlDecodedData, SecretPassphrase);
    console.log({ bytes })
    let a = bytes.toString(CryptoJS.enc.Utf8);
    console.log(a)

    let decryptedData = JSON.parse(a);

    console.log({ decryptedData })
    return decryptedData;
  } catch (error) {
    console.log({ error })
    throw error
  }
}

const instance = axios.create({
  baseURL: API_URL,
});

instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
// Set Authorization token if available
const JWT_token = localStorage.getItem("token") || sessionStorage.getItem("token");
if (JWT_token) {
  instance.defaults.headers.common["Authorization"] = `Bearer ${JWT_token}`;
}

// Request Interceptor - Encrypt Payload
instance.interceptors.request.use(
  async (config) => {
    const JWT_token = localStorage.getItem("token") || sessionStorage.getItem("token");
    if (JWT_token) {
      config.headers["Authorization"] = `Bearer ${JWT_token}`;
    }
    if (config.data) {
      config.data = { payload: encryptData(config.data) };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response Interceptor - Decrypt Response
instance.interceptors.response.use(
  (response) => {
    if (response.data) {
      response.data = decryptData(response.data);
    }
    console.log({ response })
    return response;
  },
  async (error) => {
    if (error.response && error.response.data) {
      try {
        error.response.data = decryptData(error.response.data);
      } catch (err) {
        console.error("Error decrypting response data:", err);
      }
    }

    if (error.response?.status === 902) {
      console.log("Handling 902 error...");

      try {
        const userData = localStorage.getItem("userData") || sessionStorage.getItem("userData");
        const deviceId = localStorage.getItem("deviceId") || sessionStorage.getItem("deviceId");

        if (!userData) throw new Error("User data not found");

        const parsedUserData = JSON.parse(userData);
        if (!parsedUserData?.data?.userId) throw new Error("User ID missing in stored data");

        const formData = { _id: parsedUserData?.data?.userId, deviceId };
        const response = await axios.post(`${API_URL}/getAccess`, formData);
        const updatedUserData = response?.data?.data;

        if (localStorage.getItem("deviceId")) {
          localStorage.setItem("userData", JSON.stringify(updatedUserData));
        } else {
          sessionStorage.setItem("userData", JSON.stringify(updatedUserData));
        }

        // Find first accessible module


        const SidebarData = [
          { title: "Dashboard", path: "/dashboard" },
          { title: "Account Management", path: "/accountManagement" },
          { title: "Reward Management", path: "/refer-and-earn" },
          { title: "Level Management", path: "/level-management" },
          { title: "Daily Achievement", path: "/daily-achievement" },
          { title: "Sub Admin Management", path: "/sub-admin-management" },
          { title: "CMS Management", path: "/privacy-policy" },
          { title: "Notification Management", path: "/notification-management" },
          { title: "Ticket Management", path: "/ticket-management" },
        ];

        const firstAccessibleModule = updatedUserData?.modules?.find((module) => module.access)?.moduleName;
        const redirectUrl = SidebarData.find((item) => item.title === firstAccessibleModule)?.path || "/adminPanel/dashboard";

        window.location.pathname = updatedUserData?.userType === "subAdmin" ? redirectUrl : "/adminPanel/dashboard";
        toast.success("Your access has been updated.");
        window.reload();
      } catch (error) {
        console.error("Error fetching access data:", error);
      }
    }

    if ([401].includes(error.response?.status)) {
      ["token", "userData", "email", "deviceId"].forEach((item) => {
        localStorage.removeItem(item);
        sessionStorage.removeItem(item);
      });
      toast.error("Session expired. Please login again.");
      setTimeout(() => {
        window.location.pathname = "/adminPanel";
      }, 1000)

    }
    return Promise.reject(error);
  }
);

export default instance;
