import React, { useState } from 'react'
import { DashHeading } from '../AccountManagement/AccountManagementElements'
import PremiumClaims from './PremiumClaim';
import AuditTokensClaims from './AuditTokensClaim';
import "./style.scss"
function ClaimManagement() {
    const [stateValue, setStateValue] = useState(0);
    const handleCardClick = (value) => {
        setStateValue(value);
    };
    const cardItems = [
        { label: 'Premium', value: 0 },
        { label: 'Audit Tokens', value: 1 },
    ];
    return (
        <div className='hewe-cont claimManagement' >
            <div className="flex-align-center ">
                <div className="dott me-3"></div>
                <DashHeading>Claim Management</DashHeading>
            </div>

            <div>
                <div className="rewardcardcont">
                    <div className="row flex-wrap">
                        {cardItems.map((item, index) => (
                            <div key={index} className="col-xl-2 col-lg-3 col-md-4 col-6 mb-2">
                                <div
                                    className="cardcont"
                                    onClick={() => handleCardClick(item.value)}
                                    style={
                                        stateValue === item.value
                                            ? { backgroundColor: '#599265E0', color: 'white' }
                                            : { backgroundColor: '#EFEFEF', color: "#212121" }
                                    }
                                >
                                    <h4>{item.label}</h4>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {stateValue === 0 ? (
                    <PremiumClaims/>
                ) : stateValue === 1 ? (
                    <AuditTokensClaims/>
                ) : null
                }
            </div>
        </div>
    )
}

export default ClaimManagement