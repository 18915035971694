import React, { useState, useEffect, useRef } from "react";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "../../axios";
import Overlay from "../Overlay";
import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import "react-datepicker/dist/react-datepicker.css";
import { FiArrowLeft } from "react-icons/fi";
import { FaArrowRight } from "react-icons/fa";
import { PaginationItem } from "@material-ui/lab";
import { Pagination, Stack } from "@mui/material";
import moment from "moment";
import { IoIosInformationCircleOutline } from "react-icons/io";

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Filter } from "../svg";
import Popper from "@mui/material/Popper";
const useStyles = makeStyles((theme) => ({
    paperTableHeight: {
        width: "100%",
        minHeight: "50vh",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        border: "1px solid #D9E3D9",
        overflowX: "scroll",
    },
    tableMainContainer: {
        overflowX: "scroll",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
    },
    tableHeadingRow: {
        // backgroundColor: "#21AFE6",
    },
    tablseHeadingCell: {
        padding: "0.8rem 20px !important",
        textAlign: "center",
        fontSize: "14px",
        color: "#264503",
        fontWeight: 600,
        borderBottom: "1px solid #D9E3D9",
        whiteSpace: "nowrap",
    },
    textMiddle: {
        padding: "0.6rem 20px !important",
        verticalAlign: "middle !important",
        textAlign: "center",
        fontSize: "14px",
        color: "black",
    },
    tablePaginationStyle: {
        borderTop: "1px solid #0000001a",
        borderRadius: "0rem 0rem 0.4rem 0.4rem",
        overflowY: "hidden",
    },
    sticky: {
        position: "sticky",
        left: "0"
    }
}));


const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: "0 0 4px #0000001F",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

const OtherReward = (id) => {
    let userId = id?.id;
    const classes = useStyles();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, SetTableData] = useState([]);
    // For Pagination
    const [page, setPage] = useState(1);
    // For Search
    const [limit, setLimit] = useState(10);
    const [paginationData, setPaginationData] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(10);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        window.scrollTo(0, 0);
    };

    const [type, setType] = useState()
    const handleCheckboxChange = (value) => {
        setType(prevType => (prevType === value ? "" : value));
    };
    const getData = async (selectedType = type) => {
        try {
            const formData = {
                type: selectedType
            }
            setIsLoading(true)
            const { data } = await axios.post(
                `/otherReward/${userId}?page=${page}&limit=${limit}`, formData
            );
            setPaginationData(data?.data);
            SetTableData(data?.data?.docs);
            setIsLoading(false)
            setIsShow(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }

    const handleChange = async () => {
        setType("")
        await getData("")
    }

    useEffect(() => {
        getData();
    }, [page]);

    const [isShow, setIsShow] = useState(false)
    // / out side click handle
    const dropdownRef = useRef(null);
    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsShow(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };
  
    const open = Boolean(anchorEl);
    const ids = open ? "tooltip-popper" : undefined;
    return (
        <>

            <div>
                <div className="position-relative" ref={dropdownRef}>
                    {/* <div className="d-flex justify-content-end">
                        <button className="me-3 filterbtn mb-3" onClick={() => setIsShow(!isShow)}><Filter />  Filter</button>
                    </div> */}
                    {
                        isShow && <div className="otherfilterbody">
                            <p className="pb-3 fw-500">Source</p>
                            <div>
                                <div className="flex-align-center">
                                    <input
                                        type="checkbox"
                                        checked={type === "1"}
                                        onChange={() => handleCheckboxChange("1")}
                                    />
                                    <label className="ms-3">Referral</label>
                                </div>
                                <div className="flex-align-center">
                                    <input
                                        type="checkbox"
                                        checked={type === "2"}
                                        onChange={() => handleCheckboxChange("2")}
                                    />
                                    <label className="ms-3">Level Achievement</label>
                                </div>
                                <div className="mt-4 d-flex justify-content-end">
                                    <button className="savebtn" onClick={() => getData()}>Apply</button>
                                    <button className="canclebtn ms-4" onClick={() => handleChange()}>Reset</button>
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>
            <div>
                {tableData?.length > 0 ? (<>
                    <Paper className={classes.paperTableHeight}>
                        <>
                            <TableContainer
                                className={classes.tableMainContainer}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow className={classes.tableHeadingRow}>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                S.No
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Source
                                            </TableCell>
                                            <TableCell className={[classes.tablseHeadingCell]}>
                                                Type of Reward
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Reward Name
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Date Earned
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Quantity
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Redemption Date
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableData?.length > 0 ? (<>
                                            {tableData.map((category, index) => (
                                                <TableRow key={category._id}>
                                                    <TableCell className={classes.textMiddle}>
                                                        <div>{index + 1 + rowsPerPage * (page - 1)}</div>
                                                    </TableCell>
                                                    <TableCell className={classes.textMiddle}>
                                                        <div className=" parentOtherReward">
                                                            {category?.source == 1 ? (
                                                                <>
                                                                    Referral
                                                                    <Button
                                                                        style={{ marginLeft: "-18px" }}
                                                                        onClick={handleClick}
                                                                    >
                                                                        <IoIosInformationCircleOutline size={22} color="black" />
                                                                    </Button>
                                                                    <Popper id={ids} open={open} anchorEl={anchorEl} placement="bottom-start">
                                                                        <div className="bg-white shadow-md p-2 rounded border">
                                                                            {category?.referee ? (
                                                                                <div className="py-1 px-2">
                                                                                    <p
                                                                                        className="text-b1 underline pointer fs-14"
                                                                                        onClick={() =>
                                                                                            (window.location.href = `/adminPanel/accountManagement/view-profile/${category?.referee?.userId}`)
                                                                                        }
                                                                                    >
                                                                                        {category?.referee?.profileName}
                                                                                    </p>
                                                                                    <p className="fs-12 text-gray">
                                                                                        {moment(category?.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                                                                                    </p>
                                                                                </div>
                                                                            ) : (
                                                                                <div className="py-1 px-2">
                                                                                    <p
                                                                                        className="text-b1 underline pointer fs-14"
                                                                                        onClick={() =>
                                                                                            (window.location.href = `/adminPanel/accountManagement/view-profile/${category?.referrer?.userId}`)
                                                                                        }
                                                                                    >
                                                                                        {category?.referrer?.profileName}
                                                                                    </p>
                                                                                    <p className="fs-12 text-gray">
                                                                                        {moment(category?.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                                                                                    </p>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </Popper>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    Level Achievement
                                                                    <HtmlTooltip
                                                                        title={
                                                                            <React.Fragment>
                                                                                <Typography color="inherit"><p className="font-italic text-gray fs-12"> Achieved Level {category?.levelInfo?.level}</p></Typography>
                                                                            </React.Fragment>
                                                                        }
                                                                    >
                                                                        <Button style={{ marginLeft: "-18px" }}><IoIosInformationCircleOutline size={22} color="black" /></Button>
                                                                    </HtmlTooltip>
                                                                </>
                                                            )}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={classes.textMiddle}>
                                                        {
                                                            category?.source == 1 ? (
                                                                <>HeXP</>
                                                            ) : (
                                                                <>
                                                                    {
                                                                        category?.categoryName === "1" ? "Voucher" :
                                                                            category?.categoryName === "2" ? "In App" :
                                                                                category?.categoryName === "3" ? "Audit Token" :
                                                                                    category?.categoryName === "4" ? "Premium" :
                                                                                        category?.categoryName === "5" ? "Trip" :
                                                                                            "Unknown Category"
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                    </TableCell>
                                                    <TableCell className={classes.textMiddle}>
                                                        {
                                                            category?.source == 1 ? (
                                                                <>HeXP</>
                                                            ) : (
                                                                <>
                                                                    {
                                                                        category?.Reward?.rewardName ?? "N/A"
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                    </TableCell>
                                                    <TableCell className={classes.textMiddle}>
                                                        {moment(category?.createdAt).format("DD/MM/YYYY")}
                                                    </TableCell>
                                                    <TableCell className={classes.textMiddle}>
                                                        {category?.source == 1 ? (
                                                            <>{category?.HeweCoins}</>
                                                        ) : (
                                                            <>{category?.Reward?.rewardAmount}</>
                                                        )}
                                                    </TableCell>
                                                    <TableCell className={classes.textMiddle}>
                                                        {category?.source == 1 ? (
                                                            <>{moment(category?.createdAt).format("DD/MM/YYYY")}</>
                                                        ) : (
                                                            <>{category?.categoryName == "2" ? <>{moment(category?.createdAt).format("DD/MM/YYYY")} </> : "N/A"}</>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </>) : (
                                            <TableRow>
                                                <TableCell colSpan={12} rowSpan={10} align="center">
                                                    No Data Found
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Stack spacing={2} className="flex-align-center justify-content-center w-100">
                                <Pagination
                                    count={Math.ceil(paginationData?.totalDocs / rowsPerPage)}
                                    page={page}
                                    onChange={(event, value) => handleChangePage(event, value)}
                                    renderItem={(item) => (
                                        <PaginationItem
                                            slots={{ previous: FiArrowLeft, next: FaArrowRight }}
                                            {...item}
                                        />
                                    )}
                                />
                            </Stack>
                        </>
                    </Paper>
                </>) : (
                    <div className="emptyTable mt-3">
                        No Data Found
                    </div>
                )}
            </div>
            {isLoading && <Overlay />}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        userData: state.userData,
        locationData: state.locations,
        defaultState: state.defaultState,
        sidebar: state.sidebar,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUsers: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_USER,
                updatedUser: updatedValue,
            });
        },
        setDefaultState: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_DEFAULT,
                updateDefault: updatedValue,
            });
        },
        setSidebar: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_SIDEBAR,
                updateSidebar: updatedValue,
            });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(OtherReward));
