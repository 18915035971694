import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useStyles } from "../../components/rewardTableTheme";
import axios from "../../axios";
import { toast } from "react-toastify";
import Overlay from "../../components/Overlay";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { FiArrowLeft } from "react-icons/fi";
import { FaAngleDown, FaArrowRight } from "react-icons/fa";
import Select from "react-select";
import { Stack } from "@mui/material";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { BsEye, BsPencil } from "react-icons/bs";
import moment from "moment/moment";
import { ArrowDown, ArrowUp, Copy, Filter } from "../../components/svg";
import { DashHeading } from "../AccountManagement/AccountManagementElements";

function HeweClaim() {
    const history = useHistory();
    // for module
    const parsedUserData =
        JSON.parse(localStorage.getItem("userData")) ||
        JSON.parse(sessionStorage.getItem("userData"));
    let modules = parsedUserData?.data?.modules;
    const permissions = modules?.find(
        (item) => item.moduleName === "Claim Management"
    )?.permissions;

    // state
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    const [tableData, setTableData] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [paginationData, setPaginationData] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortingValue, setSortingValue] = useState("");
    const [sortingType, setSortingType] = useState("")
    const [selectedOption, setSelectedOption] = useState({
        label: "In Progress",
        value: "1"
    });
    // for filter
    const [isShow, setIsShow] = useState(false)
    const [type, setType] = useState("")
    const handleCheckboxChange = (value) => {
        setType(prevType => (prevType === value ? "" : value));
    };

    const handleSort = (key) => {
        setSortingValue((prevSortingValue) => (prevSortingValue === key ? key : key));
        setSortingType((prevSortType) => {
            if (sortingValue === key) {
                return prevSortType === "1" ? "2" : "1";
            } else {
                return "1";
            }
        });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        window.scrollTo(0, 0);
    };

    const handleRespose = async (types = type) => {
        setIsLoading(true);
        try {
            const { data } = await axios.get(`/private/getHeweWithdrawals?page=${page}&limit=${limit}&sortType=${sortingType}&sortingValue=${sortingValue}&status=${types}`);
            setTableData(data?.data?.docs);
            setIsShow(false)
            setPaginationData(data?.data)
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.loog(error);
        }
    };
    useEffect(() => {
        handleRespose();
    }, [sortingType, sortingValue]);

    const changeStatus = async (id) => {
        try {
            setIsLoading(true)
            if (id != undefined) {
                const data = await axios.get(`/private/transfereHewe/${id}`)
                toast.success(data?.data?.message)
            }
            handleRespose()
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const copytext = async (data) => {
        try {
            await navigator.clipboard.writeText(data);
            toast.success("copied")
        } catch (error) {
            console.log(error)
        }
    }
    const handleChange = async () => {
        setType("")
        await handleRespose("")
    }

    // out side click handle
    const dropdownRef = useRef(null);
    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsShow(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <div className="hewe-cont">
            <div className="flex-align-center justify-content-between mb-3">
                <div className="flex-align-center ">
                    <div className="dott me-3"></div>
                    <DashHeading>Hewe Claim</DashHeading>
                </div>
                <div className='d-flex align-items-center'>
                    <div className="custom-dropdown-container" ref={dropdownRef}>
                        <button className='filtertbn me-0' onClick={() => setIsShow(!isShow)}>
                            <div className='d-flex align-items-center'><Filter /> <p className="ms-1 font-italic fw-400" style={{ color: "#464646c1" }}>Filter</p></div>   <span><FaAngleDown color='gray' /></span>
                        </button>
                        {
                            isShow && <div className="otherfilterbody">
                                <div>
                                    <div className="flex-align-center my-2">
                                        <input
                                            type="checkbox"
                                            checked={type === "1"}
                                            onChange={() => handleCheckboxChange("1")}
                                        />
                                        <label className="ms-3">In Progress</label>
                                    </div>
                                    <div className="flex-align-center my-2">
                                        <input
                                            type="checkbox"
                                            checked={type === "3"}
                                            onChange={() => handleCheckboxChange("3")}
                                        />
                                        <label className="ms-3">Transferred</label>
                                    </div>
                                    <div className="mt-4 d-flex justify-content-end">
                                        <button className="savebtn" onClick={() => handleRespose()}>Apply</button>
                                        <button className="canclebtn ms-4" onClick={() => handleChange()}>Reset</button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {tableData?.length > 0 ? (
                <>
                    <Paper
                        className="mt-3"
                    >
                        <>
                            <TableContainer className={classes.tableMainContainer} >
                                <Table>
                                    <TableHead>
                                        <TableRow className={classes.tableHeadingRow}>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                S.No
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Action
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                User Name
                                                <span onClick={() => handleSort("1")}>
                                                    {sortingValue === "1" && sortingType === "1" ? <ArrowUp /> : sortingValue === "1" && sortingType === "2" ? <ArrowDown /> : <ArrowUp />}
                                                </span>
                                            </TableCell>
                                            <TableCell className={[classes.tablseHeadingCell]}>
                                                Request ID
                                            </TableCell>
                                            <TableCell className={[classes.tablseHeadingCell]}>
                                                Received On
                                            </TableCell>
                                            <TableCell className={[classes.tablseHeadingCell]}>
                                                HeXP Request
                                            </TableCell>
                                            <TableCell className={[classes.tablseHeadingCell]}>
                                                Hewe Claimed
                                            </TableCell>
                                            {/* <TableCell className={[classes.tablseHeadingCell]}>
                                                Existing Point
                                            </TableCell> */}
                                            <TableCell className={[classes.tablseHeadingCell]}>
                                                Address
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableData?.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell className={classes.textMiddle}>
                                                    <div>{index + 1 + rowsPerPage * (page - 1)}</div>
                                                </TableCell>

                                                <TableCell className={classes.textMiddle}>
                                                    {
                                                        item?.action != "3" ? (
                                                            <Select
                                                                options={[{ label: "Mark it as Transferred", value: item._id }]}
                                                                value={selectedOption}
                                                                onChange={(selected) => changeStatus(selected?.value)}
                                                                className="w-120 m-auto"
                                                                isSearchable={false}
                                                                classNamePrefix="react-select"
                                                                styles={{
                                                                    control: (provided) => ({
                                                                        ...provided,
                                                                        backgroundColor: "rgba(255, 241, 191, 0.38)",
                                                                        borderColor: "gray",
                                                                        textAlign: "left",
                                                                        boxShadow: "none",
                                                                        color: "black",
                                                                        "&:hover": { borderColor: "black" },
                                                                    }),
                                                                }}
                                                            />
                                                        ) : <div className="transfered m-auto">Transferred</div>
                                                    }
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <div className="link" onClick={() => history.push(
                                                        `/accountManagement/view-profile/${item?.userId}`
                                                    )}>
                                                        {item?.profileName}
                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    {item?._id}
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    {item?.createdAt ? moment(item.createdAt).format("lll") : "-"}
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>  {item?.withdrawalHexpAmount}</TableCell>
                                                <TableCell className={classes.textMiddle}>    {item?.pointsClaimed}</TableCell>
                                                {/* <TableCell className={classes.textMiddle}>    {item?.existingHexp}</TableCell> */}
                                                <TableCell className={classes.textMiddle}><div className="d-flex justify-content-center">
                                                    **********{(item?.walletAddress).slice(-6)}  <span className="ms-2" onClick={() => copytext(item?.walletAddress)}><Copy /></span>
                                                </div>  </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <Stack
                                    spacing={2}
                                    className="flex-align-center justify-content-center w-100 py-3"
                                >
                                    <Pagination
                                        count={Math.ceil(paginationData?.totalDocs / rowsPerPage)}
                                        page={page}
                                        onChange={(event, value) => handleChangePage(event, value)}
                                        renderItem={(item) => (
                                            <PaginationItem
                                                slots={{ previous: FiArrowLeft, next: FaArrowRight }}
                                                {...item}
                                            />
                                        )}
                                    />
                                </Stack>
                            </TableContainer>
                        </>
                    </Paper>
                </>
            ) : (
                <div className="emptyTable mt-3">No Data Found</div>
            )}
            {isLoading && <Overlay />}
        </div>
    );
}

export default HeweClaim;
