import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import axios from "../../axios";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { FaEye } from "react-icons/fa";
import { IoChevronBack } from "react-icons/io5";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { AccoutSvgMail, AccoutSvgPhone, CalenderSvg, ContactSvg, CycleIcon, DobSvg, FootIcon, LocationSvg, ReferralSvg, UserFace } from "../svg";
import awardimg from "../../images/hewe/award.svg";
import maleProfileImg from "../../images/hewe/maleProfile.png"
import femaleProfileImg from "../../images/hewe/famale.png";
import imageplaceholder from "../../images/hewe/imageplaceholder.png";
import { DateFormater } from "../dateFormater";
import Rewardmngt from "./rewardmngt";
import WalkingHistory from "./walkingHistory";
import CyclingHistory from "./cyclingHistory";
import BoosterHistory from "./boosterHistory";
import { Tooltip } from "@mui/material";
import HeweCoin from "../../images/hewe/accountHewe.svg"
import HeXP from "../../images/hewe/HeXp.png"
import { TbExternalLink } from "react-icons/tb";
import OtherReward from "./otherReward";
import WithdrawHistory from "../../pages/RewardManagement/withdrawHistory";
import { IconButton } from "@material-ui/core";
function ViewProfile() {
  const [stateValue, setStateValue] = useState(0);
  const Items = [
    { label: "Booster History", value: 0 },
    { label: 'Walking History', value: 2 },
    { label: 'Cycling History', value: 3 },
    { label: 'Spin History', value: 1 },
    { label: "Other Reward", value: 4 },
    { label: "Withdraw History", value: 5 },
  ];

  const history = useHistory();
  const { state } = useLocation();
  const param = useParams();
  const [profile, setProfile] = useState();
  const [rank, setRank] = useState()
  const [rewardData, setRewardData] = useState()
  const [distance, setDistance] = useState()
  const data = async () => {
    try {
      const resp = await axios.get(`/getProfileById/${param.id}`);
      const reward = await axios.get(`/private/getRewardType`);
      setRewardData(reward?.data?.data)
      setDistance(resp?.data)
      setProfile(resp?.data?.data);
      setRank(resp?.data?.currenRank)
    } catch (error) { }
  };
  useEffect(() => {
    data();
  }, []);

  const ballBooster = rewardData?.find(item => item.key === "ballBooster");
  const arrowBooster = rewardData?.find(item => item.key === "arrowBooster");
  const spinBooster = rewardData?.find(item => item.key === "spin");


  const handleClick = (value) => {
    setStateValue(value);
    sessionStorage.setItem('spinReward', value);
  };

  const [dataShow, setIsDataShow] = useState(0);
  const toggleRef = useRef(null);

  const OnOpen = (payload) => {
    setIsDataShow(payload);
  };

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (toggleRef.current && !toggleRef.current.contains(event.target)) {
        setIsDataShow(0);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);



  return (
    <section className="hewe-cont">
      <div className="viewProfile">
        <div className="d-lg-flex justify-content-between align-items-center">
          <div className="flex-align-center">
            <IoChevronBack
              size={32}
              className="back-color"
              onClick={() => {
                history.goBack({
                  state: { page: state?.state?.page },
                })
              }}
            />
            <h5 className="back-color mb-0 ms-2">Back</h5>
          </div>
          <div className="flex-align-center flex-wrap mt-lg-0 mt-3">
            <div className="spending pointer mt-sm-0 mt-2 flex-align-center" onClick={() => history.push(`/accountManagement/purchase/${param.id}`)}>Total Spending<TbExternalLink className="ms-2 text-black" color="black" size={22} /></div>
            <div className="referralCode ms-3  mt-sm-0 mt-2 pointer" onClick={() => history.push(`/accountManagement/view-referral/${param.id}`)}>Referral Code <span>{profile?.userReferralCode} <TbExternalLink className="ms-2 text-black" color="black" size={22} /></span></div>
            <div className="redflag ms-3  mt-sm-0 mt-2 pointer" onClick={() => history.push(`/accountManagement/redflag/${param.id}`)}>Red Flag <span>{profile?.redFlag}</span><TbExternalLink className="ms-2 text-white" color="white" size={22} /></div>
          </div>
        </div>


        <div className=" row align-items-stretch" >
          <div className="pt-4  col-xxl-5 col-xl-5">
            <div className="profile ">
              <div className="profileImg d-flex">
                <div className="position-relative">
                  <img
                    src={profile?.gender === "1" ? maleProfileImg : profile?.gender === "2" ? femaleProfileImg : imageplaceholder}
                    alt={profile?.gender === "1" ? "Male Profile" : "Female Profile"}
                    className=""
                  />

                  {
                    profile?.accountStatus == "1" ? <div className="activeDot"></div> : null
                  }
                </div>
                <div className="data ms-3 mt-0">
                  <h3 className="flex-align-center">
                    <Tooltip title="Profile Name" arrow>
                      <span className="d-block">
                        <strong className="mx-2"><UserFace /></strong>
                      </span>
                    </Tooltip>
                    {profile?.profileName}
                  </h3>
                  <h3 className="flex-align-center">
                    <Tooltip title="Email Id" arrow>
                      <span className="d-block">
                        <strong className="mx-2"> <AccoutSvgMail /></strong>
                      </span>
                    </Tooltip>
                    {profile?.email}
                  </h3>
                  <h3 className="flex-align-center">
                    <span className="mx-2">
                      <Tooltip title="Phone Number" arrow>
                        <span className="d-block">
                          <AccoutSvgPhone />
                        </span>
                      </Tooltip>
                    </span>
                    {profile?.countryCode}{profile?.phoneNumber}
                  </h3>
                </div>
              </div>
              <div className="data row">
                <div className="col-6 pe-3">
                  <div className="details-card">
                    <h3 className="flex-align-center">
                      <Tooltip title="User ID" arrow>
                        <span className="mx-2 d-block"><ContactSvg /></span>
                      </Tooltip>
                      {profile?.userId}
                    </h3>
                    <h3 className="flex-align-center">
                      <Tooltip title="Account created at" arrow>
                        <span className="d-block mx-2">
                          <CalenderSvg />
                        </span>
                      </Tooltip>
                      <DateFormater item={profile?.createdAt} />
                    </h3>
                    <h3 className="flex-align-center">
                      <Tooltip title="DOB" arrow>
                        <span className="d-block mx-2">
                          <DobSvg />
                        </span>
                      </Tooltip>
                      {profile?.dob}
                    </h3>
                  </div>
                </div>
                <div className="col-6">
                  <div className="details-card">
                    <h3 className="flex-align-center">
                      <Tooltip title="Address" arrow>
                        <span className="d-block">
                          <strong className="mx-2"><LocationSvg /> </strong>
                        </span>
                      </Tooltip>
                      <span className="truncate" >
                        <Tooltip
                          title={`${profile?.city}, ${profile?.state}, ${profile?.country}`}
                          arrow
                        >
                          <span className="d-block">
                            {profile?.city}, {profile?.state}, {profile?.country}
                          </span>
                        </Tooltip>
                      </span>
                    </h3>

                    <h3 className="flex-align-center">
                      <Tooltip title="Referral Code" arrow>
                        <span className="d-block mx-2">
                          <ReferralSvg />
                        </span>
                      </Tooltip>
                      {profile?.referralCode != "" ? profile?.referralCode : "N/A"}
                    </h3>
                    <h3 onClick={() => history.push(`/accountManagement/login-history/${param.id}`, { state: profile?.profileName })} className="ps-2 text-b1 underline pointer">See Device History</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-7 col-xl-7 pt-4">
            <div className="personalData ">
              <div className="row w-100">
                <div className="statcont">
                  <div className="row d-flex justify-content-between">
                    <div className="col-9">
                      <div className="py-3 ps-lg-4  w-100">
                        <div className="booster w-100">
                          <h2>Total Boosters</h2>
                          <div className="position-relative" ref={toggleRef}>
                            <h4>ARROW <span>{profile?.winArrowCount + profile?.dailyArrowCount}<FaEye color="white" className="ms-2" onClick={() => OnOpen(1)} /></span> </h4>
                            {
                              dataShow == 1 && (
                                <div className="dataShowtoogle">
                                  <p><span className="fw-500">Earned Booster : </span>{profile?.winArrowCount} </p>
                                  <p><span className="fw-500">Daily Booster : </span>{profile?.dailyArrowCount} </p>
                                </div>
                              )
                            }
                            <h3>
                              USED: {profile?.dailyArrowCount ?? 0}
                              <span>/{arrowBooster?.value}</span>
                            </h3>
                          </div>
                          <div className="position-relative">
                            <h4>SPIN <span>{profile?.winSpinCount + profile?.dailySpinCount}<FaEye color="white" className="ms-2" onClick={() => OnOpen(2)} /></span></h4>
                            {
                              dataShow == 2 && (
                                <div className="dataShowtoogle dataShowtoogle2">
                                  <p><span className="fw-500">Earned Spin : </span>{profile?.winSpinCount} </p>
                                  <p><span className="fw-500">Daily Spin : </span>{profile?.dailySpinCount} </p>
                                </div>
                              )
                            }
                            <h3>USED:{profile?.dailySpinCount ?? 0}<span>/{spinBooster?.value}</span></h3>
                          </div>
                          <div className=" position-relative">
                            <h4>BALL <span>{profile?.winBallCount + profile?.dailyBallCount}<FaEye color="white" className="ms-2" onClick={() => OnOpen(3)} /></span></h4>
                            {
                              dataShow == 3 && (
                                <div className="dataShowtoogle">
                                  <p><span className="fw-500">Earned Booster : </span>{profile?.winBallCount} </p>
                                  <p><span className="fw-500">Daily Booster : </span>{profile?.dailyBallCount} </p>
                                </div>
                              )
                            }
                            <h3>
                              USED: {profile?.dailyBallCount ?? 0}
                              <span>/{ballBooster?.value}</span>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="award mx-lg-5">
                        <div className=" w-fit-content">
                          <div className="imgcont">
                            <img src={awardimg} alt="img" />
                            <p className="level">{profile?.CurrentLevel}</p>
                          </div>
                        </div>
                        <h4 className="rank d-flex" style={{ color: "black" }}>Rank <span className="ms-2">{rank}</span></h4>
                      </div>
                    </div>
                  </div>
                  <div className="ms-lg-4 d-flex justify-content-between align-items-center">
                    <div className="otherdetail">
                      <CycleIcon /> <span className="ms-3 text-b1">{(distance?.totalCyclingDistance ?? 0).toFixed(2)}</span>
                    </div>
                    <div className="otherdetail ">
                      <FootIcon /> <span className="ms-3 text-p1">{(distance?.totalWalkingDistance ?? 0).toFixed(2)}</span>
                    </div>

                    <div className="otherdetail text-y1">
                      <Tooltip title={`Redeemed HeXP : ${profile?.redeemedHexp}`}>
                        <IconButton>
                          <img src={HeXP} alt="icon" />
                        </IconButton>
                      </Tooltip>
                      <span className="text-black ms-2" >{profile?.HeweCoins}</span>
                    </div>
                    <div className="otherdetail font-g1">
                      <img src={HeweCoin} alt="icon" /> <span className="text-black ms-2">{profile?.withdrawaledHewe}</span>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>

        <div className="statbtn mb-3">
          {Items.map((item, index) => (
            <div key={index} className="mt-3">
              <div
                className="cont"
                onClick={() => handleClick(item.value)}
                style={
                  stateValue === item.value
                    ? { backgroundColor: '#599265E0', color: 'white' }
                    : { backgroundColor: '#EFEFEF', color: "#212121" }
                }
              >
                <p>{item.label}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="tableData">
          {stateValue === 0 ? (
            <BoosterHistory id={param.id} />
          ) : stateValue === 1 ? (
            <Rewardmngt id={param.id} />
          ) : stateValue === 2 ? (
            <WalkingHistory id={param.id} />
          ) : stateValue === 3 ? (
            <CyclingHistory id={param.id} />
          ) : stateValue === 4 ? (
            <OtherReward id={param.id} />
          ) : stateValue === 5 ? (
            <WithdrawHistory id={param.id} />
          ) : null}
        </div>
      </div>
    </section>
  );
}

export default ViewProfile;
