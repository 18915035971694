import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { FaPlus, FaRegTrashAlt } from "react-icons/fa";
import axios from "../../../axios"
import { toast } from 'react-toastify';
import Overlay from '../../Overlay';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import "../rewardspin.scss"

import { BsEye, BsPencil } from 'react-icons/bs';
import CustomizedSwitches from '../../togglebtn';
import { useStyles } from '../../rewardTableTheme';
import { DateFormater } from '../../dateFormater';
export default function Trip({ userData }) {
    const parsedUserData = JSON.parse(localStorage.getItem("userData")) || JSON.parse(sessionStorage.getItem("userData"));
    let modules = parsedUserData?.data?.modules;
    const permissions = modules?.find((item) => item.moduleName === "Reward Management")?.permissions;
    const [isLoading, setIsLoading] = useState(false);

    const classes = useStyles();

    const [tableData, setTableData] = useState();
    const history = useHistory();
    const handleRespose = async () => {
        setIsLoading(true)
        try {
            const data = await axios.get("/private/getRewardByCategory/5");
            setTableData(data?.data?.data);
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.loog(error)
        }
    }
    useEffect(() => {
        handleRespose()
    }, [])

    const changeStatus = async (id, status) => {
        setIsLoading(true)
        try {
            const formData = {
                status: status
            }
            const data = await axios.put(`private/changeRewardStatus/${id}`, formData);
            handleRespose()
            toast.success(data?.data?.message)
            setIsLoading(false)
        } catch (error) {
            if (error?.response?.data?.status == 400) {
                toast.error(error.response?.data?.message)
                setTimeout(() => {
                    window.location.reload()
                }, 1000)
            }
            setIsLoading(false)
        }
    }

    const deleteReward = async (id) => {
        setIsLoading(true)
        try {
            const data = await axios.delete(`private/deleteReward/${id}`);
            handleRespose()
            setIsLoading(false)
            toast.success(data?.data?.message)
        } catch (error) {

            setIsLoading(false)
        }
    }
    return (
        <div>
            <div className="referralcont">
                <div className="flex-align-center justify-content-between">
                    <div className='flex-align-center'>
                        <h4 className="font-dark-green mb-0 fs-22">Trip Reward</h4>
                    </div>
                    <div>
                        {(userData.userType === "admin" || userData.userType == "subAdmin" && permissions?.add == true) &&
                            <button className='flex-align-center addbtn' onClick={() => history.push("/other-reward/create-trip-reward")}><FaPlus className='me-2' /> Add Trip Reward</button>
                        }
                    </div>
                </div>

                {tableData?.length > 0 ? (<>
                    <Paper className="mt-3" style={{ position: "relative", height: "60vh", overflowY: "scroll" }}>
                        <>
                            <TableContainer className={classes.tableMainContainer}>
                                <Table>
                                    <TableHead>
                                        <TableRow className={classes.tableHeadingRow}>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                S.No
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Trip Details
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Status
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Available Qty
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Reward Expiry
                                            </TableCell>
                                            <TableCell className={[classes.tablseHeadingCell]}>
                                                Details
                                            </TableCell>
                                            <TableCell className={[classes.tablseHeadingCell]}>
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableData?.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell className={classes.textMiddle}>
                                                    <div>{index + 1}</div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <div>{item?.pickupLocation}-{item?.destination}</div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <div className='d-flex align-items-center justify-content-center  ms-4'>
                                                        {(userData.userType === "admin" || userData.userType == "subAdmin" && permissions?.edit == true) ?
                                                            <CustomizedSwitches item={item} changeStatus={changeStatus} /> : "N/A"
                                                        }
                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <div>{item?.rewardAmount}</div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <DateFormater item={item?.rewardExpireDate} />
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <div className='historybtn' onClick={() => history.push(`/other-reward/history/${item?._id}`, { state: item })}><BsEye /> history</div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>

                                                    <div className='d-flex align-items-center justify-content-center'>
                                                        {(userData.userType === "admin" || userData.userType == "subAdmin" && permissions?.edit == true) ? <div
                                                            className='me-3 pointer  flex-align-center' onClick={() => history.push(`/other-reward/edit-trip-reward/${item?._id}`)}>
                                                            <BsPencil className='actionicon'
                                                            /><span className='underline p-1 fs-16  font-g2'>Edit</span>
                                                        </div>
                                                            : "N/A "
                                                        }
                                                        {(userData.userType === "admin" || userData.userType == "subAdmin" && permissions?.delete == true) ? <div className='pointer  flex-align-center'>
                                                            <FaRegTrashAlt className='deleteicon' onClick={() => deleteReward(item?._id)} /> <spna
                                                                className="font-b2 fs-16 underline p-1">Delete</spna>
                                                        </div>
                                                            : "N/A"
                                                        }
                                                    </div>



                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    </Paper>
                </>) : (
                    <div className="emptyTable mt-3">
                        No Data Found
                    </div>
                )}
            </div>
            {isLoading && <Overlay />}
        </div>
    )
}
