import React, { useEffect, useState ,useRef} from 'react'
import { DashHeading } from '../AccountManagement/AccountManagementElements'
import { IoChevronBack } from 'react-icons/io5'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Field, Form, Formik } from 'formik';
import Input from '../../components/Input';
import { Modal } from '../../components/Modal';
import { MdOutlineClose } from 'react-icons/md';
import CreateLevelVoucher from '../../components/levelComponent/voucher/create-Level-vouchers';
import CreateLevelInAppReward from '../../components/levelComponent/inAppReward/create-Level-appReward';
import CreateLevelAuditToken from '../../components/levelComponent/auditToken/create-Level-AuditToken';
import CreateLevelPremium from '../../components/levelComponent/premium/create-Level-premium';
import CreateLevelTrip from '../../components/levelComponent/trip/create-Level-trip';
import { addLevelValidation } from '../../utils/validators';
import axios from "../../axios";
import { toast } from 'react-toastify';
import Overlay from '../../components/Overlay';
import { FaCaretDown } from 'react-icons/fa';

export default function AddLevel() {
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [rewardType, setRewardType] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [compData, setCompData] = useState({})
    const handleDataFromChild = (childData) => {
        setCompData(childData);
    };

    const [categoryName, setCategoryName] = useState("1")
    const [levelData, setLevelData] = useState({
        level: "",
        targetedHealth: "",
        targetedWealth: "",
        categoryName: "",
    })

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const ref = useRef(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (item,formikBag) => {
        getModalValue(item.value);
        formikBag.setFieldValue("categoryName", item.value);
        setCategoryName(item.value);
        setSelectedOption(item.level);
        setIsOpen(false);
    };

    const handleOutsideClick = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);
    const RewardType = [
        { level: "Vouchers", value: "1" },
        { level: "In App Rewards", value: "2" },
        { level: "Audit Token", value: "3" },
        { level: "Premium Rewards", value: "4" },
        { level: "Trip Rewards", value: "5" }
    ]

    const getModalValue = (value) => {
        setRewardType(value)
        setOpenModal(true)
    }
    const handleAddLevel = async (value) => {
        setIsLoading(true);
        try {
            let formData = {
                level: value.level,
                targetedHealth: value.targetedHealth,
                targetedWealth: value.targetedWealth,
                categoryName: value.categoryName,
                Reward: { ...compData }
            }
            const data = await axios.post("/private/createlevel", formData);

            toast.success(data?.data?.message);
            history.push("/level-management")
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error(error.response.data?.errors[0].msg)
        }
    }

    return (
        <>
            <div className='hewe-cont addlevel'>
                <div className="flex-align-center">
                    <IoChevronBack
                        size={32}
                        className="back-color"
                        onClick={() =>
                            history.push("/level-management")
                        }
                    />
                    <DashHeading>Add Level</DashHeading>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={levelData}
                    validate={addLevelValidation}
                    validateOnChange
                    onSubmit={(values) => handleAddLevel(values)}
                >
                    {(formikBag) => {
                        return (
                            <Form className="p-3">
                                <div className="bg-white p-4">
                                    <div className="row">
                                        <div className="col-xxl-3 col-xl-4 col-sm-6" style={{ padding: "1rem" }}>
                                            <label>Level</label>
                                            <div className="form-controlcont">
                                                <Field name="level w-100">
                                                    {({ field }) => (
                                                        <Input
                                                            {...field}
                                                            type="number"
                                                             min="0"
                                                            value={formikBag.values.level}
                                                            onChange={(e) => {
                                                                formikBag.setFieldValue(
                                                                    "level",
                                                                    e.target.value
                                                                );
                                                            }}

                                                            className="form-control"
                                                            placeholder="Enter Level"

                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                            <p className='error'>{
                                                formikBag.touched.level &&
                                                    formikBag.errors.level
                                                    ? formikBag.errors.level
                                                    : null
                                            }</p>
                                        </div>
                                        <div className='col-xxl-1 d-xxl-block d-none'></div>
                                        <div className="col-xxl-3 col-xl-4 col-sm-6" style={{ padding: "1rem" }}>
                                            <label>Wealth Goal (in HeXP)</label>
                                            <div className="form-controlcont">
                                                <Field name="targetedWealth w-100">
                                                    {({ field }) => (
                                                        <Input
                                                            {...field}
                                                            type="number"
                                                             min="0"
                                                            value={formikBag.values.targetedWealth}
                                                            onChange={(e) => {
                                                                formikBag.setFieldValue(
                                                                    "targetedWealth",
                                                                    e.target.value
                                                                );
                                                            }}

                                                            className="form-control"
                                                            placeholder="Enter Wealth Goal "

                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                            <p className='error'>
                                                {formikBag.touched.targetedWealth &&
                                                    formikBag.errors.targetedWealth
                                                    ? formikBag.errors.targetedWealth
                                                    : null
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xxl-3 col-xl-4 col-sm-6" style={{ padding: "1rem" }}>
                                            <label>Health Goal (in Miles)</label>
                                            <div className="form-controlcont">
                                                <Field name="targetedHealth w-100">
                                                    {({ field }) => (
                                                        <Input
                                                            {...field}
                                                            type="number"
                                                             min="0"
                                                            value={formikBag.values.targetedHealth}
                                                            onChange={(e) => {
                                                                formikBag.setFieldValue(
                                                                    "targetedHealth",
                                                                    e.target.value
                                                                );
                                                            }}

                                                            className="form-control"
                                                            placeholder="Enter Health Goal "

                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                            <p className='error'>{
                                                formikBag.touched.targetedHealth &&
                                                    formikBag.errors.targetedHealth
                                                    ? formikBag.errors.targetedHealth
                                                    : null
                                            }</p>
                                        </div>
                                        <div className='col-xxl-1 d-xxl-block d-none'></div>
                                        <div className="col-xxl-3 col-xl-4 col-sm-6" style={{ padding: "1rem" }}>
                                            <label>Reward Type</label>
                                            <div className="form-controlcont" ref={ref}>
                                                <div className="redwardTypeselect position-relative" >
                                                    <div className='d-flex justify-content-between align-items-center'  onClick={toggleDropdown}>
                                                    <div
                                                        className="select-button  pointer"
                                                    >
                                                        {selectedOption || 'Select Type'}
                                                    </div>
                                                    <FaCaretDown className='text-black' />
                                                    </div>
                                                    {isOpen && (
                                                        <div className="options-container ">
                                                            {RewardType?.map((item) => (
                                                                <div >
                                                                <div
                                                                    key={item.value}
                                                                    className="option-button pointer"
                                                                    onClick={() => handleOptionClick(item,formikBag)}
                                                                >
                                                                    {item.level}
                                                                </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>


                                            <p className='error'>{
                                                formikBag.touched.categoryName &&
                                                    formikBag.errors.categoryName
                                                    ? formikBag.errors.categoryName
                                                    : null
                                            }</p>
                                        </div>
                                    </div>

                                    <div className="d-flex  mt-3" >
                                        <button
                                            type="submit"
                                            className="savebtn ms-1  px-sm-5 px-4 py-2"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
            <Modal
                maxWidth="lg"
                width="100%"
                RoundedCorners={true}
                isOpen={openModal}
                onClose={() => {
                    setOpenModal(false);
                    setRewardType();
                }}
                backgroundModal={false}
                backgroundModalContent={false}
                title={
                    <div className="p-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <p className="ps-2 main-heading">
                                    Add{" "}
                                    {categoryName === "1"
                                        ? "Voucher"
                                        : categoryName === "2"
                                            ? "In App Reward"
                                            : categoryName === "3"
                                                ? "Audit Reward"
                                                : categoryName === "4"
                                                    ? "Premium Reward"
                                                    : categoryName === "5"
                                                        ? "Trip Reward"
                                                        : ""}
                                </p>
                            </div>
                            <MdOutlineClose
                                style={{
                                    fontWeight: 600,
                                    fontSize: "22px",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setOpenModal(false);
                                    setRewardType(0)
                                }}
                            />
                        </div>

                    </div>
                }
                content={
                    <>
                        {
                            rewardType == "1" ? <CreateLevelVoucher rewardValue={handleDataFromChild} childData={levelData} setOpenModal={setOpenModal} /> : rewardType == "2" ? <CreateLevelInAppReward rewardValue={handleDataFromChild} childData={levelData} setOpenModal={setOpenModal} /> : rewardType == "3" ? <CreateLevelAuditToken rewardValue={handleDataFromChild} childData={levelData} setOpenModal={setOpenModal} /> : rewardType == "4" ? <CreateLevelPremium rewardValue={handleDataFromChild} childData={levelData} setOpenModal={setOpenModal} /> : rewardType == "5" ? <CreateLevelTrip rewardValue={handleDataFromChild} childData={levelData} setOpenModal={setOpenModal} /> : null
                        }
                    </>
                }
            />
            {isLoading && <Overlay />}

        </>

    )
}
