import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import "./style.scss"
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "../../axios";
import { get } from "lodash";
import { Filter } from '../../components/svg';
import { FaAngleDown, FaChevronRight } from 'react-icons/fa';
import "react-datepicker/dist/react-datepicker.css";
import { IoChevronBack, IoEarth } from 'react-icons/io5';
import { FiArrowLeft } from "react-icons/fi";
import { FaArrowRight } from "react-icons/fa";
import { PaginationItem } from "@material-ui/lab";
import { Pagination, Stack } from "@mui/material";
import realMoney from "../../images/hewe/realmoney.svg";
import HeweCoin from "../../images/hewe/hewecoins.svg";
import { GrAndroid, GrApple } from 'react-icons/gr';
import Overlay from '../Overlay';
const useStyles = makeStyles((theme) => ({
    paperTableHeight: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        border: "1px solid #D9E3D9",
        overflowX: "scroll",
    },
    tableMainContainer: {
        overflowX: "scroll",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
    },
    tableHeadingRow: {
        // backgroundColor: "#21AFE6",
    },
    tablseHeadingCell: {
        padding: "0.8rem 20px !important",
        textAlign: "center",
        fontSize: "14px",
        color: "#264503",
        fontWeight: 600,
        borderBottom: "1px solid #D9E3D9",
        whiteSpace: "nowrap",
    },
    textMiddle: {
        padding: "0.6rem 20px !important",
        verticalAlign: "middle !important",
        textAlign: "center",
        fontSize: "14px",
        color: "black",
    },
    tablePaginationStyle: {
        borderTop: "1px solid #0000001a",
        borderRadius: "0rem 0rem 0.4rem 0.4rem",
        overflowY: "hidden",
    },
    sticky: {
        position: "sticky",
        left: "0"
    }
}));
function Purchase() {
    const history = useHistory()
    const { state } = useLocation();
    const param = useParams();
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, SetTableData] = useState([]);
    // For Pagination
    const [page, setPage] = useState(1);
    // For Search
    const [limit, setLimit] = useState(10);
    const [paginationData, setPaginationData] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(10);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        window.scrollTo(0, 0);
    };

    // -----------filter--------------------------------
    const [filter, setFilterValue] = useState({
        purchaseType: [],
        currentMonth: "0",
        lastWeek: "0",
        startDate: "",
        endDate: "",
    })

    const [selectedOption, setSelectedOption] = useState(0);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);


    const getData = async () => {
        setIsLoading(true);
        try {
            const formData = { ...filter };
            const { data } = await axios.post(
                `/private/purchaseHistory/${param.id}?page=${page}&limit=${limit}`,
                formData
            );
            setIsDropdownOpen(false);
            setPaginationData(data?.data);
            SetTableData(data?.data?.docs);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getData();
    }, [page]);

    // Handle filter selection
    const handleSelectChange = (value) => {
        setFilterValue((prevFilter) => ({
            ...prevFilter,
            currentMonth: value === 1 ? "1" : "0",
            lastWeek: value === 2 ? "1" : "0",
            startDate: value === 3 ? prevFilter.startDate : "",
            endDate: value === 3 ? prevFilter.endDate : "",
        }));
        setSelectedOption(value);
    };

    // Handle date selection
    const handleDateChange = (date, type) => {
        setFilterValue((prevFilter) => ({
            ...prevFilter,
            startDate: type === 1 ? date : prevFilter.startDate,
            endDate: type === 2 ? date : prevFilter.endDate,
        }));
    };

    // Toggle dropdown visibility
    const toggleDropdown = () => {
        setIsDropdownOpen((prevState) => !prevState);
    };

    // Reset filter
    const cancelFilter = () => {
        setFilterValue({
            purchaseType: [],
            currentMonth: "0",
            lastWeek: "0",
            startDate: "",
            endDate: "",
        });
        setSelectedOption(0);
        setIsDropdownOpen(false);
    };

    // Handle checkbox selection
    const handleCheckboxChange = (e, value) => {
        setFilterValue((prevState) => {
            const updatedPurchaseType = e.target.checked
                ? [...prevState.purchaseType, value]
                : prevState.purchaseType.filter((item) => item !== value);

            return { ...prevState, purchaseType: updatedPurchaseType };
        });
    };
    //    ---------filter end--------------------------------

    // out side click handle
    const dropdownRef = useRef(null);
    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className='hewe-cont'>

            <div className="flex-align-center justify-content-between mb-4 w-100">
                <div className="flex-align-center ">
                    <IoChevronBack
                        size={32}
                        className="back-color"
                        onClick={() =>
                            history.goBack({
                                state: { page: state?.state?.page },
                            })
                        }
                    />
                    <h3 className='font-dark-green mb-0 fs-22 main-heading'>Total Spending</h3>
                </div>
                <div className='d-flex align-items-center'>
                    <div className="custom-dropdown-container" ref={dropdownRef}>
                        <button className='filtertbn' onClick={toggleDropdown}>
                            <div className='d-flex align-items-center'><Filter /> <p className="ms-1 font-italic fw-400" style={{ color: "#464646c1" }}>Filter</p></div>   <span><FaAngleDown color='gray' /></span>
                        </button>

                        {isDropdownOpen && (
                            <div className="custom-dropdown">
                                <div>
                                </div>
                                <div
                                    className={`dropdown-item ${selectedOption === 1 ? "selected" : ""}`}
                                    onClick={() => handleSelectChange(1)}
                                >
                                    Current Month
                                </div>
                                <div
                                    className={`dropdown-item ${selectedOption === 2 ? "selected" : ""}`}
                                    onClick={() => handleSelectChange(2)}
                                >
                                    Last Week
                                </div>
                                <div
                                    className={`dropdown-item ${selectedOption === 3 ? "selected" : ""}`}
                                    onClick={() => handleSelectChange(3)}
                                >
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <p>By Date</p><FaChevronRight color='black' />
                                    </div>
                                </div>

                                {selectedOption === 3 && (
                                    <div className='datepcikers'>
                                        <div>
                                            <label>Start Date</label>
                                            <div>
                                                <input
                                                    type="date"
                                                    onChange={(e) => handleDateChange(e.target.value, 1)}
                                                    max={new Date().toISOString().split("T")[0]}
                                                    className="datepic"
                                                />
                                            </div>
                                        </div>
                                        <div className='mt-3 mb-3'>
                                            <label>End Date</label>
                                            <div>
                                                <input
                                                    type="date"
                                                    onChange={(e) => handleDateChange(e.target.value, 2)}
                                                    max={new Date().toISOString().split("T")[0]}
                                                    min={filter?.startDate}
                                                    className='datepic'
                                                />
                                            </div>
                                        </div>
                                        <div className="dropdown-actions d-flex mt-2">
                                            <button className='cancle  me-3' onClick={cancelFilter}>Cancel</button>
                                            <button className='apply bg-green px-4' onClick={getData}>Apply</button>
                                        </div>
                                    </div>
                                )}

                                {selectedOption != 3 && (
                                    <div className="dropdown-actions d-flex mt-2">
                                        <button className='cancle  me-3' onClick={cancelFilter}>Cancel</button>
                                        <button className='apply bg-green px-3' onClick={getData}>Apply</button>
                                    </div>
                                )}

                            </div>
                        )}
                    </div>
                </div>
            </div>
            {tableData?.length > 0 ? (<>
                <Paper className={classes.paperTableHeight}>
                    <>
                        <TableContainer
                            className={classes.tableMainContainer}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow className={classes.tableHeadingRow}>
                                        <TableCell className={classes.tablseHeadingCell}>
                                            S.No
                                        </TableCell>
                                        <TableCell className={classes.tablseHeadingCell}>
                                            Buy Date & Time
                                        </TableCell>
                                        <TableCell className={[classes.tablseHeadingCell]}>
                                            Package ID
                                        </TableCell>
                                        <TableCell className={classes.tablseHeadingCell}>
                                            Transaction ID
                                        </TableCell>
                                        <TableCell className={[classes.tablseHeadingCell]}>
                                            Purchase Type
                                        </TableCell>
                                        <TableCell className={[classes.tablseHeadingCell]}>
                                            Amount
                                        </TableCell>
                                        <TableCell className={[classes.tablseHeadingCell]}>
                                            Device ID
                                        </TableCell>
                                        <TableCell className={[classes.tablseHeadingCell]}>
                                            Device Type
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableData.map((category, index) => (
                                        <TableRow key={category._id}>
                                            <TableCell className={classes.textMiddle}>
                                                <div>{index + 1 + rowsPerPage * (page - 1)}</div>
                                            </TableCell>
                                            <TableCell className={classes.textMiddle}>
                                                <div>
                                                    {get(category, 'createdAt', 'N/A')
                                                        ? `${new Date(get(category, 'createdAt')).toLocaleDateString("en-US", {
                                                            year: 'numeric',
                                                            month: '2-digit',
                                                            day: '2-digit'
                                                        })} at ${new Date(get(category, 'createdAt')).toLocaleTimeString("en-US", {
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                            hour12: false
                                                        })}`
                                                        : 'N/A'}
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.textMiddle}>
                                                <div>{get(category, "skuId", "N/A")}</div>
                                            </TableCell>
                                            <TableCell className={classes.textMiddle}>
                                                <span className="truncate" >
                                                    <Tooltip
                                                        title={get(category, "transectionId", "N/A")}
                                                        arrow
                                                    >
                                                        <span className='d-block'>
                                                            {get(category, "transectionId", "N/A")}
                                                        </span>
                                                    </Tooltip>
                                                </span>
                                            </TableCell>
                                            <TableCell className={classes.textMiddle}>
                                                <div>{category?.purchaseType == "1" ? <div >
                                                    <img src={realMoney} />
                                                </div> : <div >
                                                    <img src={HeweCoin} />
                                                </div>}</div>
                                            </TableCell>
                                            <TableCell className={classes.textMiddle}>
                                                <div>
                                                    {get(category, "price_currency_code", "N/A") == "Hewe" ? <img src={HeweCoin} /> : get(category, "price_currency_code", "N/A")}
                                                    <span className='ms-1'>
                                                        {
                                                            category?.price?.$numberDecimal
                                                                ? Number(category?.price?.$numberDecimal)
                                                                : (typeof category?.price === 'number' ? category?.price : "N/A")
                                                        }
                                                    </span>

                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.textMiddle}>
                                                <div>{get(category, "deviceId", "N/A")}</div>
                                            </TableCell>
                                            <TableCell className={classes.textMiddle}>
                                                {category?.deviceType === "3" ? (
                                                    <GrApple
                                                        style={{ fontSize: "20px", color: "#424245" }}
                                                    />
                                                ) : category?.deviceType === "2" ? (
                                                    <GrAndroid
                                                        style={{ fontSize: "20px", color: "#79c257" }}
                                                    />
                                                ) : (
                                                    <IoEarth />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Stack spacing={2} className="flex-align-center justify-content-center w-100 py-3">
                            <Pagination
                                count={Math.ceil(paginationData?.totalDocs / rowsPerPage)}
                                page={page}
                                onChange={(event, value) => handleChangePage(event, value)}
                                renderItem={(item) => (
                                    <PaginationItem
                                        slots={{ previous: FiArrowLeft, next: FaArrowRight }}
                                        {...item}
                                    />
                                )}
                            />
                        </Stack>
                    </>
                </Paper>
            </>) : (
                <div className="emptyTable mt-3">
                    No Data Found
                </div>
            )}
            {isLoading && <Overlay />}
        </div>

    )
}

export default Purchase