import React from "react";

import * as FaIcons from "react-icons/fa";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as BiIcons from "react-icons/bi";
import * as BsIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";
import * as AiIcons from "react-icons/ai";
import * as mdIcons from "react-icons/md";
import { HiOutlineDeviceMobile } from "react-icons/hi";
import { AccountSvg, DailyAchievement, HomeIcon, LevelSvg, RewardSvg, SubAdminSvg, NotificationSvg, CmsIcon, TicketMngtSvg, Claim } from "../svg";
import Daily_Achievement from "../../pages/DailyAchievement/Daily-Achievement";
import icon1 from "../../images/hewe/question-sign.svg"

export const SidebarData = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <HomeIcon />,
  },
  {
    title: "Account Management",
    path: "/accountManagement",
    icon: <AccountSvg />,
  },
  {
    title: "Reward Management",
    path: "/refer-and-earn",
    icon: <RewardSvg />,
    iconClosed: <RiIcons.RiArrowDownSFill size={22} />,
    iconOpened: <RiIcons.RiArrowUpSFill size={22} />,
    subNav: [
      {
        title: "Refer & Earn",
        path: "/refer-and-earn",
        icon: <BsIcons.BsCardList />,
      },
      {
        title: "Daily Booster",
        path: "/dailyBooster",
        icon: <BsIcons.BsCardList />,
      },
      {
        title: "Mile Reward",
        path: "/miles-reward",
        icon: <BsIcons.BsCardList />,
      },
      {
        title: "Conversion Rate",
        path: "/rate",
        icon: <BsIcons.BsCardList />,
      },
      {
        title: "Spin Reward",
        path: "/other-reward",
        icon: <BsIcons.BsCardList />,
      },
    ],
  },
  // {
  //   title: "Level Management",
  //   path: "/level-management",
  //   icon: <LevelSvg />,
  // },

  {
    title: "Claim Management",
    path: "/claim-management",
    icon: <Claim />,
    iconClosed: <RiIcons.RiArrowDownSFill size={22} />,
    iconOpened: <RiIcons.RiArrowUpSFill size={22} />,
    subNav: [
      {
        title: "Claim Management",
        path: "/claim-management",
        icon: <Claim />,
      },
      {
        title: "Hewe Claim",
        path: "/hewe-claim",
        icon: <BsIcons.BsCardList />,
      },

    ],
  },
  // {
  //   title: "Daily Achievement",
  //   path: "/daily-achievement",
  //   icon: <DailyAchievement />,
  // },
  {
    title: "Sub Admin Management",
    path: "/sub-admin-management",
    icon: <SubAdminSvg />,
  },
  {
    title: "CMS Management",
    path: "/privacy-policy",
    icon: <CmsIcon />,
    iconClosed: <RiIcons.RiArrowDownSFill size={22} />,
    iconOpened: <RiIcons.RiArrowUpSFill size={22} />,
    subNav: [
      {
        title: "Privacy Policy",
        path: "/privacy-policy",
        icon: <BsIcons.BsCardList />,
      },
      {
        title: "About Us",
        path: "/about-us",
        icon: <BsIcons.BsCardList />,
      },
      {
        title: "Contact Us",
        path: "/contact-us",
        icon: <BsIcons.BsCardList />,
      },
      {
        title: "Terms & Conditions",
        path: "/terms-and-conditions",
        icon: <BsIcons.BsCardList />,
      },
      {
        title: "FAQs",
        path: "/faq",
        icon: <BsIcons.BsCardList />,
      },
    ],
  },

  {
    title: "Notification Management",
    path: "/notification-management",
    icon: <NotificationSvg />,
  },
  // {
  //   title: "Ticket Management",
  //   path: "/ticket-management",
  //   icon: <TicketMngtSvg />,
  // },
  // {
  //   title: "Query Management",
  //   path: "/query-management",
  //   icon:<img src={icon1}/>
  // },
  {
    title: "Ticket Management",
    path: "/ticket-management",
    icon: <TicketMngtSvg />,
    iconClosed: <RiIcons.RiArrowDownSFill size={22} />,
    iconOpened: <RiIcons.RiArrowUpSFill size={22} />,
    subNav: [
      {
        title: "Ticket Management",
        path: "/ticket-management",
        icon: <Claim />,
      },
      {
        title: "Add Query",
        path: "/query-management",
        icon: <BsIcons.BsCardList />,
      },

    ],
  },
];

export const profileSidebarData = [
  {
    title: "My Profile",
    path: "/profile",
    icon: <FaIcons.FaUser />,
  },
  {
    title: "Manage Devices",
    path: "/manageDevice",
    icon: <HiOutlineDeviceMobile />,
  },
  {
    title: "Change Password",
    path: "/changedPassword",
    icon: <FaIcons.FaLock />,
  },
];
