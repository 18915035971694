import React, { useEffect, useState } from 'react'
import { DashHeading } from '../AccountManagement/AccountManagementElements'
import { connect } from "react-redux";
import { withRouter, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import './style.scss'
import axios from "../../axios";
import Overlay from "../../components/Overlay";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RiDeleteBinLine, RiEditBoxFill } from 'react-icons/ri'
import { toast } from 'react-toastify'
import { FaEdit } from 'react-icons/fa';
function Faqs({ userData }) {
  const parsedUserData = JSON.parse(localStorage.getItem("userData")) || JSON.parse(sessionStorage.getItem("userData"));
  let modules = parsedUserData?.data?.modules;
  const permissions = modules?.find((item) => item.moduleName === "CMS Management")?.permissions;
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const [data, setData] = useState()
  const getFaq = async () => {
    setIsLoading(true)
    try {
      const { data } = await axios.get("/private/getFaq")
      setData(data.data)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }



  const deleteFaq = async (id) => {
    setIsLoading(true)
    try {
      const { data } = await axios.delete(`/private/deleteFaq/${id}`)
      getFaq()
      setIsLoading(false)
      toast.success(data?.data?.message);
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }
  useEffect(() => {
    getFaq()
  }, [])



  function formateData(data) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(data, 'text/html');
    const textContent = doc.body.textContent || '';
    return textContent.trim();
  }
  return (
    <>
      <div className='hewe-cont'>
        <div className="flex-align-center justify-content-between mb-5">
          <div className='flex-align-center'>
            <div className="dott me-3"></div>
            <DashHeading>FAQs</DashHeading>
          </div>
          <div>
            {(userData.userType === "admin" || userData.userType == "subAdmin" && permissions?.add == true) &&
              <button className='savebtn' onClick={() => history.push("/faq/create-faq")}>Create FAQ</button>
            }
          </div>
        </div>
        <div>
          <div>
            {
              data?.map((item, index) => {
                return (
                  <Accordion className='mb-3'>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography component="span" className='fw-700'>{index + 1}{"."} &nbsp;{item?.faq?.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {formateData(item?.faq?.answer)}
                      <div className='d-flex align-items-center justify-content-end'>
                        {(userData.userType === "admin" || userData.userType == "subAdmin" && permissions?.edit == true) &&
                          <div className='me-2'><FaEdit size={24} onClick={() => history.push("/edit-Faq", { state: item })} /></div>
                        }
                        {(userData.userType === "admin" || userData.userType == "subAdmin" && permissions?.delete == true) &&
                          <div className='transbtn' ><RiDeleteBinLine size={22} color='red' onClick={() => deleteFaq(item?.id)} /></div>
                        }
                      </div>
                    </AccordionDetails>
                  </Accordion>
                )
              })
            }
          </div>
        </div>
      </div>
      {isLoading && <Overlay />}
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
    sidebar: state.sidebar,
  };
};

export default connect(
  mapStateToProps,
  // mapDispatchToProps
)(withRouter(Faqs));
