import React, { useEffect, useState } from "react";
import "./style.scss";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter, } from "react-router-dom/cjs/react-router-dom.min";
import AllReward from "../../components/reward/AllReward/AllReward";
import Voucher from "../../components/reward/voucher/Vouchers";
import InAppReward from "../../components/reward/inAppReward/appReward";
import AuditToken from "../../components/reward/auditToken/AuditToken";
import Premium from "../../components/reward/premium/Premium";
import Trip from "../../components/reward/trip/trip";
import { connect } from "react-redux";
function OtherReward({ userData }) {
    const spinReward = sessionStorage.getItem("spinReward");
    const [stateValue, setStateValue] = useState(spinReward ? parseInt(spinReward) : 0);
    useEffect(() => {
        const savedValue = sessionStorage.getItem('spinReward');
        if (savedValue) {
            setStateValue(parseInt(savedValue));
        }
    }, []);

    const handleCardClick = (value) => {
        setStateValue(value);
        sessionStorage.setItem('spinReward', value);
    };

    const cardItems = [
        { label: 'All Reward', value: 0 },
        // { label: 'Vouchers', value: 1 },
        { label: 'In App', value: 2 },
        { label: 'Audit Token', value: 3 },
        { label: 'Premium', value: 4 },
        { label: 'Trip', value: 5 }
    ];

    return (
        <>
            <div className="hewe-cont">
                <div className="referralcont">
                    <div className="flex-align-center">
                        <div className="dott me-3"></div>
                        <h3 className="font-dark-green mb-0 fs-22 main-heading">Manage Reward Category</h3>
                    </div>
                    <div className="rewardcardcont">
                        <div className="row flex-wrap">
                            {cardItems.map((item, index) => (
                                <div key={index} className="col-xl-2 col-lg-3 col-md-4 col-6 mb-2">
                                    <div
                                        className="cardcont"
                                        onClick={() => handleCardClick(item.value)}
                                        style={
                                            stateValue === item.value
                                                ? { backgroundColor: '#599265E0', color: 'white' }
                                                : { backgroundColor: '#EFEFEF', color: "#212121" }
                                        }
                                    >
                                        <h4>{item.label}</h4>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {stateValue === 0 ? (
                        <AllReward userData={userData} />
                    ) : stateValue === 1 ? (
                        <Voucher userData={userData} />
                    ) : stateValue === 2 ? (
                        <InAppReward userData={userData} />
                    ) : stateValue === 3 ? (
                        <AuditToken userData={userData} />
                    ) : stateValue === 4 ? (
                        <Premium userData={userData} />
                    ) : stateValue === 5 ? (
                        <Trip userData={userData} />
                    ) : null
                    }
                </div>
            </div>
        </>
    );
}



const mapStateToProps = (state) => {
    return {
        userData: state.userData,
        locationData: state.locations,
        defaultState: state.defaultState,
        sidebar: state.sidebar,
    };
};

export default connect(
    mapStateToProps,
    // mapDispatchToProps
)(withRouter(OtherReward));
