import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import axios from "../../axios"
import Overlay from '../../components/Overlay';
import "./style.scss"
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    makeStyles,
} from "@material-ui/core";
import { FiArrowLeft } from "react-icons/fi";
import { FaArrowRight } from "react-icons/fa";
import { PaginationItem } from "@material-ui/lab";
import { Pagination, Stack } from "@mui/material";
import { IoChevronBack } from 'react-icons/io5';
import { DateFormater } from '../../components/dateFormater';
import { FilterSvg } from '../../components/svg';
const useStyles = makeStyles((theme) => ({
    paperTableHeight: {
        width: "95%",
        marginLeft: "2rem",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        border: "1px solid #D9E3D9",
        overflowX: "scroll",
    },
    tableMainContainer: {
        overflowX: "scroll",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
    },
    tableHeadingRow: {
        // backgroundColor: "#21AFE6",
    },
    tablseHeadingCell: {
        padding: "0.8rem 20px !important",
        textAlign: "center",
        fontSize: "14px",
        color: "#264503",
        fontWeight: 600,
        borderBottom: "1px solid #D9E3D9",
        whiteSpace: "nowrap",
    },
    textMiddle: {
        padding: "0.6rem 20px !important",
        verticalAlign: "middle !important",
        textAlign: "center",
        fontSize: "14px",
        color: "#00000080",
    },
    tablePaginationStyle: {
        borderTop: "1px solid #0000001a",
        borderRadius: "0rem 0rem 0.4rem 0.4rem",
        overflowY: "hidden",
    },
    button: {
        textAlign: "center",
        backgroundColor: "#88AE40",
        padding: "7px 30px",
        color: "white",
        width: "250px",
        border: "none",
        borderRadius: "4px"
    },
}));
export default function SubAdminHistory() {
    const [stateData, setStateData] = useState()
    const param = useParams();
    const { state } = useLocation();
    useEffect(() => {
        setStateData(state?.state)
    }, [])
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    const [tableData, setTableData] = useState();

    const [page, setPage] = useState(1);
    const [statePage, setStatePage] = useState(null)
    const [limit, setLimit] = useState(10);
    const [paginationData, setPaginationData] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        window.scrollTo(0, 0);
    };
    const history = useHistory();
    const handleResponse = async (id) => {
        setIsLoading(true);
        try {
            const data = await axios.get(`/private/getSubadminLogs/${param?.id}?startDate=${filter.startDate}&endDate=${filter.endDate}&page=${page}&limit=${limit}`, filter);
            setFilterOpen(false)
            setTableData(data?.data?.data?.docs);
            setPaginationData(data?.data?.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleResponse();
    }, [page]);
    const [filter, setFilterValue] = useState({
        startDate: "",
        endDate: "",
    })
    const [filterOpen, setFilterOpen] = React.useState(false)
    const handleDateChange = (event) => {
        const { name, value } = event.target;
        const formattedDate = new Date(value).toISOString();
        setFilterValue((prev) => ({
            ...prev,
            [name]: formattedDate,
        }));
    };

    const handleCancel = () => {
        setFilterValue({
            startDate: "",
            endDate: "",
        });
        setFilterOpen(!filterOpen)
    };
    const handleFilterOpen = () => {
        setFilterOpen(!filterOpen)
    }


    // out side click handle
    const dropdownRef = useRef(null);
    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setFilterOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (
        <div className="hewe-cont ">
            <div className="referralcont">
                <div className="flex-align-center justify-content-between">
                    <div className='flex-align-center'>
                        <IoChevronBack
                            size={40}
                            onClick={() =>
                                history.goBack()
                            }
                        />
                        <h3 className="font-dark-green mb-0 fs-22 main-heading">Action Feed</h3>
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-end position-relative mb-4' ref={dropdownRef}>
                    <div className='ms-4 pointer text-end' style={{ color: "#264623" }} onClick={handleFilterOpen}> <FilterSvg /> Advanced Filters</div>
                    {
                        filterOpen && (
                            <div className="filterBody1">
                                <div className="py-2">
                                    <div>
                                        <label className="pb-1 text-24363a  fw-600">Start Date</label>
                                    </div>
                                    <input
                                        type="date"
                                        name="startDate"
                                        color='#464646a4'
                                        max={new Date().toISOString().split('T')[0]}
                                        className="calender"
                                        value={filter.startDate ? new Date(filter.startDate).toISOString().split('T')[0] : ""}
                                        onChange={handleDateChange}
                                    />
                                </div>

                                <div>
                                    <div className="pb-1">
                                        <label className='text-24363a fw-600 '>End Date</label>
                                    </div>
                                    <input
                                        type="date"
                                        name="endDate"
                                        color='#464646a4'
                                        min={filter.startDate}
                                        max={new Date().toISOString().split('T')[0]}
                                        className="calender"
                                        value={filter.endDate ? new Date(filter.endDate).toISOString().split('T')[0] : ""}
                                        onChange={handleDateChange}
                                    />
                                </div>

                                <div className="mt-3 flex-align-center justify-content-end">
                                    <button
                                        type="button"
                                        className="canclebtn"
                                        onClick={handleCancel}

                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="savebtn ms-3"
                                        onClick={handleResponse}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        )}
                </div>

                {tableData?.length > 0 ? (<>
                    <Paper className="mt-4 ms-3">
                        <>
                            <TableContainer className={classes.tableMainContainer}>
                                <Table>
                                    <TableHead>
                                        <TableRow className={classes.tableHeadingRow}>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                S.No
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Action Performed
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Date / Time
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Status
                                            </TableCell>



                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableData?.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell className={classes.textMiddle}>
                                                    {index + 1 + rowsPerPage * (page - 1)}
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <div>{item?.url}</div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <DateFormater item={item?.createdAt} />
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <div>
                                                        {item?.status == "200" ? <div className='success'>Success</div> : item?.status == "201" ? <div className='success'>Success</div> : <div className='failed'>Failed</div>}
                                                    </div>
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Stack spacing={2} className="flex-align-center justify-content-center w-100">
                                <Pagination
                                    count={Math.ceil((paginationData?.totalDocs || 0) / rowsPerPage) || 1}
                                    page={page}
                                    onChange={handleChangePage}
                                    renderItem={(item) => (
                                        <PaginationItem
                                            slots={{ previous: FiArrowLeft, next: FaArrowRight }}
                                            {...item}
                                        />
                                    )}
                                />
                            </Stack>
                        </>
                    </Paper>
                </>) : (
                    <div className="emptyTable mt-3">
                        No Data Found
                    </div>
                )}
            </div>
            {isLoading && <Overlay />}
        </div>
    )
}
