import React, { useEffect, useState } from 'react'
import "./style.scss"
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import axios from "../../axios";
import { useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { get } from 'lodash';
import { IoChevronBack } from 'react-icons/io5';
import { FiArrowLeft } from "react-icons/fi";
import { FaArrowRight } from "react-icons/fa";
import { PaginationItem } from "@material-ui/lab";
import { Pagination, Stack } from "@mui/material";
import moment from 'moment/moment';
const useStyles = makeStyles((theme) => ({
  paperTableHeight: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    border: "1px solid #D9E3D9",
    overflowX: "scroll",
  },
  tableMainContainer: {
    overflowX: "scroll",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  tableHeadingRow: {
    // backgroundColor: "#21AFE6",
  },
  tablseHeadingCell: {
    padding: "0.8rem 20px !important",
    textAlign: "center",
    fontSize: "14px",
    color: "#264503",
    fontWeight: 600,
    borderBottom: "1px solid #D9E3D9",
    whiteSpace: "nowrap",
  },
  textMiddle: {
    padding: "0.6rem 20px !important",
    verticalAlign: "middle !important",
    textAlign: "center",
    fontSize: "14px",
    color: "black",
  },
  tablePaginationStyle: {
    borderTop: "1px solid #0000001a",
    borderRadius: "0rem 0rem 0.4rem 0.4rem",
    overflowY: "hidden",
  },
  sticky: {
    position: "sticky",
    left: "0"
  }
}));
function ViewReferral() {
  const classes = useStyles();
  const history = useHistory();
  const param = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, SetTableData] = useState([]);
  // For Pagination
  const [page, setPage] = useState(1);
  // For Search
  const [limit, setLimit] = useState(10);
  const [paginationData, setPaginationData] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    window.scrollTo(0, 0);
  };
  const getData = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `/private/userReferralRewardHistory/${param.id}?page=${page}&limit=${limit}`
      );
      setPaginationData(data?.data);
      SetTableData(data?.data?.docs);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [page]);

  return (
    <div className='hewe-cont'>
      <div className="flex-align-center mb-4 ">
        <IoChevronBack
          size={32}
          className="back-color"
          onClick={() =>
            history.goBack()
          }
        />
        <h3 className='font-dark-green mb-0 fs-22 main-heading'>Referral History</h3>
      </div>
      <h6 className='mt-3'>Total Number of Referral: {paginationData?.totalDocs}</h6>
      {
        tableData.length != 0 ? (
          <Paper className={classes.paperTableHeight}>
            <>
              <TableContainer
                className={classes.tableMainContainer}
              >
                <Table>
                  <TableHead>
                    <TableRow className={classes.tableHeadingRow}>
                      <TableCell className={classes.tablseHeadingCell}>
                        S.No
                      </TableCell>
                      <TableCell className={classes.tablseHeadingCell}>
                        Profile Name
                      </TableCell>
                      <TableCell className={classes.tablseHeadingCell}>
                        Hewe Coins
                      </TableCell>
                      <TableCell className={classes.tablseHeadingCell}>
                        Date
                      </TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData?.map((category, index) => (
                      <TableRow key={category._id}>
                        <TableCell className={classes.textMiddle}>
                          <div>{index + 1 + rowsPerPage * (page - 1)}</div>
                        </TableCell>
                        <TableCell className={classes.textMiddle}>
                          <div>{category?.referee?.profileName || category?.referrer?.profileName}</div>
                        </TableCell>
                        <TableCell className={classes.textMiddle}>
                          <div>{get(category, "HeweCoins", "N/A")}</div>
                        </TableCell>
                        <TableCell className={classes.textMiddle}>
                          <div>{moment(category?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Stack spacing={2} className="flex-align-center justify-content-center w-100 py-3">
                <Pagination
                  count={Math.ceil(paginationData?.totalDocs / rowsPerPage)}
                  page={page}
                  onChange={(event, value) => handleChangePage(event, value)}
                  renderItem={(item) => (
                    <PaginationItem
                      slots={{ previous: FiArrowLeft, next: FaArrowRight }}
                      {...item}
                    />
                  )}
                />
              </Stack>
            </>
          </Paper>
        ) : (
          <div className="emptyTable mt-3">
            No Data Found
          </div>
        )
      }

    </div>
  )
}

export default ViewReferral