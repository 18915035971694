import React, { useEffect, useRef, useState } from 'react'
import { DashHeading } from '../AccountManagement/AccountManagementElements'
import { useFormik } from "formik";
import * as Yup from "yup";
import { countryNameLists } from '../../components/countryArray';
import { IoChevronBack, IoEarth } from 'react-icons/io5'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { get } from "lodash";
import Select from "react-select";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import "./style.scss"
import { makeStyles } from "@material-ui/core/styles";
import axios from "../../axios"
import { FiArrowLeft } from "react-icons/fi";
import { FaArrowRight } from "react-icons/fa";
import { PaginationItem } from "@material-ui/lab";
import { Pagination, Stack } from "@mui/material";
import { FaChevronDown } from 'react-icons/fa'
import SearchBar from 'material-ui-search-bar';
import { FilterSvg } from '../../components/svg';
import { toast } from 'react-toastify';
import { GrAndroid, GrApple } from 'react-icons/gr';
import Overlay from '../../components/Overlay';
const useStyles = makeStyles((theme) => ({
  paperTableHeight: {
    width: "100%",
    minHeight: "50vh",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    border: "1px solid #D9E3D9",
    overflowX: "scroll",
  },
  tableMainContainer: {
    overflowX: "scroll",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  tableHeadingRow: {
    // backgroundColor: "#21AFE6",
  },
  tablseHeadingCell: {
    padding: "20px 0px",
    fontSize: "14px",
    color: "#264503",
    fontWeight: 600,
    borderBottom: "1px solid #D9E3D9",
    whiteSpace: "nowrap",
  },
  textMiddle: {
    padding: "0.6rem 20px !important",
    verticalAlign: "middle !important",
    textAlign: "center",
    fontSize: "14px",
    color: "black",
  },
  tablePaginationStyle: {
    borderTop: "1px solid #0000001a",
    borderRadius: "0rem 0rem 0.4rem 0.4rem",
    overflowY: "hidden",
  },
  sticky: {
    position: "sticky",
    left: "0"
  }
}));
function AddNotification() {
  const classes = useStyles();
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false);
  const [selectOpen, setSelectOpen] = React.useState(false)
  const [filterOpen, setFilterOpen] = React.useState(false)
  const selectClick = () => {
    setSelectOpen(!selectOpen)
  }
  const [tableData, SetTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [paginationData, setPaginationData] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    window.scrollTo(0, 0);
  };

  const handleFilterOpen = () => {
    setFilterOpen(!filterOpen)
  }
  const [searchTerm, setSearchTerm] = useState("");
  function myDeb(call, d = 1000) {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        call(...args);
      }, d);
    };
  }
  const SearchAccountManagement = myDeb((search) => {
    setSearchTerm(search);
  });
  const cancelSearch = () => {
    setSearchTerm("");
  };

  const [filter, setFilterValue] = useState({
    deviceType: [],
    country: []
  })
  const handleDateChange = (event) => {
    const { name, value } = event.target;

    setFilterValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const handleCancel = () => {
    setFilterValue({
      deviceType: [],
      country: []
    });
    setFilterOpen(!filterOpen)
  };


  const handleFilterSave = async () => {
    try {
      let formData = {
        ...filter,
        country: filter.country.map((item) => item.value)
      }
      setIsLoading(true)
      const { data } = await axios.post(
        `/private/getAllActiveUser?search=${searchTerm}&page=${page}&limit=${limit}`, formData
      );
      setFilterOpen(false)
      setPaginationData(data?.data);
      SetTableData(data?.data?.docs);
      setIsLoading(false)

    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }
  useEffect(() => {
    handleFilterSave()
  }, [page, searchTerm])

  // main

  const [description, setDescription] = useState("");
  const formik = useFormik({
    initialValues: {
      notificationType: "",
      userId: [],
      description: "",
      title: "",
      type: "",
      date: "",
      time: ""
    },
    validationSchema: Yup.object({
      notificationType: Yup.string().required("Notification type is required."),
      title: Yup.string()
        .min(5, "Title must be at least 5 characters.")
        .max(30, "Title cannot exceed 30 characters.")
        .required("Title is required."),
      type: Yup.string().required("User type is required."),
      description: Yup.string()
        .min(10, "Description must be at least 10 characters.")
        .max(50, "Description cannot exceed 50 characters.")
        .required("Description is required."),
      date: Yup.string().when("notificationType", {
        is: "2",
        then: (schema) => schema.required("Date is required."),
      }),
      time: Yup.string().when("notificationType", {
        is: "2",
        then: (schema) => schema.required("Time is required."),
      }),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        let scheduledDate;
        if (values.date != "") {
          scheduledDate = new Date(`${values.date}T${values.time}:00.000Z`)?.toISOString();
        }

        const payload = {
          ...values,
          scheduledDate,
        };
        const { data } = await axios.post("/private/sendNotification", payload);
        history.push("/notification-management")
        toast.success(data?.message)
        resetForm();
      } catch (error) {
        console.error("Submission error:", error);
        // alert("Failed to submit the form. Please try again.");
      }
    },
  });

  const handleNotificationTypeChange = (type) => {
    formik.setFieldValue("notificationType", type);
  };


  const [selectedIds, setSelectedIds] = useState([]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allIds = tableData.map((item) => item._id);
      setSelectedIds(allIds);
      formik.setFieldValue("userId", allIds);
    } else {
      setSelectedIds([]);
      formik.setFieldValue("userId", []);
    }
  };

  const handleCheckboxChange = (id) => {
    const updatedSelectedIds = selectedIds.includes(id)
      ? selectedIds.filter((selectedId) => selectedId !== id)
      : [...selectedIds, id];

    setSelectedIds(updatedSelectedIds);
    formik.setFieldValue("userId", updatedSelectedIds);
  };


  // out side click handle
  const dropdownRef = useRef(null);
  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setFilterOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className='hewe-cont notificationCont'>
      <div className="flex-align-center ">
        <IoChevronBack
          size={32}
          className="back-color"
          onClick={() =>
            history.goBack()}
        />
        <DashHeading>Create New Notification</DashHeading>
      </div>

      <form onSubmit={formik.handleSubmit}>
        {/* Notification Type */}
        <div className="notifcont mt-3">
          <div className='d-lg-flex align-items-center'>
            <div className="position-relative">
              <h6>Notification Type<span className='font-r1'>*</span></h6>
              <div className="dropSelect" onClick={selectClick}>
                <h3>
                  {formik.values.notificationType === "0"
                    ? "General"
                    : formik.values.notificationType === "1"
                      ? "Warning"
                      : formik.values.notificationType === "2"
                        ? "Schedule"
                        : "Select"}
                </h3>
                <div className="dropkey">
                  <FaChevronDown />
                </div>
              </div>
              {selectOpen && (
                <div className="dropData">
                  <div
                    className="flex-align-center py-1"
                    onClick={() => handleNotificationTypeChange("0")}
                  >
                    <input
                      type="checkbox"
                      checked={formik.values.notificationType === "0"}
                      readOnly
                    />
                    <label className="ps-3">General</label>
                  </div>
                  <div
                    className="flex-align-center py-1"
                    onClick={() => handleNotificationTypeChange("1")}
                  >
                    <input
                      type="checkbox"
                      checked={formik.values.notificationType === "1"}
                      readOnly
                    />
                    <label className="ps-3">Warning</label>
                  </div>
                  <div
                    className="flex-align-center py-1"
                    onClick={() => handleNotificationTypeChange("2")}
                  >
                    <input
                      type="checkbox"
                      checked={formik.values.notificationType === "2"}
                      readOnly
                    />
                    <label className="ps-3">Schedule</label>
                  </div>
                  <div className="mt-3 d-flex justify-content-end ">
                    <button className="savebtn me-3 " onClick={() => setSelectOpen(false)} type="button">
                      Save
                    </button>
                    <button className="canclebtn" onClick={selectClick} type="button">
                      Cancel
                    </button>
                  </div>
                </div>
              )}
              {formik.touched.notificationType && formik.errors.notificationType ? (
                <div className="error">{formik.errors.notificationType}</div>
              ) : null}
              {formik.touched.time && formik.errors.time ? (
                <div className="text-white fs-12 unselectable" aria-disabled>{formik.errors.time}</div>
              ) : null}
            </div>

            {formik.values.notificationType === "2" &&
              <>
                <div className='ms-lg-3 ms-0 mt-lg-0 mt-3'>
                  <h6>Scheduled Date  <span className='font-r1'>*</span></h6>
                  <input
                    type="date"
                    min={new Date().toISOString().split('T')[0]}
                    onChange={(e) => {
                      formik.setFieldValue("date", e.target.value);
                    }}
                    className="datetime"
                  />
                  {formik.touched.date && formik.errors.date ? (
                    <div className="error">{formik.errors.date}</div>
                  ) : null}
                </div>

                <div className='ms-lg-3 ms-0 mt-lg-0 mt-3'>
                  <h6>Scheduled Time <span className='font-r1'>*</span></h6>
                  <input
                    type="time"
                    onChange={(e) => {
                      formik.setFieldValue("time", e.target.value);
                    }}
                    className="datetime"
                  />
                  {formik.touched.time && formik.errors.time ? (
                    <div className="error">{formik.errors.time}</div>
                  ) : null}
                </div>
              </>
            }
          </div>
        </div>

        {/* Notification Title */}
        <div className="notifcont mt-3">
          <div className="flex-align-center justify-content-between">
            <h6>Notification Title<span className='font-r1'>*</span> </h6>
            <h6>{formik.values.title.length}/30</h6>
          </div>
          <div className="position-relative">
            <input
              type="text"
              className="w-100 notifInput"
              name="title"
              max="50"
              minlength="5"
              maxlength="30"
              value={formik.values.title}
              onChange={(e) => {
                const trimmedValue = e.target.value.replace(/\s{2,}/g, ' ');
                formik.setFieldValue('title', trimmedValue.trimStart());
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.title && formik.errors.title ? (
              <div className="error">{formik.errors.title}</div>
            ) : null}
          </div>
        </div>
        {/* Description */}
        <div className="notifcont mt-3">
          <div className='d-flex justify-content-between'>
            <h6>Description<span className='font-r1'>*</span></h6>
            <p>{formik.values?.description.length}/50</p>
          </div>

          <textarea
            className="textareanot"
            name="description"
            onChange={(e) => {
              const trimmedValue = e.target.value.replace(/\s{2,}/g, ' ');
              formik.setFieldValue('description', trimmedValue.trimStart());
            }}
            value={formik.values.description}
            minlength="10"
            maxlength="50"
          ></textarea>

          {formik.touched.description && formik.errors.description ? (
            <div className="error">{formik.errors.description}</div>
          ) : null}
        </div>

        {/* Select User Type */}
        <div className="notifcont mt-3">
          <div className="flex-align-center justify-content-between">
            <h6 className="mt-3">Select User Type<span className='font-r1'>*</span></h6>
          </div>
          <div className="flex-align-center">
            <div>
              <label htmlFor="all" className="me-3">
                All
              </label>
              <input
                type="radio"
                name="type"
                value="1"
                checked={formik.values.type === "1"}
                onChange={formik.handleChange}
              />
            </div>
            <div className="mx-5">|</div>
            <div>
              <label htmlFor="manual" className="me-3">
                Manually
              </label>
              <input
                type="radio"
                name="type"
                value="2"
                checked={formik.values.type === "2"}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          {formik.touched.type && formik.errors.type ? (
            <div className="error">{formik.errors.type}</div>
          ) : null}
        </div>
        {formik.values.type === "2" && (
          <div className='mt-4'>
            <div className='d-sm-flex align-items-center mb-3 position-relative'>
              <SearchBar
                style={{ background: "#FDFFFB", minWidth: "353px", Height: "33px", borderRadius: "4px", marginLeft: "5px", fontStyle: "italic", color: "#FDFFFB" }}
                className={"heightfix"}
                onChange={(e) => {
                  SearchAccountManagement(e);
                }}
                onCancelSearch={cancelSearch}
                placeholder="Search User Name...."
                inputProps={{ maxLength: 30 }}
              />

              <div ref={dropdownRef} >
                <div className='ms-4 pointer mt-sm-0 mt-4' style={{ color: "#264623" }} onClick={handleFilterOpen}> <FilterSvg /> Advanced Filters</div>
                {
                  filterOpen && (
                    <div className="filterBody">
                      <h6 className='fw-700 text-24363a fw-600'>Device Type</h6>
                      <div className="py-1">
                        <label className="ms-2 fw-400" style={{ color: "#181818" }}>
                          <input
                            type="checkbox"
                            className='me-2'
                            name="Android"
                            checked={filter.deviceType.includes("2")}
                            onChange={() => {
                              const updatedDeviceType = filter.deviceType.includes("2")
                                ? filter.deviceType.filter((type) => type !== "2")
                                : [...filter.deviceType, "2"];
                              setFilterValue({ ...filter, deviceType: updatedDeviceType });
                            }}
                          />
                          Android
                        </label>
                      </div>

                      <div className="py-1">
                        <label className="ms-2 fw-400" style={{ color: "#181818" }}>
                          <input
                            type="checkbox"
                            className='me-2'
                            name="iOS"
                            checked={filter.deviceType.includes("3")}
                            onChange={() => {
                              const updatedDeviceType = filter.deviceType.includes("3")
                                ? filter.deviceType.filter((type) => type !== "3")
                                : [...filter.deviceType, "3"];
                              setFilterValue({ ...filter, deviceType: updatedDeviceType });
                            }}
                          />
                          IOS
                        </label>
                      </div>
                      <div className='py-2'>
                        <label className='fw-700 pb-2'>Country</label>
                        <Select
                          options={countryNameLists.map((name) => ({ label: name, value: name }))}
                          value={filter.country}
                          onChange={(selected) => setFilterValue((prev) => ({ ...prev, country: selected }))}
                          isSearchable={true}
                          isMulti
                          classNamePrefix="react-select"
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: "200px",
                              backgroundColor: "rgba(255, 255, 255, 0.38)",
                              borderColor: "gray",
                              textAlign: "left",
                              boxShadow: "none",
                              color: "black",
                              "&:hover": { borderColor: "black" },
                            }),
                          }}
                        />
                      </div>
                      <div className="mt-3 text-end d-flex justify-content-end">
                        <button
                          type="button"
                          className="canclebtn"
                          onClick={handleCancel}
                        >
                          Reset
                        </button>
                        <button
                          className="savebtn ms-3"
                          onClick={handleFilterSave}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>

            {tableData?.length > 0 ? (
              <Paper className={classes.paperTableHeight}>
                <TableContainer className={classes.tableMainContainer}>
                  <Table>
                    <TableHead>
                      <TableRow className={classes.tableHeadingRow}>
                        <TableCell >
                          <input
                            type="checkbox"
                            className=' mx-4  '
                            checked={selectedIds.length === tableData.length && tableData.length > 0}
                            onChange={handleSelectAll}
                            aria-label="Select all users"
                          />
                          S.No
                        </TableCell>
                        <TableCell className={classes.tablseHeadingCell}>User Name</TableCell>
                        <TableCell className={classes.tablseHeadingCell}>Phone Number</TableCell>
                        <TableCell className={classes.tablseHeadingCell}>Country</TableCell>
                        <TableCell className={classes.tablseHeadingCell} style={{ textAlign: "center" }}>Device type</TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData.map((category, index) => (
                        <TableRow key={category._id}>
                          <TableCell >
                            <input
                              type="checkbox"
                              className='mx-4'
                              checked={selectedIds.includes(category._id)}
                              onChange={() => handleCheckboxChange(category._id)}
                              aria-label={`Select user ${get(category, "profileName", "unknown")}`}
                            />
                            {index + 1 + rowsPerPage * (page - 1)}
                          </TableCell>
                          <TableCell >
                            {get(category, "profileName", "N/A")}
                          </TableCell>
                          <TableCell >
                            {get(category, "countryCode", "N/A")}
                            {get(category, "phoneNumber", "N/A")}
                          </TableCell>
                          <TableCell >
                            {get(category, "country", "N/A")}
                          </TableCell>
                          <TableCell className='text-center'>
                            {category?.deviceType === "3" ? (
                              <GrApple
                                style={{ fontSize: "20px", color: "#424245" }}
                              />
                            ) : category?.deviceType === "2" ? (
                              <GrAndroid
                                style={{ fontSize: "20px", color: "#79c257" }}
                              />
                            ) : (
                              <IoEarth />
                            )}
                          </TableCell>

                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Stack spacing={2} className="flex-align-center justify-content-center w-100">
                  <Pagination
                    count={Math.ceil((paginationData?.totalDocs || 0) / rowsPerPage) || 1}
                    page={page}
                    onChange={handleChangePage}
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{ previous: FiArrowLeft, next: FaArrowRight }}
                        {...item}
                      />
                    )}
                  />
                </Stack>
              </Paper>
            ) : (
              <div className="emptyTable mt-3">No Data Found</div>
            )}

          </div>
        )}

        {/* Submit Button */}
        <div className=" mt-5">
          <button type="submit" className="savebtn px-5">
            Send
          </button>
        </div>
      </form>
      {isLoading && <Overlay />}
    </div>
  )
}

export default AddNotification