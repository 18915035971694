import React, { useEffect, useState } from 'react'
import axios from "../../axios";
import "./style.scss"
import { toast } from 'react-toastify';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

function MilesReward({ userData }) {
  const parsedUserData = JSON.parse(localStorage.getItem("userData")) || JSON.parse(sessionStorage.getItem("userData"));
  let modules = parsedUserData?.data?.modules;
  const permissions = modules?.find((item) => item.moduleName === "Reward Management")?.permissions;
  const [formData, setFormData] = useState({
    miles: { id: "678e5288094e705e091d7ab8", value: "" },
    limit: { id: "67da8fa8a7395948de154e3f", value: "" }
  });

  const fetchRewardValue = async (key) => {
    try {
      const res = await axios.get(`/findRewardValueById/${formData[key].id}`);
      setFormData((prev) => ({
        ...prev,
        [key]: { ...prev[key], value: res.data.data[0].value },
      }));
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
    }
  };

  const updateRewardValue = async (key) => {
    try {
      if (!formData[key] || Number(formData[key]?.value) <= 0) {
        toast.error("Please enter a valid value greater than 0.");
        return;
      }
      const res = await axios.post("/private/updateRewardValue ", formData[key]);
      if (formData[key].id == "678e5288094e705e091d7ab8") {
        toast.success("Mile Reward value updated successfully.");
      } else {
        toast.success("HeXP limit updated successfully.");
      }
      fetchRewardValue(key);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchRewardValue("miles");
    fetchRewardValue("limit");
  }, []);


  return (
    <>
      <div className='hewe-cont'>
        <div className='referralcont'>
          <div className='flex-align-center'>
            <div className="dott me-3"></div>
            <p className='main-heading'>Mile Reward</p>
          </div>
          <div className=''>
            <div className='desc my-4'>
              <p>Define the HeXP rewards for each milestone and set the maximum HeXP limit for Walking and Cycling to enhance participation and engagement.</p>
            </div>
            <div className="refcont">
              <h2>Reward</h2>
              <div className='flex-align-center flex-wrap mt-3'>
                <h4 className='mb-0 perrefer'>Per Mile = </h4>
                <div className='ms-3'>
                  <label htmlFor="Total Hewe coins">Total HeXP</label>
                  <div>
                    <input
                      type="number"
                      placeholder="Enter per mile reward "
                      className='me-5'
                      value={formData.miles.value}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          miles: { ...prev.miles, value: e.target.value },
                        }))
                      }
                    /> </div>
                </div>
                {(userData.userType === "admin" || userData.userType == "subAdmin" && permissions?.edit == true) &&
                  <button className='mt-4' onClick={() => updateRewardValue("miles")}>Update</button>
                }
              </div>
            </div>

            <div className="refcont">
              <h2>HeXP Limit</h2>
              <div className='flex-align-center flex-wrap mt-3'>
                <h4 className='mb-0 perrefer'>Per day  = </h4>
                <div className='ms-3'>
                  <label htmlFor="Total Hewe coins">Total HeXP</label>
                  <div>
                    <input
                      type="number"
                      placeholder="Enter per day limit"
                      className='me-5'
                      value={formData.limit.value}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          limit: { ...prev.limit, value: e.target.value },
                        }))
                      }
                    /> </div>
                </div>
                {(userData.userType === "admin" || userData.userType == "subAdmin" && permissions?.edit == true) &&
                  <button className='mt-4' onClick={() => updateRewardValue("limit")}>Update</button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}



const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
    sidebar: state.sidebar,
  };
};

export default connect(
  mapStateToProps,
  // mapDispatchToProps
)(withRouter(MilesReward));