export const GET_LOCATIONS = "GET_LOCATIONS";
export const USER_DATA = "USER_DATA";
export const DEFAULT_STATE = "DEFAULT_STATE";
export const UPDATE_DEFAULT = "UPDATE_DEFAULT";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_SIDEBAR = "UPDATE_SIDEBAR";
export const SET_ACTIVE_DROPDOWN="SET_ACTIVE_DROPDOWN";
export const DAILY_ACHIEVEMENT="DAILY_ACHIEVEMENT";
export const ACCOUNT_MANAGEMENT1="ACCOUNT_MANAGEMENT1";
export const ACCOUNT_MANAGEMENT2="ACCOUNT_MANAGEMENT2";
export const ACCOUNT_MANAGEMENT3="ACCOUNT_MANAGEMENT3";
