import React, { useState} from "react";
import axios from "../../axios";
import { Form, Formik } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import {  toast } from "react-toastify";
import JoditEditor from "jodit-react";
import Overlay from "../../components/Overlay";
import TextArea from "../../components/TextArea";
import { useHistory, useLocation} from "react-router-dom/cjs/react-router-dom.min";
import {DashHeading } from "../AccountManagement/AccountManagementElements";
import { IoChevronBack } from "react-icons/io5";


const EditFAQs = () => {
    const location = useLocation();
    const state = location.state.state;
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({
        question: state?.faq?.question || "",
        answer: state?.faq?.answer || "",
      });
    

    const handleSubmit = async (values) => {
        setIsLoading(true);
        try {
            const apiCall = await axios.post(`/private/editFaq/${state.id}`, values);
            const { data } = await apiCall;
            history.goBack();
            toast.success(data?.message);
            setIsLoading(false)
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="hewe-cont">
                <div className="flex-align-center justify-content-between mb-4 w-100">
                    <div className="flex-align-center ">
                        <IoChevronBack
                            size={32}
                            className="back-color"
                            onClick={() =>
                                history.goBack()}
                        />
                        <DashHeading>Edit FAQs</DashHeading>
                    </div>
                </div>

                <div>
                    <Formik
                        enableReinitialize
                        initialValues={data}
                        validate={(values) => {
                            const errors = {};
                            if (!values.question)
                                errors.question = "Question is required";
                            if (!values.answer) errors.answer = "Answer is required";
                            return errors;
                        }}
                        onSubmit={handleSubmit}
                    >
                        {({ values, handleChange, setFieldValue, errors, touched }) => (
                            <Form
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "1rem",
                                }}
                            >
                                <div className="faq-section">
                                    <label className="fw-700 mb-3">Question</label>
                                    <TextArea
                                        name="question"
                                        className="form-control"
                                        placeholder="Type your question here..."
                                        value={values.question}
                                        onChange={handleChange}
                                    />
                                    {errors.question && touched.question && (
                                        <div style={{ color: "red" }}>{errors.question}</div>
                                    )}
                                </div>
                                <div className="faq-section mt-3">
                                    <lable className="fw-700 mb-4">Answer</lable>
                                    <JoditEditor
                                        config={{
                                            askBeforePasteFromWord: false,
                                            askBeforePasteHTML: false,
                                            readonly: false,
                                            placeholder: "Type your answer here...",
                                        }}
                                        value={values.answer}
                                        onBlur={(content) => {
                                            setFieldValue("answer", content);
                                        }}
                                    />
                                    {errors.answer && touched.answer && (
                                        <div style={{ color: "red" }}>{errors.answer}</div>
                                    )}
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "1rem",
                                    }}
                                >
                                    <button className="savebtn" type="submit" disabled={isLoading}>
                                        {isLoading ? "Saving..." : "Update"}
                                    </button>

                                    <button
                                        type="button"
                                        className="canclebtn ms-3"
                                        onClick={() => {
                                            setFieldValue("question", "");
                                            setFieldValue("answer", "");
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>

            {isLoading && <Overlay />}
        </>
    );
};

export default EditFAQs;