import React, { useState } from "react";
import styled from "styled-components/macro";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const SidebarLink = styled(Link)`
  display: flex;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0px 18px 18px;
  height: 45px;
  text-decoration: none;

  background: ${({ sidebarActive }) => (sidebarActive ? "#304312" : "#132400")};
  border-left: ${({ sidebarActive }) =>
    sidebarActive ? "3px solid grey" : "3px solid #132400"};
`;

const DropdownLink = styled(SidebarLink)`
  padding-left: 35px;
  background: ${({ sidebarActive }) => (sidebarActive ? "#2a3a0f" : "transparent")};
  border-left: ${({ sidebarActive }) =>
    sidebarActive ? "3px solid grey" : "3px solid transparent"};
`;

const SidebarLabel = styled.span`
  margin-left: 14px;
  font-size: 15px;
  
`;

const IconLabel = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  color: ${({ sidebarActive }) => (sidebarActive ? "#fff" : "white")};
`;

const SidebarIcon = styled.span`
  &:hover {
    color: #21afe6;
  }
`;

const SubMenu = ({ item, activeDropdown, setActiveDropdown, dailyAchievement, setDailyAchievement, setDailyAchievement3, setDailyAchievement2, setDailyAchievement1, }) => {
  const location = useLocation();
  const sidePathname = location.pathname.split("/")[1];
  const isActive = activeDropdown === item.title;
  const toggleSubnav = () => {
    setActiveDropdown(isActive ? null : item.title);
    navigate()
  };
  const navigate = () => {
    let redData = { page: "", limit: "", year: "", month: "", startDate: "", endDate: "" }
    setDailyAchievement(redData)
    // reset level
    sessionStorage.setItem("levelpagi", "")
    // account
    setDailyAchievement3({ page: "", limit: "", country: "", deviceType: "", rank: "", level: "", gender: "", search: "" })
    setDailyAchievement2({ page: "", limit: "", country: "", deviceType: "", rank: "", level: "", gender: "", search: "" })
    setDailyAchievement1({ page: "", limit: "", country: "", deviceType: "", rank: "", level: "", gender: "", search: "" })
    sessionStorage.setItem("subAdminPage", 1)
    sessionStorage.setItem("spinReward", "")
    sessionStorage.setItem("ticketPage", 1)
  };
  return (
    <>
      <SidebarLink
        sidebarActive={isActive || sidePathname === item.path.split("/")[1]}
        to={item.path}
        onClick={toggleSubnav}
      >
        <IconLabel sidebarActive={isActive} >
          <SidebarIcon>{item.icon}</SidebarIcon>
          <SidebarLabel style={{ fontWeight: sidePathname == item.path.split("/")[1] ? 700 : "200" }}>
            {item.title}
          </SidebarLabel>

        </IconLabel>
        <div className="me-3">
          {item.subNav && isActive ? item.iconOpened : item.subNav ? item.iconClosed : null}
        </div>
      </SidebarLink>
      {isActive &&
        item.subNav?.map((subItem, index) => (
          <DropdownLink
            to={subItem.path}
            sidebarActive={sidePathname === subItem.path.split("/")[1]}
            key={index}
          >
            <IconLabel>
              <SidebarLabel>{subItem.title}</SidebarLabel>
            </IconLabel>
          </DropdownLink>
        ))}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSidebar: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_SIDEBAR,
        updateSidebar: updatedValue,
      });
    },
    setActiveDropdown: (dropdown) => {
      dispatch({
        type: actionTypes.SET_ACTIVE_DROPDOWN,
        activeDropdown: dropdown,
      });
    },
    setDailyAchievement: (updatedValue) => {
      dispatch({
        type: actionTypes.DAILY_ACHIEVEMENT,
        dailyAchievement: updatedValue,
      });
    },
    setDailyAchievement1: (updatedValue) => {
      dispatch({
        type: actionTypes.ACCOUNT_MANAGEMENT1,
        accountManagement1: updatedValue,
      });
    },
    setDailyAchievement2: (updatedValue) => {
      dispatch({
        type: actionTypes.ACCOUNT_MANAGEMENT2,
        accountManagement2: updatedValue,
      });
    },
    setDailyAchievement3: (updatedValue) => {
      dispatch({
        type: actionTypes.ACCOUNT_MANAGEMENT3,
        accountManagement3: updatedValue,
      });
    },

  };
};
const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    sidebar: state.sidebar,
    activeDropdown: state.activeDropdown,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SubMenu));
