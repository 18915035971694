import React, { useEffect, useState } from 'react'
import { DashHeading } from '../Authentican/Profile/ProfileElements'
import { FaEye, FaPlus } from 'react-icons/fa'
import { useHistory, withRouter, } from 'react-router-dom/cjs/react-router-dom';
import axios from "../../axios";
import Overlay from '../../components/Overlay';
import { connect } from "react-redux";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { get } from 'lodash';
import { toast } from 'react-toastify';
import SearchBar from 'material-ui-search-bar';
import Select from "react-select";
import { FiArrowLeft } from "react-icons/fi";
import { FaArrowRight } from "react-icons/fa";
import { PaginationItem } from "@material-ui/lab";
import { Pagination, Stack } from "@mui/material";
import "./style.scss"
import { SubAdminCross } from '../../components/svg';
const useStyles = makeStyles((theme) => ({
  paperTableHeight: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    border: "1px solid #D9E3D9",
    overflowX: "scroll",

  },
  tableMainContainer: {
    overflowX: "scroll",
    maxHeight: "80vh",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  tableHeadingRow: {
    // backgroundColor: "#21AFE6",
  },
  tablseHeadingCell: {
    textTransform: "capitalize",
    padding: "0.8rem 20px !important",
    textAlign: "center",
    fontSize: "14px",
    color: "#264503",
    fontWeight: 600,
    borderBottom: "1px solid #D9E3D9",
    whiteSpace: "nowrap",
  },
  textMiddle: {
    padding: "0.6rem 20px !important",
    verticalAlign: "middle !important",
    textAlign: "center",
    fontSize: "14px",
    color: "black",
  },
  tablePaginationStyle: {
    borderTop: "1px solid #0000001a",
    borderRadius: "0rem 0rem 0.4rem 0.4rem",
    overflowY: "hidden",
  },
  sticky: {
    position: "sticky",
    left: "0"
  }
}));
function SubAdmin({ userData }) {
  const parsedUserData = JSON.parse(localStorage.getItem("userData")) || JSON.parse(sessionStorage.getItem("userData"));
  let modules = parsedUserData?.data?.modules;
  const permissions = modules?.find((item) => item.moduleName === "Sub Admin Management")?.permissions;
  const history = useHistory();
  let prevpage = sessionStorage.getItem("subAdminPage")
  const [page, setPage] = useState(Number(prevpage) || 1);
  const [limit, setLimit] = useState(10);
  const [paginationData, setPaginationData] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    window.scrollTo(0, 0);
  };
  const [tableData, setTableData] = useState()
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState("");
  const SearchAccountManagement = myDeb((search) => {
    setSearchTerm(search);
  });

  const cancelSearch = () => {
    setSearchTerm("");
  };

  function myDeb(call, d = 1000) {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        call(...args);
      }, d);
    };
  }

  const getData = async () => {
    try {
      setIsLoading(true)
      const { data } = await axios.get(`/private/getSubAdmin?search=${searchTerm}&page=${page}&limit=${limit}`)
      sessionStorage.setItem("subAdminPage", Number(page))
      setPaginationData(data?.data);
      setTableData(data?.data?.docs);
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [searchTerm, page])


  const funBlock = async (id, payload) => {
    try {
      setIsLoading(true)
      const data = await axios.put(`private/undateInfo/${id}?status=${payload}`)
      toast.success(data?.data?.message);
      getData()
      setIsLoading(false)
    } catch (error) {

    }
  }
  const funCancel = async (id, payload) => {
    try {
      setIsLoading(true)
      const data = await axios.put(`private/undateInfo/${id}?invitation=${payload}`)
      getData()
      toast.success(data?.data?.message);
      setIsLoading(false)
    } catch (error) {

    }
  }
  const funInvitation = async (id, payload) => {
    try {
      setIsLoading(true)

      const data = await axios.put(`private/undateInfo/${id}?invitation=${payload}`)
      getData()
      toast.success(data?.data?.message);
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const [selectedRows, setSelectedRows] = useState([]);
  const handleRowSelection = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };
  const handleSelectAll = () => {
    if (selectedRows.length === tableData.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(tableData.map((category) => category.userId));
    }
  };


  const handledelete = async () => {
    try {
      setIsLoading(true)
      const refData = {
        ids: selectedRows
      }
      const data = await axios.post(`private/deleteSubAdmins`, refData)
      toast.success(data?.data?.message);
      setSelectedRows([])
      getData()
      setIsLoading(false)

    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }
  return (
    <>

      <div className='hewe-cont subadmincont'>
        <div className='d-xl-flex align-items-center  justify-content-between'>
          <div className="flex-align-center mb-0 ">
            <div className="dott me-3"></div>
            <DashHeading>Sub Admin Management</DashHeading>
          </div>
          <div className='d-lg-flex align-items-center mt-xl-0 mt-4'>
            <SearchBar
              style={{ background: "#FDFFFB", minWidth: "353px", Height: "25px", borderRadius: "4px", fontStyle: "italic", color: "#FDFFFB" }}
              className="searchbars1 me-3"
              value={searchTerm}
              onChange={(e) => {
                SearchAccountManagement(e);
              }}
              onCancelSearch={cancelSearch}
              placeholder="Search By Id, Name, Email, Phone Number"
              inputProps={{ maxLength: 30 }}
            />
            <button onClick={() => history.push(`/sub-admin-management/add-subAdmin`)} className='savebtn flex-align-center mt-lg-0 mt-4'><FaPlus className='me-2' />Add Sub Admin</button>
          </div>
        </div>
        {
          selectedRows.length != 0 && <div className='flex-align-center deleteSubAdmin mt-3'>
            <div>
              <button onClick={() => handledelete()}>Delete</button>
            </div>
            <p className='ms-2'>{selectedRows.length} Selected</p>
            <div className='flex-align-center ms-5' onClick={() => setSelectedRows([])}><SubAdminCross /> <p className='ms-2'>Clear</p></div>
          </div>
        }

        {
          tableData?.length != 0 ? <Paper className='mt-4'>
            <TableContainer className={classes.tableMainContainer}>
              <Table>
                <TableHead>
                  <TableRow className={classes.tableHeadingRow}>
                    <TableCell className={classes.tablseHeadingCell}>
                      <input
                        type="checkbox"
                        checked={selectedRows?.length === tableData?.length}
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell className={classes.tablseHeadingCell}>S.no</TableCell>
                    <TableCell className={classes.tablseHeadingCell}>Status</TableCell>
                    <TableCell className={classes.tablseHeadingCell}>Action</TableCell>
                    <TableCell className={classes.tablseHeadingCell}>Sub Admin ID</TableCell>
                    <TableCell className={classes.tablseHeadingCell}>Sub Admin Name</TableCell>
                    <TableCell className={classes.tablseHeadingCell}>Sub Admin Title</TableCell>
                    <TableCell className={classes.tablseHeadingCell}>Email</TableCell>
                    <TableCell className={classes.tablseHeadingCell}>Phone Number</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData?.map((category, index) => (
                    <TableRow >
                      <TableCell className={classes.textMiddle}>
                        <input
                          type="checkbox"
                          checked={selectedRows.includes(category.userId)}
                          onChange={() => handleRowSelection(category.userId)}
                        />
                      </TableCell>
                      <TableCell className={classes.textMiddle}>
                        <div>{index + 1 + rowsPerPage * (page - 1)}</div>
                      </TableCell>
                      {(userData.userType === "admin" || userData.userType == "subAdmin" && permissions?.edit == true) ?
                        <TableCell className={classes.textMiddle}>
                          <InvitationStatus
                            category={category}
                            funInvitation={funInvitation}
                            funCancel={funCancel}
                            funBlock={funBlock}
                            history={history}
                            userData={userData}
                            permissions={permissions}
                          />
                        </TableCell> : <TableCell className={classes.textMiddle}>
                          N/A
                        </TableCell>
                      }
                      <TableCell className={classes.textMiddle}>
                        <span
                          className='ms-2 pointer'
                          onClick={() => history.push(`/sub-admin-management/SubAdminHistory/${category.userId}`)}
                        >
                          <FaEye size={22} color='rgba(94, 171, 7, 1)' />
                        </span>
                      </TableCell>

                      <TableCell className={classes.textMiddle}>
                        {get(category, "subAdminId", "N/A")}
                      </TableCell>
                      <TableCell className={classes.textMiddle}>
                        {get(category, "subAdminName", "N/A")}
                      </TableCell>
                      <TableCell className={classes.textMiddle}>
                        {get(category, "subAdminTitle", "N/A")}
                      </TableCell>

                      <TableCell className={classes.textMiddle}>
                        {get(category, "email", "N/A")}
                      </TableCell>
                      <TableCell className={classes.textMiddle}>
                        +{get(category, "phoneNumber", "N/A")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Stack spacing={2} className="flex-align-center justify-content-center w-100">
                <Pagination
                  count={Math.ceil(paginationData?.totalDocs / rowsPerPage)}
                  page={page}
                  onChange={(event, value) => handleChangePage(event, value)}
                  renderItem={(item) => (
                    <PaginationItem
                      slots={{ previous: FiArrowLeft, next: FaArrowRight }}
                      {...item}
                    />
                  )}
                />
              </Stack>
            </TableContainer>
          </Paper> : <div className="emptyTable mt-3">
            No Data Found
          </div>
        }

      </div>
      {isLoading && <Overlay />}

    </>
  )
}


const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
    sidebar: state.sidebar,
  };
};

export default connect(
  mapStateToProps,
  // mapDispatchToProps
)(withRouter(SubAdmin));


const InvitationStatus = ({ category, funInvitation, funCancel, funBlock, history, userData, permissions }) => {
  const handleInvitationChange = (selectedOption) => {
    const selectedValue = selectedOption.value;
    if (selectedValue === "resend") {
      funInvitation(category?.userId, 1);
    }
    if (selectedValue === "cancel") {
      funCancel(category?.userId, 2);
    }
  };

  const handleStatusChange = (selectedOption) => {
    const value = selectedOption.value;
    if (value === "3") {
      history.push(`/sub-admin-management/edit-subAdmin/${category.userId}`);
    }
    funBlock(category?.userId, value);
  };

  const invitationOptions = [
    { value: 'expire', label: 'Expired' },
    { value: 'resend', label: 'Resend Invitation' },
  ];

  const cancelInvitationOptions = [
    { value: 'expire', label: 'Invitation Cancel' },
    { value: 'resend', label: 'Resend Invitation' },
  ];

  const sentInvitationOptions = [
    { value: 'sent', label: 'Invitation Sent' },
    { value: 'resend', label: 'Resend Invitation' },
    { value: 'cancel', label: 'Cancel Invitation' },
  ];

  const blockedOptions = [
    { value: '1', label: 'Blocked' },
    { value: '2', label: 'Unblock' },
  ];

  const activeOptions = [
    { value: '2', label: 'Active' },
    { value: '1', label: 'Block' },
    { value: '3', label: 'Edit' },
  ];

  // Filter out the currently selected value from the options
  const filterSelectedOption = (options, selectedValue) => {
    return options.filter(option => option.value !== selectedValue);
  };

  return (
    <>
      {category?.invitationAccepted === false && category?.isExpire === true ? (
        <Select
          options={filterSelectedOption(invitationOptions, 'expire')}
          defaultValue={invitationOptions.find(option => option.value === 'expire')}
          onChange={handleInvitationChange}
          className="invitExpire subbtngrop"
          id="invitationStatus"
          isSearchable={false}
        />
      ) : category?.isInvitationCancel === true ? (
        <Select
          options={filterSelectedOption(cancelInvitationOptions, 'expire')}
          defaultValue={cancelInvitationOptions.find(option => option.value === 'expire')}
          onChange={handleInvitationChange}
          className="invitExpire subbtngrop"
          id="invitationStatus"
          isSearchable={false}
        />
      ) : category?.invitationAccepted === false ? (
        <Select
          options={filterSelectedOption(sentInvitationOptions, 'sent')}
          defaultValue={sentInvitationOptions.find(option => option.value === 'sent')}
          onChange={handleInvitationChange}
          className="invitSent subbtngrop"
          id="invitationStatus"
          isSearchable={false}
        />
      ) : category?.status === "1" ? (
        <Select
          className="status-dropdown subbtngrop blockbtn"
          options={filterSelectedOption(blockedOptions, '1')}
          value={blockedOptions.find(option => option.value === category?.status)}
          onChange={handleStatusChange}
          isSearchable={false}
        />
      ) : category?.status === "2" ? (
        <Select
          className="status-dropdown subbtngrop unblockbtn"
          options={filterSelectedOption(activeOptions, category?.status)}
          value={activeOptions.find(option => option.value === category?.status)}
          onChange={handleStatusChange}
          isSearchable={false}
        />
      ) : null}
    </>
  );
};