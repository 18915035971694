import axios from "axios";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa"; // Ensure FaPlus is imported


export const UploadImage = async (file, message) => {
  try {
    if (!file) {
      throw new Error("No file provided");
    }
    const formData = new FormData();
    formData.append("file", file); // Use the file directly
    const { data } = await axios.post("https://game.hewe.club/fileUpload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    toast.success(message);
    return data?.data?.image_url;
  } catch (error) {
    toast.error("Profile picture upload failed. Please try again.", {
      position: toast.POSITION.TOP_RIGHT,
    });
    throw error;
  }
};



const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};
export const UploadImageBase64 = async (file, message) => {
  try {
    // Convert image file to base64
    const base64 = await convertToBase64(file);
    const base64Parts = base64.split(",");
    const data = base64Parts[1];
    toast.success(message);
    return data;
  } catch (error) {
    toast.error("Profile picture upload failed. Please try again.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
