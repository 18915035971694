import React, { useState, useEffect } from "react";
import { DashHeading } from '../AccountManagement/AccountManagementElements'
import "./style.scss"
import { FaPlus } from 'react-icons/fa'
import Select from "react-select";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
} from "@material-ui/core";
import axios from "../../axios"
import { makeStyles } from "@material-ui/core/styles";
import { get } from "lodash";
import { withRouter, useHistory } from "react-router-dom";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { FiArrowLeft } from "react-icons/fi";
import { FaArrowRight } from "react-icons/fa";
import { Stack } from "@mui/material";
import Overlay from "../../components/Overlay";
import { toast } from "react-toastify";
const useStyles = makeStyles((theme) => ({
    paperTableHeight: {
        width: "100%",
        maxHeight: "120vh",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        border: "1px solid #D9E3D9",
        overflowX: "scroll",
    },
    tableMainContainer: {
        minHeight: "50vh",
        overflowX: "scroll",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
    },
    tableHeadingRow: {
        // backgroundColor: "#21AFE6",
    },
    tablseHeadingCell: {
        padding: "0.8rem 20px !important",
        textAlign: "center",
        fontSize: "14px",
        color: "#264503",
        fontWeight: 600,
        borderBottom: "1px solid #D9E3D9",
        whiteSpace: "nowrap",
    },
    textMiddle: {
        padding: "0.6rem 20px !important",
        verticalAlign: "middle !important",
        textAlign: "center",
        fontSize: "14px",
        color: "black",
    },
    tablePaginationStyle: {
        borderTop: "1px solid #0000001a",
        borderRadius: "0rem 0rem 0.4rem 0.4rem",
        overflowY: "hidden",
    },
    sticky: {
        position: "sticky",
        left: "0"
    }
}));
function TicketMngt() {
    const classes = useStyles();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, SetTableData] = useState([]);
    // For Pagination
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [paginationData, setPaginationData] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(10);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        window.scrollTo(0, 0);
    };

    const getData = async () => {
        try {
            setIsLoading(true)
            const { data } = await axios.get(
                `/private/getTicket/?page=${page}&limit=${limit}`
            );
            sessionStorage.setItem("ticketPage", Number(page))
            setPaginationData(data?.data);
            SetTableData(data?.data?.docs);
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }
    useEffect(() => {
        getData()
    }, [page])


    let handleStatus = async (id, value) => {
        try {
            const status = {
                Status: value
            }
            const data = await axios.post(`/private/changeTicketStatus/${id}`, status)
            toast.success(data?.data?.message)
            getData()
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className='hewe-cont'>
            <div className='flex-align-center  justify-content-between'>
                <div className="flex-align-center mb-0 ">
                    <div className="dott me-3"></div>
                    <DashHeading>Ticket Management</DashHeading>
                </div>
            </div>
            <div className="mt-4">
                {tableData?.length > 0 ? (<>
                    <Paper className={classes.paperTableHeight}>
                        <>
                            <TableContainer
                                className={classes.tableMainContainer}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow className={classes.tableHeadingRow}>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                S.No
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Status
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Ticket ID
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                User Name
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Created On
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Query
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Description
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableData.map((category, index) => (
                                            <TableRow key={category._id}>
                                                <TableCell className={classes.textMiddle}>
                                                    <div>{index + 1 + rowsPerPage * (page - 1)}</div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <TicketStatusSelect category={category} handleStatus={handleStatus} />
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <div>
                                                        {get(category, "ticketId")}
                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <div className="font-b1 underline pointer" onClick={() =>
                                                        history.push(
                                                            `/accountManagement/view-profile/${get(category, "userId")}`
                                                        )
                                                    }>
                                                        {get(category, "userName")}
                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    <div>
                                                        {new Date(get(category, "createdAt", 0)).toLocaleString("en-GB", {
                                                            day: "2-digit",
                                                            month: "2-digit",
                                                            year: "numeric",
                                                            hour: "2-digit",
                                                            minute: "2-digit",
                                                            hour12: true
                                                        })}
                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    {get(category, "subject", "-")}
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    {get(category, "description", 0)}
                                                </TableCell>

                                            </TableRow>
                                        ))
                                        }
                                    </TableBody>
                                </Table>
                                <Stack spacing={2} className="flex-align-center justify-content-center w-100 py-3">
                                    <Pagination
                                        count={Math.ceil(paginationData?.totalDocs / rowsPerPage)}
                                        page={page}
                                        onChange={(event, value) => handleChangePage(event, value)}
                                        renderItem={(item) => (
                                            <PaginationItem
                                                slots={{ previous: FiArrowLeft, next: FaArrowRight }}
                                                {...item}
                                            />
                                        )}
                                    />
                                </Stack>
                            </TableContainer>
                        </>
                    </Paper>
                </>) : (
                    <div className="emptyTable mt-3">
                        No Data Found
                    </div>
                )}
            </div>
            {isLoading && <Overlay />}
        </div>
    )
}

export default TicketMngt




const statusOptions = {
    "1": [
        { value: "2", label: "Close" },
        { value: "4", label: "Review" }
    ],
    "2": [
        { value: "3", label: "Reopen" }
    ],
    "3": [
        { value: "2", label: "Close" },
        { value: "4", label: "Review" }
    ],
    "4": [
        { value: "2", label: "Close" }
    ]
};

const statusLabels = {
    "1": "Open",
    "2": "Close",
    "3": "Reopen",
    "4": "Review"
};

const getClassName = (status) => {
    switch (status) {
        case "1":
            return "ticketOpen";
        case "2":
            return "ticketClose";
        case "3":
            return "ticketReopen";
        case "4":
            return "ticketReview";
        default:
            return "";
    }
};
const TicketStatusSelect = ({ category, handleStatus }) => {
    return (
        <Select
            className={`ticketSelect ${getClassName(category.status)}`}
            value={{ value: category.status, label: statusLabels[category.status] }}
            onChange={(selectedOption) => handleStatus(category._id, selectedOption.value)}
            options={statusOptions[category.status] || []}
            isSearchable={false}
            styles={{
                control: (provided) => ({
                    ...provided,
                    backgroundColor: "inherite",
                    borderColor: "transparent",
                    boxShadow: "none",
                    "&:hover": { borderColor: "none" }
                }),
            }}
        />
    );
};