import React, { useEffect, useState } from 'react'
import { MdArrowBackIosNew, MdOutlineClose } from 'react-icons/md'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import axios from "../../axios"
import { Copy } from '../../components/svg'
import moment from 'moment'
import { Modal } from "../../components/Modal";
import Overlay from "../../components/Overlay";

import "./style.scss"

function ViewClaimDetailsPage() {
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false);

    const [openModal, setOpenModal] = useState(false);
    const { id } = useParams()
    const [data, setData] = useState()
    const response = async () => {
        try {
            setIsLoading(true)
            const { data } = await axios.get(`/private/viewDetails/${id}`);
            setData(data?.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }
    useEffect(() => {
        response()
    }, [])
    const markdelivered = async () => {
        try {
            setIsLoading(true);
            await axios.post(`/private/rewardDelivered/${data._id}`)
            setOpenModal(false)
            response()
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }
    return (
        <div className='hewe-cont detailspage'>
            <div className="flex-align-center justify-content-between  ms-0 mb-3">
                <div className='flex-align-center'>
                    <div className="me-2"><MdArrowBackIosNew style={{ fontSize: "30px" }} onClick={() => history.goBack()} /></div>
                    <h2 className="font-dark-green mb-0 fs-22">Tracking Details</h2>
                </div>
                {
                    data?.action != "2" && <div className='savebtn ' onClick={() => setOpenModal(true)}>Mark as delivered</div>
                }
            </div>
            <div className='row align-items-stretch'>
                <div className='col-8  p-2 '>
                    <div className='cardcont p-3'>
                        <div className='d-flex justify-content-between'>
                            <div>
                                <div className='d-flex align-items-center'>
                                    <div className='d-flex align-items-center'>
                                        <p className='trackingId'>#{data?.trakingId}</p> <div className='ms-3'><Copy /></div>
                                    </div>
                                    <div>
                                        <p className='ms-5'>Updated at: {moment(data?.updatedAt).format('LLL')}</p>
                                    </div>
                                </div>
                                <div className='rewardEarn my-4'>
                                    <p><span style={{color:"rgba(75, 85, 88, 1)"}}>Reward earned on:</span> {moment(data?.receivedOn).format('LLL')}</p>
                                </div>
                            </div>
                            <div>
                                <img src={data?.brandImg} alt='img' className='imgcont' />
                            </div>
                        </div>
                        <h4 className='mt-3'>Product Details</h4>
                        <div className='row'>
                            <div className="col-8 d-flex justify-content-between">
                                <div>
                                    <p className='py-3 p2'>Product Name</p>
                                    <p>{data?.rewardName}</p>
                                </div>
                                <div>
                                    <p className='py-3 p2'>Qty</p>
                                    <p>{data?.rewardAmount}</p>
                                </div>
                                <div>
                                    <p className='py-3 p2' >Total Weight</p>
                                    <p>{data?.weight ?? "-"}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-4 p-2'>
                    <div className='cardcont'>
                        <h5 className='p-3 mb-0'>User Details</h5>
                        <div className="line"></div>
                        <div className='p-3'>
                            <p> {data?.address?.addressLine1}, {data?.address?.addressLine2}, {data?.address?.city}, {data?.address?.state}, {data?.address?.zipCode}</p>
                            <h6 className='mt-5 mb-3'>Contact</h6>
                            <p className='link pointer' onClick={() => {
                                history.push(
                                    `/accountManagement/view-profile/${data?.userId}`
                                )
                            }}>{data?.profileName}</p>
                            <p>{data?.email}</p>
                            <p>{data?.phoneNumber}</p>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                maxWidth="lg"
                width="680px"
                RoundedCorners={true}
                isOpen={openModal}
                onClose={() => {
                    setOpenModal(false);
                }}
                backgroundModal={false}
                backgroundModalContent={false}
                title={
                    <div className="p-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <p className="ps-2">Mark as dilivered</p>
                            </div>
                            <MdOutlineClose
                                style={{
                                    fontWeight: 600,
                                    fontSize: "22px",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setOpenModal(false);
                                }}
                            />
                        </div>
                    </div>
                }
                content={
                    <div className='text-center'>
                        <p>Are you sure want to mark it as delivered?</p>
                        <div className='d-flex justify-content-center my-4' >
                            <button className='savebtn px-5 py-2' onClick={() => markdelivered()}>Yes</button>
                            <button className='cancelbtn ms-3 px-5 py-2' onClick={()=>setOpenModal(false)}>No</button>
                        </div>
                    </div>
                }
            />
            {isLoading && <Overlay />}

        </div>
    )
}

export default ViewClaimDetailsPage