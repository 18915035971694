import * as actionTypes from "./actions";


const data = JSON.parse(localStorage.getItem("userData")) || JSON.parse(sessionStorage.getItem("userData"));



const initialState = {
  userData: JSON.parse(localStorage.getItem("userData")) || JSON.parse(sessionStorage.getItem("userData")),
  locations: [],
  sidebar: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER:
      return {
        ...state,
        userData: action.updatedUser,
      };
    case actionTypes.UPDATE_DEFAULT:
      return {
        ...state,
        defaultState: action.updateDefault,
      };
    case actionTypes.GET_LOCATIONS:
      return {
        ...state,
        locations: action.locationData,
      };
    case actionTypes.UPDATE_SIDEBAR:
      return {
        ...state,
        sidebar: action.updateSidebar,
      };
    case actionTypes.SET_ACTIVE_DROPDOWN:
      return {
        ...state,
        activeDropdown: action.activeDropdown,
      };
      case actionTypes.DAILY_ACHIEVEMENT:
      return {
        ...state,
        dailyAchievement: action.dailyAchievement,
      };
      case actionTypes.ACCOUNT_MANAGEMENT1:
      return {
        ...state,
        accountManagement1: action.updateAccountManagement1,
      };
      case actionTypes.ACCOUNT_MANAGEMENT2:
      return {
        ...state,
        accountManagement2: action.updateAccountManagement2,
      };
      case actionTypes.ACCOUNT_MANAGEMENT3:
      return {
        ...state,
        accountManagement3: action.updateAccountManagement3,
      };
    default:
      return state;
  }
};

export default reducer;
