import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useStyles } from "../../components/rewardTableTheme";
import axios from "../../axios";
import { toast } from "react-toastify";
import Overlay from "../../components/Overlay";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { FiArrowLeft } from "react-icons/fi";
import { FaAngleDown, FaArrowRight } from "react-icons/fa";
import Select from "react-select";
import { Stack } from "@mui/material";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { BsEye, BsPencil } from "react-icons/bs";
import moment from "moment/moment";
import { ArrowDown, ArrowUp, Copy, Filter } from "../../components/svg";
import { DashHeading } from "../AccountManagement/AccountManagementElements";

function WithdrawHistory(id) {
    const history = useHistory();
    // for module
    const parsedUserData =
        JSON.parse(localStorage.getItem("userData")) ||
        JSON.parse(sessionStorage.getItem("userData"));
    let modules = parsedUserData?.data?.modules;
    const permissions = modules?.find(
        (item) => item.moduleName === "Claim Management"
    )?.permissions;

    // state
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    const [tableData, setTableData] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [paginationData, setPaginationData] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortingValue, setSortingValue] = useState("1");
    const [sortingType, setSortingType] = useState("1")
    const [selectedOption, setSelectedOption] = useState({
        label: "In Progress",
        value: "1"
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        window.scrollTo(0, 0);
    };

    const handleRespose = async () => {
        setIsLoading(true);
        try {
            const { data } = await axios.get(`/private/Withdrawals/${id?.id}?page=${page}&limit=${limit}`);
            setTableData(data?.data?.docs);
            setPaginationData(data?.data)
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.loog(error);
        }
    };
    useEffect(() => {
        handleRespose();
    }, [sortingType, sortingValue]);

    const changeStatus = async (id) => {
        try {
            setIsLoading(true)
            if (id != undefined) {
                const data = await axios.post(`/private/transfereHewe/${id}`)
                toast.success(data?.data?.message)
            }
            handleRespose()
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const copytext = async (data) => {
        try {
            await navigator.clipboard.writeText(data);
            toast.success("copied")
        } catch (error) {
            console.log(error)
        }
    }



    return (
        <div className="">
            {tableData?.length > 0 ? (
                <>
                    <Paper
                        className="mt-3"
                        style={{
                            position: "relative",
                            height: "60vh",
                            overflowY: "scroll",
                        }}
                    >
                        <>
                            <TableContainer className={classes.tableMainContainer} style={{ minHeight: "50vh" }}>
                                <Table>
                                    <TableHead>
                                        <TableRow className={classes.tableHeadingRow}>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                S.No
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                Action
                                            </TableCell>
                                            <TableCell className={classes.tablseHeadingCell}>
                                                User Name
                                            </TableCell>
                                            <TableCell className={[classes.tablseHeadingCell]}>
                                                Request ID
                                            </TableCell>
                                            <TableCell className={[classes.tablseHeadingCell]}>
                                                Received On
                                            </TableCell>
                                            <TableCell className={[classes.tablseHeadingCell]}>
                                                HeXP Request
                                            </TableCell>

                                            <TableCell className={[classes.tablseHeadingCell]}>
                                                Hewe Claimed
                                            </TableCell>
                                            {/* <TableCell className={[classes.tablseHeadingCell]}>
                                                Existing Point
                                            </TableCell> */}
                                            <TableCell className={[classes.tablseHeadingCell]}>
                                                Address
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableData?.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell className={classes.textMiddle}>
                                                    <div>{index + 1 + rowsPerPage * (page - 1)}</div>
                                                </TableCell>

                                                <TableCell className={classes.textMiddle}>
                                                    {
                                                        item?.action != "3" ? (
                                                            <div className="transfered m-auto">Pending</div>
                                                        ) : <div className="transfered m-auto">Transferred</div>
                                                    }
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    {item?.profileName}
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    {item?._id}
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>
                                                    {item?.createdAt ? moment(item.createdAt).format("lll") : "-"}
                                                </TableCell>
                                                <TableCell className={classes.textMiddle}>  {item?.withdrawalHexpAmount}</TableCell>
                                                <TableCell className={classes.textMiddle}>    {item?.heweCoinAmount?.$numberDecimal ?? "-"}</TableCell>
                                                {/* <TableCell className={classes.textMiddle}>    {item?.existingHexp}</TableCell> */}
                                                <TableCell className={classes.textMiddle}><div className="d-flex justify-content-center">
                                                    **********{(item?.walletAddress).slice(-6)}  <span className="ms-2" onClick={() => copytext(item?.walletAddress)}><Copy /></span>
                                                </div>  </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Stack
                                spacing={2}
                                className="flex-align-center justify-content-center w-100 py-3"
                            >
                                <Pagination
                                    count={Math.ceil(paginationData?.totalDocs / rowsPerPage)}
                                    page={page}
                                    onChange={(event, value) => handleChangePage(event, value)}
                                    renderItem={(item) => (
                                        <PaginationItem
                                            slots={{ previous: FiArrowLeft, next: FaArrowRight }}
                                            {...item}
                                        />
                                    )}
                                />
                            </Stack>
                        </>
                    </Paper>
                </>
            ) : (
                <div className="emptyTable mt-3">No Data Found</div>
            )}
            {isLoading && <Overlay />}
        </div>
    );
}

export default WithdrawHistory;
