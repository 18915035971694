import React, { useState, useEffect, useRef } from "react";
import { IoMdInformationCircleOutline } from "react-icons/io";
import {
  DashboardHeading,
  DashHeading,
} from "./AccountManagementElements";
import Select from "react-select";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputBase,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "../../axios";
import Overlay from "../../components/Overlay";
import { get } from "lodash";
import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { FaArrowRight, FaGlobe } from "react-icons/fa";
import { GrApple, GrAndroid } from "react-icons/gr";
import { Modal } from "../../components/Modal";
import SearchBar from "material-ui-search-bar";
import rn1 from "../../images/hewe/award.svg"
import {
  MdOutlineClose,
  MdOutlineFileUpload,
} from "react-icons/md";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import * as XLSX from 'xlsx';
import { AccoutArcSvg, AccoutBlockSvg, AccoutSvgMail, AccoutSvgPhone, AdvancedFilterIcon, ArrowDown, ArrowUp, UnarchiveAccout } from "../../components/svg";
import { countryNameLists } from "../../components/countryArray";
import { CiMenuKebab } from "react-icons/ci";
import { Stack } from "react-bootstrap";
import { FiArrowLeft } from "react-icons/fi";



const useStyles = makeStyles((theme) => ({
  paperTableHeight: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    border: "1px solid #D9E3D9",
    overflowX: "scroll",
  },
  tableMainContainer: {
    overflowX: "scroll",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  tableHeadingRow: {
    // backgroundColor: "#21AFE6",
  },
  tablseHeadingCell: {
    // position: "sticky",
    top: 0,
    zIndex: 99,
    padding: "0.9rem 20px !important",
    textAlign: "center",
    fontSize: "14px",
    color: "#264503",
    fontWeight: 600,
    backgroundColor: "white",
    borderBottom: "1px solid #D9E3D9",
    whiteSpace: "nowrap",
  },
  textMiddle: {
    padding: "0.3rem 20px !important",
    verticalAlign: "middle !important",
    textAlign: "center",
    fontSize: "14px",
    color: "black",
  },
  tablePaginationStyle: {
    borderTop: "1px solid #0000001a",
    borderRadius: "0rem 0rem 0.4rem 0.4rem",
    overflowY: "hidden",
  },
  sticky: {
    position: "sticky",
    left: "0"
  }
}));

const ArchiveManagement = ({ userData, setAccount, accountManagement2 }) => {

  const parsedUserData = JSON.parse(localStorage.getItem("userData")) || JSON.parse(sessionStorage.getItem("userData"));
  let modules = parsedUserData?.data?.modules;
  const permissions = modules?.find((item) => item.moduleName === "Account Management")?.permissions;
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, SetTableData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const { state } = useLocation()
  // For Pagination
  const [page, setPage] = useState(accountManagement2?.page || 1);

  const [statePage, setStatePage] = useState(null)
  // For Search
  const [searchTerm, setSearchTerm] = useState(accountManagement2?.search || "");
  const [limit, setLimit] = useState(accountManagement2?.limit || 10);
  const [paginationData, setPaginationData] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // sorting
  const [sortType, setSortType] = useState("");
  const [sortingValue, setSortingValue] = useState("");

  const handleSort = (key) => {
    setSortingValue((prevSortingValue) => (prevSortingValue === key ? key : key));
    setSortType((prevSortType) => {
      if (sortingValue === key) {
        return prevSortType === "1" ? "2" : "1";
      } else {
        return "1";
      }
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    window.scrollTo(0, 0)
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    window.scrollTo(0, 0);
  };
  // Search functionalty
  function myDeb(call, d = 1000) {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        call(...args);
      }, d);
    };
  }
  useEffect(() => {
    // Check if `state?.state?.page` exists
    if (state?.state?.page != null) {
      setPage(state.state.page);
      setStatePage(null); // Reset the state page once it's used
    }
  }, [state]);

  useEffect(() => {
    getAccountManageData();
  }, [page, searchTerm, sortType, sortingValue]);
  const getAccountManageData = async (formData = null) => {
    setIsLoading(true);
    try {
      let requestData = formData || {
        country: selectedCountries.map(country => country?.value),
        deviceType: device.map(d => d?.value),
        CurrentRank: rank.map(r => r?.value),
        gender: gender.map(g => g?.value),
      };

      const { data } = await axios.post(
        `/private/archiveUser?search=${searchTerm}&page=${page}&limit=${limit}&sortingValues=${sortingValue}&sortType=${sortType}`,
        requestData
      );

      // Update state with reset values if onReset is triggered
      if (formData) {
        setAccount({
          page,
          limit,
          country: [],
          deviceType: [],
          rank: [],
          level: [],
          gender: [],
          search: searchTerm
        });
      } else {
        setAccount({
          page,
          limit,
          country: selectedCountries,
          deviceType: device,
          rank: rank,
          level: currentLevel,
          gender: gender,
          search: searchTerm
        });
      }

      setPaginationData(data?.data);
      SetTableData(data?.data?.docs);
      setOpenModal(false);
      setShow(false);
      window.scrollTo(0, 0);
    } catch (error) {
      if (error?.response.status === 401) {
        toast.success(`${error.response.data?.errors[0].msg}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        history.push("/");
      } else {
        toast.error(`Something went wrong`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const SearchAccountManagement = myDeb((search) => {
    setSearchTerm(search);
  });

  const cancelSearch = () => {
    setSearchTerm("");
  };

  const block = async (id) => {
    try {
      const response = await axios.put(`/private/changeStatus/${id}`);
      if (response.data.message === "User blocked successfully.") {
        toast.error(response.data.message);
      } else {
        toast.success(response.data.message);
      }
      getAccountManageData();
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const archive = async (id) => {
    try {
      let formData = {
        status: "1",
      };
      const response = await axios.put(`/changeAccountStatus/${id}`, formData);
      toast.success(response.data.message);
      getAccountManageData();
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };
  const [show, setShow] = useState(false);

  // advance filter

  const genderlist = [
    {
      label: "Male",
      value: "1",
    },
    {
      label: "Female",
      value: "2",
    }
  ];
  const rankList = [
    "1-10", "11-20", "21-30", "31-40", "41-50", "51-60", "61-70", "71-80", "81-90", "91-100"
  ]
  const level = Array.from({ length: 100 }, (_, i) => i + 1);

  const deviceList = ["Android", "iOS"]
  const [selectedCountries, setSelectedCountries] = React.useState(accountManagement2?.country || []);
  const [search, setSearch] = React.useState(accountManagement2?.search || "");
  const [gender, setGender] = useState(accountManagement2?.gender || [])
  const [rank, setRank] = useState(accountManagement2?.rank || [])
  const [currentLevel, setCurrentLevel] = useState(accountManagement2?.level || [])
  const [device, setDevice] = useState(accountManagement2?.device || [])
  const handleChangegender = (event) => {
    const {
      target: { value },
    } = event;
    setGender(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleChangerank = (event) => {
    const {
      target: { value },
    } = event;
    setRank(
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const handleChangedevice = (event) => {
    const {
      target: { value },
    } = event;
    setDevice(
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const handleCountryChange = (event) => {
    const { target: { value } } = event;
    setSelectedCountries(
      typeof value === 'string' ? value.split(',') : value
    );
  };
  const handleChangeLevel = (event) => {
    const {
      target: { value },
    } = event;
    setCurrentLevel(
      value
    );
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  // Filter country list based on the search query
  const filteredCountryList = countryNameLists.filter((name) =>
    name.toLowerCase().includes(search.toLowerCase())
  );


  const [activeId, setActiveId] = useState(null);


  const toggleRef = useRef(null);
  const toggleClick = (id) => {
    setActiveId((prevId) => (prevId === id ? null : id));
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (toggleRef.current && !toggleRef.current.contains(event.target)) {
        setActiveId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [hoverId, setHoverId] = useState(null);

  const handleMouseEvent = (id, isHovering) => {
    if (isHovering) {
      setHoverId(id); // Set the ID when mouse enters
    } else {
      setHoverId(null); // Clear the ID when mouse leaves
    }
  };
  const resetbtn = () => {
    setSelectedCountries([]);
    setGender([]);
    setRank([]);
    setCurrentLevel([]);
    setDevice([]);

    // Reset API call without filters
    const formData = {
      country: [],
      deviceType: [],
      CurrentRank: [],
      CurrentLevel: [],
      gender: []
    };

    getAccountManageData(formData);
  };


  return (
    <>
      <div className="hewe-cont">

        <div className="mb-3 w-100">
          <DashboardHeading>
            <div className="dott me-3"></div>
            <DashHeading>Account Management</DashHeading>
          </DashboardHeading>
          <div className="d-lg-flex justify-content-between align-items-center w-100">
            <div className="d-lg-flex align-items-center">
              <div>
                <SearchBar
                  style={{ minWidth: "353px", Height: "33px", borderRadius: "4px", fontStyle: "italic", color: "#FDFFFB" }}
                  className="searchbars"
                  onChange={(e) => {
                    SearchAccountManagement(e);
                  }}
                  onCancelSearch={cancelSearch}
                  placeholder="Search Profile Name"
                  inputProps={{ maxLength: 30 }}
                />
              </div>
              <div className="ms-3 mt-lg-0 mt-3 font-dark-green pointer" onClick={() => {
                setOpenModal(true);
              }}>
                <AdvancedFilterIcon /> Advanced Filter
              </div>
            </div>
            <div>
              <div className="mt-lg-0 mt-3 d-flex align-items-center">
                {(userData.userType === "admin" || userData.userType == "subAdmin" && permissions?.download == true) && <ExportXLS />}
              </div>
            </div>
          </div>
          <div className="mt-1 ">
            <button className="Archived white-space-nowrap" onClick={() => history.push("/accountManagement")}>Active Users</button>
            <button className="ArchivedActivebtn white-space-nowrap" onClick={() => history.push("/accountManagement/archiveUser")}>Archived USERS ({paginationData?.totalDocs})</button>
            <button className="Archived white-space-nowrap" onClick={() => history.push("/accountManagement/blockUser")}>Blocked USERS</button>
          </div>
        </div>
        {tableData?.length > 0 ? (<>
          <Paper className={classes.paperTableHeight} onClick={() => setShow(false)}>
            <>
              <TableContainer
                className={classes.tableMainContainer}
              // style={{minHeight:"50vh"}}
              >
                <Table>
                  <TableHead>
                    <TableRow className={classes.tableHeadingRow}>
                      <TableCell className={classes.tablseHeadingCell}>S.No</TableCell>
                      <TableCell className={classes.tablseHeadingCell}>Action</TableCell>

                      <TableCell className={classes.tablseHeadingCell} style={{ textAlign: "left" }}>
                        Profile Name
                        <span onClick={() => handleSort("1")}>
                          {sortingValue === "1" && sortType === "1" ? <ArrowUp /> : sortingValue === "1" && sortType === "2" ? <ArrowDown /> : <ArrowUp />}
                        </span>
                      </TableCell>

                      <TableCell className={classes.tablseHeadingCell}>Country</TableCell>
                      <TableCell className={classes.tablseHeadingCell}>Gender</TableCell>

                      {/* <TableCell className={classes.tablseHeadingCell}>
                        Level
                        <span onClick={() => handleSort("2")}>
                          {sortingValue === "2" && sortType === "1" ? <ArrowUp /> : sortingValue === "2" && sortType === "2" ? <ArrowDown /> : <ArrowUp />}
                        </span>
                      </TableCell> */}

                      <TableCell className={classes.tablseHeadingCell}>
                        Rank
                        <span onClick={() => handleSort("3")}>
                          {sortingValue === "3" && sortType === "1" ? <ArrowUp /> : sortingValue === "3" && sortType === "2" ? <ArrowDown /> : <ArrowUp />}
                        </span>
                      </TableCell>

                      <TableCell className={classes.tablseHeadingCell}>Device</TableCell>

                      <TableCell className={classes.tablseHeadingCell}>
                        Total Walking (in miles)
                        <span onClick={() => handleSort("4")}>
                          {sortingValue === "4" && sortType === "1" ? <ArrowUp /> : sortingValue === "4" && sortType === "2" ? <ArrowDown /> : <ArrowUp />}
                        </span>
                      </TableCell>

                      <TableCell className={classes.tablseHeadingCell}>
                        Total Cycling (in miles)
                        <span onClick={() => handleSort("5")}>
                          {sortingValue === "5" && sortType === "1" ? <ArrowUp /> : sortingValue === "5" && sortType === "2" ? <ArrowDown /> : <ArrowUp />}
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.map((category, index) => {
                      const id = get(category, "_id");
                      // const isToggle = toggleState[id] || false;
                      return (
                        (
                          <>
                            <TableRow key={category._id}>
                              <TableCell className={classes.textMiddle}>
                                <div>{index + 1 + rowsPerPage * (page - 1)}</div>
                              </TableCell>
                              <TableCell className={classes.textMiddle}>
                                {/* ref={toggleRef} */}
                                <div className="position-relative">
                                  <CiMenuKebab onClick={() => toggleClick(id)} />
                                  {activeId === id ? (
                                    <div className="toogleHover" ref={toggleRef}>
                                      {get(category, "archiveStatus") == "2" ? (
                                        <>
                                          {(userData.userType == "admin" || userData.userType == "subAdmin" && permissions?.blockUnblock == true) &&
                                            <div
                                              className="d-flex align-items-center py-2 px-2 pointer hovertoggle"
                                              onClick={() => block(get(category, "_id"))}
                                            >
                                              <AccoutBlockSvg />
                                              <span className=" ps-2 tu">
                                                {get(category, "status") == 1
                                                  ? "Unblock"
                                                  : "Block"}
                                              </span>
                                            </div>
                                          }

                                          <div
                                            className=" d-flex align-items-center py-2 px-2 pointer hovertoggle "
                                            onClick={() =>
                                              archive(get(category, "_id"))
                                            }
                                          >
                                            <AccoutArcSvg />
                                            <span className="ps-2 ">Unarchive</span>
                                          </div>
                                        </>
                                      ) : null}
                                    </div>
                                  ) : null
                                  }

                                </div>
                              </TableCell>
                              <TableCell className={classes.textMiddle} style={{ position: "relative" }}>
                                <div className="toogleHoverViewcont ">
                                  <div className="pointer underline font-g2 flex-align-center " >
                                    <IoMdInformationCircleOutline size={24} className="me-2 toogleHoverView"
                                      onMouseEnter={() => handleMouseEvent(id, true)}
                                      onMouseLeave={() => handleMouseEvent(null, false)} />
                                    <span onClick={() =>
                                      history.push(
                                        `/accountManagement/view-profile/${get(category, "_id")}`, { state: { page: page } }
                                      )
                                    }>{get(category, "profileName", "N/A")}</span>
                                  </div>
                                  {
                                    hoverId === id && (
                                      <div className="toogleViewData">
                                        <p className="flex-align-center"><AccoutSvgMail /><span className="ms-2">{get(category, "email", "N/A")}</span></p>
                                        <p className="flex-align-center py-2"><AccoutSvgPhone /><div style={{ whiteSpace: "nowrap" }} className="ms-2">
                                          {get(category, "countryCode", "N/A")}{" "}
                                          {get(category, "phoneNumber", "N/A")}
                                        </div></p>
                                        <p style={{ fontSize: "12px" }}>
                                          <i>
                                            {new Date(get(category, "createdAt", "N/A")).toLocaleDateString('en-US', {
                                              year: 'numeric',
                                              month: 'long',
                                              day: 'numeric',
                                            })}
                                          </i>
                                        </p>
                                      </div>
                                    )
                                  }

                                </div>
                              </TableCell>

                              <TableCell className={classes.textMiddle}>
                                <div style={{ whiteSpace: "nowrap" }} className="text-uppercase">
                                  <strong>
                                    {get(category, "country", "N/A")}
                                  </strong>
                                </div>
                              </TableCell>


                              <TableCell className={classes.textMiddle}>
                                {get(category, "gender", "N/A") === "1" ? (
                                  <span>Male</span>
                                ) : get(category, "gender", "N/A") === "2" ? (
                                  <span>Female</span>
                                ) : get(category, "gender", "N/A") === "3" ? (
                                  <span>Other</span>
                                ) : (
                                  "N/A"
                                )}
                              </TableCell>
                              {/* <TableCell className={classes.textMiddle}>
                                <div className="awardImg"><img src={rn1} alt="" /><p className="level">{category?.CurrentLevel}</p></div>
                              </TableCell> */}
                              <TableCell className={classes.textMiddle}>
                                <div>{get(category, "CurrentRank", "N/A")}</div>
                              </TableCell>
                              <TableCell className={classes.textMiddle}>
                                {get(category, "deviceType", "N/A") === "1" ? (
                                  <FaGlobe
                                    style={{ fontSize: "20px", color: "#000000" }}
                                  />
                                ) : get(category, "deviceType", "N/A") === "2" ? (
                                  <GrAndroid
                                    style={{ fontSize: "20px", color: "#79c257" }}
                                  />
                                ) : get(category, "deviceType", "N/A") === "3" ? (
                                  <GrApple
                                    style={{ fontSize: "20px", color: "#424245" }}
                                  />
                                ) : (
                                  "N/A"
                                )}
                              </TableCell>
                              <TableCell className={classes.textMiddle}>
                                {get(category, "TotalWalk", "N/A")?.toFixed(2)}
                              </TableCell>
                              <TableCell className={classes.textMiddle}>
                                {get(category, "TotalCycling", "N/A")?.toFixed(2)}
                              </TableCell>
                            </TableRow>
                          </>
                        )
                      )
                    })}
                  </TableBody>
                </Table>
                <Stack spacing={2} className="flex-align-center justify-content-center w-100 py-3">
                  <Pagination
                    count={Math.ceil(paginationData?.totalDocs / rowsPerPage)}
                    page={page}
                    onChange={(event, value) => handleChangePage(event, value)}
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{ previous: FiArrowLeft, next: FaArrowRight }}
                        {...item}
                      />
                    )}
                  />
                </Stack>
              </TableContainer>
            </>
          </Paper>
        </>) : (
          <div className="emptyTable mt-3">
            No Data Found
          </div>
        )}

      </div>

      {/* Modal for filter  */}
      <Modal
        maxWidth="lg"
        width="680px"
        Height="500px"
        RoundedCorners={true}
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        backgroundModal={false}
        backgroundModalContent={false}
        title={
          <div className="p-2">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <p className="">Advanced Filter</p>
              </div>
              <MdOutlineClose
                style={{
                  fontWeight: 600,
                  fontSize: "22px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenModal(false);
                }}
              />
            </div>

          </div>
        }
        content={
          <>
            <div className="row">
              <div className="col-6">
                <div>
                  <label className='fw-700 mb-2'>Country</label>
                  <Select options={countryNameLists.map((name) => ({ label: name, value: name }))}
                    value={selectedCountries}
                    onChange={setSelectedCountries}
                    isSearchable={true}
                    isMulti
                    classNamePrefix="react-select"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 255, 255, 0.38)",
                        borderColor: "gray",
                        textAlign: "left",
                        boxShadow: "none",
                        color: "black",
                        "&:hover": { borderColor: "black" },
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="col-6 ">
                <div>
                  <label className='fw-700 mb-2'>Rank</label>
                  <Select options={rankList.map((name) => ({ label: name, value: name }))}
                    value={rank}
                    onChange={setRank}
                    isSearchable={true}
                    isMulti
                    classNamePrefix="react-select"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 255, 255, 0.38)",
                        borderColor: "gray",
                        textAlign: "left",
                        boxShadow: "none",
                        color: "black",
                        "&:hover": { borderColor: "black" },
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="col-6 mt-3">
                <div>
                  <label className='fw-700 mb-2'>Gender</label>
                  <Select options={genderlist}
                    value={gender}
                    onChange={setGender}
                    isSearchable={true}
                    isMulti
                    classNamePrefix="react-select"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 255, 255, 0.38)",
                        borderColor: "gray",
                        textAlign: "left",
                        boxShadow: "none",
                        color: "black",
                        "&:hover": { borderColor: "black" },
                      }),
                    }}
                  />
                </div>
              </div>

              <div className="col-6 mt-3">
                <div>
                  <label className='fw-700 mb-2'>Device Type</label>
                  <Select options={deviceList.map((name) => ({ label: name, value: name }))}
                    value={device}
                    onChange={setDevice}
                    isSearchable={true}
                    isMulti
                    classNamePrefix="react-select"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(255, 255, 255, 0.38)",
                        borderColor: "gray",
                        textAlign: "left",
                        boxShadow: "none",
                        color: "black",
                        "&:hover": { borderColor: "black" },
                      }),
                    }}
                  />
                </div>
              </div>
            </div>


            <div className="d-flex justify-content-end my-4">
              <button className="canclebtn me-3" onClick={() => resetbtn()}>Reset</button>
              <button className="ArchivedActivebtn me-0" onClick={() => getAccountManageData()}>Apply</button>
            </div>
          </>
        }
      />

      {isLoading && <Overlay />}
    </>
  );
};

export const ExportXLS = () => {
  const fileName = "users-detail.xls";
  const [userData, setUserData] = useState([]);
  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    axios.get('/private/archivetxls')
      .then((res) => {
        setUserData(res.data.data);
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  const exportToXLS = () => {
    const ws = XLSX.utils.json_to_sheet(userData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Users");
    XLSX.writeFile(wb, fileName);
  };

  return (
    <div className='containers'>
      <button className="ExportXls" onClick={exportToXLS}>
        <MdOutlineFileUpload size={20} /> Export XLS
      </button>
    </div>
  );
};



const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    accountManagement2: state.accountManagement2

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setAccount: (updatedValue) => {
      dispatch({
        type: actionTypes.ACCOUNT_MANAGEMENT2,
        updateAccountManagement2: updatedValue,
      });
    }
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ArchiveManagement));
